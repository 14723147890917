import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { withTranslation } from 'react-i18next'
import { t } from 'i18next'
import withRouter from '../../HOC/widthRouter'
import AdditionalField from '../AdditionalField'
import { PulPalServicesLoadingTypes } from '../../redux/reducers/pulPalServices/types'
import { PulPalServicesActionCreators } from '../../redux/reducers/pulPalServices/action-creators'
import { payment, pulpalServices, pulServSaveCredit } from '../../http';
import './index.scss'
import descriptionImg from '../../assets/spot-illustrations/description.svg';
import PulPalLoader from "../PulPalLoader/PulPalLoader";
import { isAzercellService } from '../../utils/functions';
import PaymentFields from '../PaymentFields';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Service extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      previousLang: undefined,
      fields: [],
      groupId: 0,
      isClientExist: false,
      allFieldsHaveData: false,
      isLoading: false,
      isModalVisible: false,
      amountValue: '',
      msgType: 0,
      subVal: '',
      autoRedirectToPayment: false,
      subName: '',
      additionalDebtFields: [],
      isAmountCorrect: true,
      goPrevButton: false,
      loadingNotTransparent: false,
    }
  }

  componentDidMount = async () => {
    const { params, getServiceById } = this.props
    await getServiceById(params.id)
  }

  componentDidUpdate = async (_, prevS) => {
    const { service, navigate  } = this.props
    if(service?.isAuth)
      {
        navigate('/')
        toast.info((t('logInToContinue')), {
          position: toast.POSITION.TOP_CENTER,
      })
    }
    if (prevS.allFieldsHaveData !== this.state.allFieldsHaveData) {
      if (this.state.allFieldsHaveData) {
        await this.onButtonPress()
      }
    }
    if (this.state.autoRedirectToPayment !== prevS.autoRedirectToPayment) {
      if (this.state.autoRedirectToPayment) {
        await this.onButtonPress()
      }
    }
  }

  goPrev = () => {
    this.props.navigate(-1);
    this.setState({goPrevButton: true})
  }

  async checkIfUserExist(serviceId, fields, selectedGroupId) {
    this.setState({ isLoading: true })
    try {
      const response = await axios.post(`${pulpalServices}/get-info`, {
        serviceId,
        fields,
        groupId: selectedGroupId,
        terminalId: 'string',
      })

      if (response.status === 200) {
        const json = response.data
        if (json.isSuccess === true) {
          this.setState({ isClientExist: true, checkData: json })
        } else if (json.isSuccess === false) {
          this.setState({ msgType: 1 });
          if(!this.state.goPrevButton)
            toast.error(t('clientNotFound'), {
              position: toast.POSITION.TOP_CENTER,
            })

        } else if (
          json.resultCodes === 'UNDECIDED_PROTOCOL' ||
          json.resultCodes === 'PROTOCOL_CANT_DECIDED'
        ) {
          this.setState({ showVerdictFields: true })
        }
      } else {
        this.setState({ msgType: 0 })
      }
    } catch (e) {
      this.setState({ msgType: 0 })
    } finally {
      this.setState({ isLoading: false })
    }
  }

  async onButtonPress(e) {
    e?.preventDefault()
    const { service } = this.props
    const { fields, groupId, subName, subVal } = this.state
    const fieldsJSON = fields
    if (this.props.loading.isLoading) {
      return
    }
    if (this.state.isClientExist) {
      if (this.state.isAmountCorrect || Number(this.state.paramAmountValue)) {
        this.setState({ isLoading: true })

        try {
          const response = await axios.post(`${pulServSaveCredit}/save`, {
            serviceId: service?.id,
            amount: Math.trunc(Number(this.state.amountValue) * 100),
            groupId: this.state?.groupId,
            serviceItemData: {
              key: subName,
              value: subVal,
            },
            terminalId: 'web',
            fields: fields,
          })

          const goToTopUpBalance = () => {
            //TODO: remake to URLSearchParams and URL
            window.location.href =
              payment +
              `?uniqueCode=S-${response.data}`
          }

          goToTopUpBalance()
        } catch (e) {
        } finally {
          this.setState({ isLoading: false })
        }
      }
    } else {
      this.checkIfUserExist(service?.id, fieldsJSON, groupId || 0)
    }
  }

  render() {
    const { service, serviceError } = this.props

    const { loadingNotTransparent } = this.state

    if (serviceError) {
      return <div className='service_error'>Ошибка при загрузке Сервиса</div>
    }

    const loader = (
      <div
        className={`pulpal-loader-wrapper ${loadingNotTransparent ? 'pulpal-loader-wrapper--not-transparent' : ''
          } `}
      >
        <PulPalLoader />
      </div>
    )

    if (Object.keys(service).length === 0) {
      return loader
    }

    const {
      isClientExist,
      checkData,
      isAmountCorrect,
      showVerdictFields,
      fieldsForPicker,
    } = this.state
    // console.log('this.props', this.props.language.langKey);

    // const serviceDescription =
    //   `<div
    //         class="service_description-field-title">${t(
    //       'information',
    //   )}!</div>` +
    //   // service?.localeDesc?.['valueAz']
    //   service?.localeDesc?.[this.props.language.langKey]

    const serviceDescription = () => {
      return (
        <div className="service_description-field-title">
          ${t(
            'information',
          )}!
          service?.localeDesc?.[this.props.language.langKey]
        </div>
      )
    }

    const isRightColumn =
      // service?.localeDesc?.['valueAz'] === '' ||
      service?.localeDesc?.[this.props.language.langKey] === '' ||
      this.state?.additionalDebtFields?.length === 0

    const containerClassName = isRightColumn
      ? 'container'
      : 'container container--not-right-column'

    console.log('this.state?.additionalDebtFields', this.state?.additionalDebtFields);


    return (
      <div className='servicePay'>
        <div className='service-wrapper row mt-3'>
          <div className='service py-4'>
            <div className='card theme-wizard mb-5'>
              <div className='tab-content'>
                <div className='tab-pane active px-sm-3 px-md-5'>
                  <div className='card-body py-4 service-wrapper__inner'>
                      <aside className='mb-3 aside'>
                        <div
                          className='service_fields-item service_description-field'
                          style={{
                            backgroundImage: ` url(${descriptionImg})`
                          }}
                        >
                          <div className='service_description-title'>{t('information')}</div>
                          <div className='service_description-text'>
                            {service?.localeName?.[this.props.language.langKey] || service?.name}
                          </div>
                        </div>
                        {this.state.additionalDebtFields.length !== 0 && (
                          <div className='additional-info__container'>
                            {this.state?.additionalDebtFields.map(
                              (item, index) => {
                                return (
                                  <div className="additional-info__container__column" key={index}>
                                    <div className="additional-info__container__column-title">
                                      {item.multiLangValueString[
                                        this.props.language.langKey
                                      ].replace(/<[^>]+>/g, ' ')}
                                    </div>
                                    <div className="additional-info__container__column-text">
                                      {item.value}
                                    </div>
                                  </div>
                                )
                              },
                            )}
                          </div>
                        )}
                      </aside>
                    <form
                      className='service_form'
                      onSubmit={e => {
                        this.onButtonPress(e)
                      }}
                    >
                      {/* <h1 className='service_title'>
                  {service?.localeName['valueAz']}
                </h1> */}
                      <div className='service_content'>
                        <button className='goBack' onClick={() => this.goPrev()}>{t("Back")}</button>
                        <PaymentFields
                          showVerdictFields={showVerdictFields}
                          service={service}
                          onValueChange={s => this.setState(s)}
                          isClientExist={isClientExist}
                          azercell={isAzercellService(service?.id)}
                          fieldsFolorPicker={fieldsForPicker}
                        />
                        {isClientExist && (
                          <>
                            <AdditionalField
                              onChange={s => this.setState(s)}
                              isAmountCorrect={isAmountCorrect}
                              service={service}
                              checkData={checkData}
                            />
                          </>
                        )}
                      </div>
                      <button
                        style={{ width: '100%', color: '#fff' }}
                        className='btn d-block w-100 mt-3 falcon-primary reg btnCheck'
                        isDisabled={!isAmountCorrect && isClientExist}
                      >
                        {/* {isClientExist ? this.props.t('pay') : this.props.t('check')} */}
                        <div className='btn__text '>{(
                          this.state.isLoading ||
                          this.props.loading.isLoading ||
                          this.props.loading.subjects.has(
                            PulPalServicesLoadingTypes.GET_SELECTED_SERVICES,
                          )) ? <span class="loader"></span> : isClientExist ? this.props.t('pay') : this.props.t('check')}</div>
                      </button>
                    </form>

                    {/* {(this.state.isLoading ||
                      this.props.loading.isLoading ||
                      this.props.loading.subjects.has(
                        PulPalServicesLoadingTypes.GET_SELECTED_SERVICES,
                      )) &&
                      loader} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  console.log('map state to props', state)
  return {
    service: state.pulPalServicesReducer.selectedService,
    serviceError: state.pulPalServicesReducer.error,
    loading: state.loadingReducer,
    language: state.languageReducer
  }
}

const mapDispatchToProps = {
  getServiceById: PulPalServicesActionCreators.getServiceById,
  // doGuestPay: GuestActionCreators.doGuestPay,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(Service)))
