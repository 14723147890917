import AboutPNG from './../../../assets/about/about.png';
import SVG01 from './../../../assets/about/01.svg';
import SVG02 from './../../../assets/about/02.svg';
import SVG03 from './../../../assets/about/03.svg';

export const aboutCardSources = {
    aboutPNG: AboutPNG,
    svg01: SVG01,
    svg02: SVG02,
    svg03: SVG03
}