// import LinkButton from '@/elements/LinkButton/LinkButton';
import LinkButton from '../../../elements/LinkButton/LinkButton';
import React from 'react';
import { DeveloperBoxProps } from './Developers.props';
import styles from '../Home.module.scss';
import { useTranslation } from 'react-i18next';

const DeveloperBox: React.FC<DeveloperBoxProps> = ({ icon, title, desc, button, color }) => {
    const { t } = useTranslation();
    return (
        <div className='col-12 col-md-6 col-lg-3'>
            <div className={`${styles['dev__box']} ${styles['dev__box-' + color]}`}>
                <img className={styles['dev__box-icon']} src={icon.src} alt={icon.alt} />
                <h5 className={styles.devBoxTitle}>{title}</h5>
                <p className={styles.devBoxDesc}>{desc}</p>
                <LinkButton url={button.url}>
                    <>
                        {button.title}
                        {button.image && <>
                            &nbsp;
                            <img src={button.image.src} alt={button.image.alt} />
                        </>}
                    </>
                </LinkButton>
            </div>
        </div>
    );
}

export default DeveloperBox;