// import '../Registration/registration.css';
import { useState } from 'react';
import { RegistrationResources } from "../Registration/Registration.resources";
import SignUpIndividualService from "../../services/individual";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const VerificationAfterSignUp = () => {
    const [verifyCode, setVerifyCode] = useState('');
    const phoneNumber = localStorage.getItem('phone');
    const { handleSubmit } = useForm();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const loc = useLocation();
    console.log('loc', loc);

    const params = useParams();
    console.log('params', params);

    const sendVerifyCode = async () => {
        const data = await SignUpIndividualService.verifyByPhone({ verifyCode, phone: '%2B994' + phoneNumber });
        console.log('data verify comp', data)
        if (data?.data.status === 0) {
            navigate('/userSuccess');
        }
        else {
            console.log('error asdasd', data);
        }
    };

    const resendVerification = async () => {
        await SignUpIndividualService.resendVerification({ phoneOrEmail: '%2B994' + phoneNumber });
        toast.success(t('CodeResubmitted'), {
            position: toast.POSITION.TOP_CENTER,
        })
    }

    const onSubmit = () => { };


    return (
        <main className="main" id="top">
            <div className="container-fluid">
                <div className="row min-vh-100 flex-center g-0 to-center">
                    <div className="col-lg-8 col-xxl-5 py-3 position-relative">
                        <img className="bg-auth-circle-shape" src={RegistrationResources.bgShape} alt="" width="250" />
                        <img className="bg-auth-circle-shape-2" src={RegistrationResources.shape1} alt="" width="150" />
                        <div className="card overflow-hidden z-index-1">
                            <div className="card-body p-0">
                                <div className="row g-0 h-100">
                                    <div className="col-md-5 text-center bg-card-gradient">
                                        <div className="position-relative p-4 pt-md-5 pb-md-7 light">
                                            <div className="bg-holder bg-auth-card-shape bg-half-circle">
                                            </div>
                                            <div className="z-index-1 position-relative"><a className="link-light mb-4 font-sans-serif fs-4 d-inline-block fw-bolder link-pulpal" href="index.html">
                                                <img src={RegistrationResources.pulpalSvg} alt="" width="35" className="pulpalSvg" />PulPal</a>
                                                <p className="opacity-75 text-white text-size">{t("ThePowerOfPulpal")}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7 d-flex flex-center">
                                        <div className="p-4 p-md-5 flex-grow-1">
                                            <div className="text-center text-md-start">
                                                <h4 className="mb-0 check">{t("EnterVerificationCode")}</h4>
                                                <p className="mb-4 labels-color label-huge">{t("SMS")}</p>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-sm-8 col-md">
                                                    <form onSubmit={handleSubmit(onSubmit)} className="mb-3">
                                                        {/* <input value={verifyCode} onChange={(e) => setVerifyCode(e.target.value)} className="form-control" type="tel" placeholder={`${t("VerificationCode")}`} /> */}
                                                        <input
                                                            value={verifyCode}
                                                            onChange={(e) => {
                                                                const sanitizedValue = e.target.value.replace(/\D/g, '');
                                                                const truncatedValue = sanitizedValue.slice(0, 4);
                                                                setVerifyCode(truncatedValue);
                                                            }}
                                                            className="form-control"
                                                            type="tel"
                                                            //@ts-ignore
                                                            placeholder={t("VerificationCode")}
                                                            //@ts-ignore
                                                            maxLength="4"
                                                            pattern="[0-9]*"
                                                        />

                                                        <div className="mb-3"></div>
                                                        <button onClick={(e) => { sendVerifyCode(); e.preventDefault() }} className="btn btn-primary d-block w-100 mt-3 reg" type="submit" name="submit">{t("Confirm")}</button>
                                                    </form>
                                                    <a style={{ cursor: 'pointer' }} onClick={() => resendVerification()} className="fs--1 text-600 text-code">{t("DidntReceiveCode")}<span className="d-inline-block ms-1">&rarr;</span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default VerificationAfterSignUp;