import { useTranslation } from 'react-i18next';
import styles from './NotFound.module.scss';
import pulpal404 from '../../assets/pulpal404.svg';
import { NavLink } from 'react-router-dom';

const NotFound = () => {
    const { t } = useTranslation();
    return (
        <div className={`${styles.notFound}`}>
            <div className="col-sm-10 col-md-8 col-lg-6 col-xxl-5">
                <NavLink className="d-flex flex-center mb-4" to='/'>
                    <img className="me-2" src={pulpal404} alt="" width="58" />
                    <span className="font-sans-serif fw-bolder fs-5 d-inline-block">PulPal</span>
                </NavLink>
                <div className="card">
                    <div className="card-body p-4 p-sm-5">
                        <div className="fw-black lh-1 text-300 fs-error">404</div>
                        <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold w-md-75 w-xl-100 mx-auto">{t("NotFound")}</p>
                        <hr />
                        <p>{t("NotFoundContactUs")}
                            <a href="mailto:office@pulpal.az">, {t("ContactUs")}</a>.</p>
                        <NavLink to='/' className="btn btn-primary btn-sm mt-3">
                            <span className="fas fa-home me-2"></span>
                            {t("ReturnToMainPage")}
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotFound;