import React from 'react';
import { BusinessSalesProps } from './BusinessSales.props';
import styles from '../Business.module.scss';
import { BusinessResources } from '../Business.resources';
import IconText from '../../../elements/IconText/IconText';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const BusinessSales: React.FC<BusinessSalesProps> = () => {
    const { t } = useTranslation();
    const language = localStorage.getItem("language");
    return (
        <section className={styles.sui}>
            <div className={'container'}>
                <div className={styles['sui__inner']}>
                    <div className={'row'}>
                        <div className={'col-md-10 offset-md-1 col-lg-7 offset-lg-0 text-center p-5'}>
                            <img className={styles['sui__inner-img']} src={BusinessResources.sui} alt={''} />
                        </div>
                        <div className={`col-md-10 offset-md-1 col-lg-5 offset-lg-0 ${styles['sui__right']}`}>
                            <h2 className={'alttitle'}>
                                <img src={BusinessResources.gSvg} alt={''} />{t("Sales opportunities")}
                            </h2>
                            <div className={styles['bizcab__content']}>
                                <IconText image={{ src: BusinessResources.sui01, alt: '' }} desc={`${t("Integration into your site")}`} />
                                <IconText image={{ src: BusinessResources.sui02, alt: '' }} desc={t("Connection of ready-made modules")} />
                                <IconText image={{ src: BusinessResources.sui03, alt: '' }} desc={t("Sales through QR Code")} />
                                <IconText image={{ src: BusinessResources.sui04, alt: '' }} desc={t("Selling through a link")} />
                                <IconText image={{ src: BusinessResources.sui05, alt: '' }} desc={t("Products and services page")} />
                                <IconText image={{ src: BusinessResources.sui06, alt: '' }} desc={t("Selling on social networks")} />
                            </div>

                            <div className={`d-flex justify-content-start mt-5 ${styles['sui__btns']}`}>
                                <a style={{ display: "flex", alignItems: "center", justifyContent: "center", width: '200px' }}
                                    href={`https://docs.pulpal.az/index_${language}.html#pulpal-prefiksi-il-inteqrasiya`}
                                    className={`${styles['sui__but']} ${styles['sui__but-api']}`}>
                                    <img src={BusinessResources.apiIcon} alt={''} />
                                    <p>{t("All API capabilities")}</p>
                                </a>
                                <NavLink to='/modules' className={`${styles['sui__but']} ${styles['sui__but-modules']}`}>
                                    <img src={BusinessResources.moduleIcon} alt={''} />
                                    <p>{t("Ready modules")}</p>
                                </NavLink>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default BusinessSales;