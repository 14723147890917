import { ChangeEvent, FC } from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';

interface SearchProps {
    inputString: string;
    handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const Search: FC<SearchProps> = ({ inputString, handleInputChange }) => {
    const { t } = useTranslation();
    // console.log('t')
    return (
        <ul style={{marginBottom: '10px'}} className='navbar-nav d-lg-block'>
            <li className='nav-item'>
                <div className='search-box' data-list='{"valueNames":["title"]}'>
                    <form
                        className='position-relative'
                        data-bs-toggle='search'
                        data-bs-display='static'
                    >
                        <div style={{ position: 'relative' }}>
                            <input
                                className='form-control search-input fuzzy-search defInput'
                                type='search'
                                placeholder={t('search') + '...'}
                                aria-label='Search'
                                value={inputString}
                                onChange={handleInputChange}
                            />
                            <span style={{ position: 'absolute', top: '14px', left: '15px' }} className='fas fa-search search-box-icon lupa'></span>
                        </div>
                    </form>

                    <div
                        className='btn-close-falcon-container position-absolute end-0 top-50 translate-middle shadow-none'
                        data-bs-dismiss='search'
                    >
                        <button
                            className='btn btn-link btn-close-falcon p-0'
                            aria-label='Close'
                        ></button>
                    </div>

                    {/* <div className='dropdown-menu border font-base start-0 mt-2 py-0 overflow-hidden w-100'>
                        <div className='scrollbar list py-3' style={{ maxHeight: '24rem' }}>
                            <h6 className='dropdown-header fw-medium text-uppercase px-x1 fs--2 pt-0 pb-2'>
                                {t('recentActivities')}
                            </h6>
                            <a className='dropdown-item fs--1 px-x1 py-1 hover-primary' href='#'>
                                <div className='d-flex align-items-center'>
                                    <span className='fas fa-circle me-2 text-300 fs--2'></span>

                                    <div className='fw-normal title'>
                                        {t('transactions')+" "}
                                        <span
                                            className='fas fa-chevron-right mx-1 text-500 fs--2'
                                            data-fa-transform='shrink-2'
                                        ></span>{' '}
                                        {t('payments')}
                                    </div>
                                </div>
                            </a>

                            <a className='dropdown-item fs--1 px-x1 py-1 hover-primary' href='#'>
                                <div className='d-flex align-items-center'>
                                    <span className='fas fa-circle me-2 text-300 fs--2'></span>

                                    <div className='fw-normal title'>
                                        {t('wallet')+' '}
                                        <span
                                            className='fas fa-chevron-right mx-1 text-500 fs--2'
                                            data-fa-transform='shrink-2'
                                        ></span>{' '}
                                        {t('cards')}
                                    </div>
                                </div>
                            </a>

                            <hr className='text-200 dark__text-900' />

                            <h6 className='dropdown-header fw-medium text-uppercase px-x1 fs--2 pt-0 pb-2'>
                                Son axtarılan ödəmələr
                            </h6>
                            <a className='dropdown-item px-x1 py-2' href='#!'>
                                <div className='d-flex align-items-center'>
                                    <div className='file-thumbnail me-2'>
                                        <img
                                            className='border h-100 w-100 fit-cover rounded-3'
                                            src={azercell}
                                            alt='Azercell'
                                        />
                                    </div>

                                    <div className='flex-1'>
                                        <h6 className='mb-0 title'>Azercell</h6>

                                        <p className='fs--2 mb-0 d-flex'>
                                            <span className='fw-semi-bold'>Mobil rabitə</span>
                                        </p>
                                    </div>
                                </div>
                            </a>

                            <a className='dropdown-item px-x1 py-2' href='#'>
                                <div className='d-flex align-items-center'>
                                    <div className='file-thumbnail me-2'>
                                        <img className='img-fluid' src={azercell} alt='Azərişıq' />
                                    </div>

                                    <div className='flex-1'>
                                        <h6 className='mb-0 title'>Azərişıq</h6>

                                        <p className='fs--2 mb-0 d-flex'>
                                            <span className='fw-semi-bold'>{t('utility')}</span>
                                        </p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='text-center mt-n3'>
                            <p className='fallback fw-bold fs-1 d-none'>Axtarış nəticə vermədi.</p>
                        </div>
                    </div> */}
                </div>
            </li>
        </ul>
    );
};
