import C2CIcon from './../../../assets/icons/c2c.svg';
import SVG01 from './../../../assets/icons/01.svg';
import SVG02 from './../../../assets/icons/02.svg';
import SVG03 from './../../../assets/icons/03.svg';
import SVG04 from './../../../assets/icons/04.svg';
import SVG05 from './../../../assets/icons/05.svg';
import AR from './../../../assets/icons/ar.svg';

export const C2CSources = {
    c2cIcon: C2CIcon,
    svg01: SVG01,
    svg02: SVG02,
    svg03: SVG03,
    svg04: SVG04,
    svg05: SVG05,
    ar: AR
}