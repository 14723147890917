import React from 'react';
import GoogleSVG from './../../../assets/slider/google.svg';
import AppStore from './../../../assets/apple.svg';
import AppGallerySVG from './../../../assets/slider/appgallery.svg';
import Slide1PNG from './../../../assets/slider/slide1.png';
import Slide3PNG from './../../../assets/slider/slide3.png';
import OdeqebPNG from './../../../assets/odeqeb.png';
import SliderItem from './SliderItem';
import styles from './../Home.module.scss';
import { SliderProps } from './slider.props';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const Slider: React.FC<SliderProps> = () => {
    const { t } = useTranslation();

    return (
        <section className={styles.slider}>
            <div className={'container'}>
                <div id='carouselExampleControls' className={'carousel slide'} data-bs-ride='carousel'>
                    <div className={'carousel-inner'}>
                        {/* <!-- Slide 1 --> */}
                        <SliderItem
                            isActive={true}
                            image={Slide1PNG}
                            alt={'PulPal mobile app'}
                            title={`${t("BusinessAccount")}`}
                            text={`${t("MakeItEasier")}`}>
                            <div className={styles.apps}>
                                <a className={styles.apps_link} href='https://play.google.com/store/apps/details?id=az.pulpal&hl=ru&gl=US'><img src={GoogleSVG} alt='Google Play' /></a>
                                <a className={styles.apps_link} href='https://apps.apple.com/ru/app/pulpal/id1670623136'><img className={styles.appStore} src={AppStore} alt='Google Play' /></a>
                                <a className={styles.apps_link} href='https://appgallery.huawei.com/app/C105530163'><img src={AppGallerySVG} alt='App Gallery' /></a>
                            </div>
                        </SliderItem>
                        {/* <!-- Slide 2 --> */}
                        <SliderItem
                            isActive={false}
                            image={OdeqebPNG} alt={'Ödənişlərin qəbulu'}
                            title={`${t("AcceptPaymentsByCard")}`}
                            text={`${t("OurTechnologicalSolutions")}`}
                        >
                            <>
                                <NavLink to='/sign-up' type="button" className="btn btn-dark mt-3">{t("OPEN ONLINE CASHBOX")}</NavLink>
                            </>
                        </SliderItem>
                        {/* <!-- Slide 3 --> */}
                        <SliderItem
                            isActive={false}
                            image={Slide3PNG} alt={'Pulpal Merchant Cabinet'}
                            title={`${t("AllOnlinePayments")}`}
                            text={`${t("ThePulpalApp")}`}
                        />
                    </div>
                    <button className={'carousel-control-prev'} type='button'
                        data-bs-target='#carouselExampleControls' data-bs-slide='prev'>
                        <span className={'carousel-control-prev-icon'} aria-hidden='true'></span>
                        <span className={'visually-hidden'}>Previous</span>
                    </button>
                    <button className={'carousel-control-next'} type='button' data-bs-target='#carouselExampleControls'
                        data-bs-slide='next'>
                        <span className={'carousel-control-next-icon'} aria-hidden='true'></span>
                        <span className={'visually-hidden'}>Next</span>
                    </button>
                </div>
            </div>
        </section>
    );
}

export default Slider;