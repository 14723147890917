import React from 'react'
import "./index.scss"

const ItemList = ({
    classNames = '',
    itemsClassNames = '',
    itemClassNames = '',
    items,
    margin = 22,
}) => {

    return (
        <>
            {items.map((item, i) => {
                return (
                    <div
                        key={i}
                        className="col-xxl-3 col-md-4 itemBlock"
                    >
                        {item}
                    </div>
                )
            })}
        </>
    )
}

export default ItemList
