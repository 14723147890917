// import { AuthActionCreators } from './auth/action-creators'
import { LoadingActionCreators } from './loading/action-creators'
// import { ProductsActionCreators } from './products/action-creators'
// import { PurchasesActionCreators } from './purchases/action-creators'
// import { WalletsActionCreators } from './wallets/action-creators'
import { PulPalServicesActionCreators } from './pulPalServices/action-creators'
// import { HistoryActionCreators } from './history/action-creators'
// import { IdentificationOnlineActionCreators } from './identificationOnline/action-creators'
// import {LanguageActionCreators} from "./language/action-creators";
// import { ExportPurchasesActionCreators } from "./exportPurchases/action-creators";
// import {IdentificationStatusActionCreators} from "./identificationStatus/action-creators";


export const allActionCreators = {
  // ...AuthActionCreators,
  ...LoadingActionCreators,
  // ...ProductsActionCreators,
  // ...LanguageActionCreators,
  // ...PurchasesActionCreators,
  // ...WalletsActionCreators,
  ...PulPalServicesActionCreators,
  // ...HistoryActionCreators,
  // ...IdentificationOnlineActionCreators,
  // ...ExportPurchasesActionCreators,
  // ...IdentificationStatusActionCreators
}
