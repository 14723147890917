import styles from './modal.module.scss';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalProps } from './modal.props';
import { NavLink, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { setAuthorizationAC, setUserName } from '../../redux/reducers/helper/helper';
import { useAppDispatch } from '../hooks/redux';
import SignUpIndividualService from '../../services/individual';
import SignUpEntityService from '../../services/entity';
import { goToCabinet, goToMerchant } from '../../utils/utils';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import eye from '../../assets/icons/eye.svg';
import eye2 from '../../assets/icons/eye2.svg';

const Modal: React.FC<ModalProps> = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [individualData, setIndividualData] = useState({ phoneOrEmail: '', password: '' });
    const [entityData, setEntityData] = useState({ phoneOrEmail: '', password: '' });
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { handleSubmit, setError, register, formState: {
        errors,
    } }: any = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordEntity, setShowPasswordEntity] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const togglePasswordVisibilityEntity = () => {
        setShowPasswordEntity(!showPasswordEntity);
    };

    const navigate = useNavigate();

    const handleCheckInput = (params: any) => {
        let check = params.phoneOrEmail.includes('@');
        return check;
    };
    const { language } = useSelector((state: any) => state.languageReducer);

    // ----------------------------- SIGN IN INDIVIDUAL -----------------------------

    const dataFromSignInIndividual = async (data: any) => {
        if (data?.status === 200) {
            dispatch(setAuthorizationAC(true));
            localStorage.setItem('pulpalAuth', JSON.stringify(data.data));
            const self = await SignUpIndividualService.getSelf();
            dispatch(setUserName(self?.data.firstName));
            localStorage.setItem('phone', individualData.phoneOrEmail);
            goToCabinet();
            $('#exampleModal').modal('hide');
        }
        else if (data.status === 400 && (data.data.code === 1005 || data.data.code === 1006)) {
            setError('individual', {
                type: 'notverified',
                message: t("IsNotVerified")
            })
        }
        else if (data.status === 400 && (data.data.code === 1010 || data.data.code === 1011)) {
            setError('individual', {
                type: 'incorrect',
                message: t("PhoneOrPassIncorrect")
            })
        }
    };


    const signInIndividual = async () => {
        if (handleCheckInput(individualData)) {
            setIsLoading(true);
            const data: any = await SignUpIndividualService.signInIndividualWithEmail(individualData);
            setIsLoading(false);
            dataFromSignInIndividual(data);
        }
        else {
            setIsLoading(true);
            const data: any = await SignUpIndividualService.signInIndividualWithPhone(individualData);
            setIsLoading(false)
            dataFromSignInIndividual(data);
        }
    };

    // ----------------------------- SIGN IN ENTITY -----------------------------



    const dataFromSignUpEntity = async (data: any) => {
        if (data?.status === 200) {
            localStorage.setItem("pulpalAuth", JSON.stringify(data.data));
            const self = await SignUpIndividualService.getSelf();
            localStorage.setItem('username', self?.data.firstName)
            dispatch(setUserName(self?.data.firstName));
            const merchantData = await SignUpEntityService.getByUserId();
            if (
                (merchantData?.data?.length == 1 && merchantData?.data[0]?.status === 20)
                || merchantData?.data?.length > 1
            ) {
                dispatch(setAuthorizationAC(true));
                $("#exampleModal").modal("hide");
                localStorage.setItem("phone", individualData.phoneOrEmail);
                goToMerchant(language);
            }
            else if (merchantData?.data?.length == 1 && merchantData?.data[0]?.status === 30) {
                setError('entity', {
                    type: 'reject',
                    message: t("Reject")
                })
            }
            else {
                setError('entity', {
                    type: 'wait',
                    message: t("wait")
                })
                await SignUpIndividualService.signOut();
            }
        }
        else if (data?.status === 400 && (data?.data?.code === 1005 || data.data.code === 1006)) {
            setError('entity', {
                type: 'notverified',
                message: t("IsNotVerified")
            })
        }
        else if (data?.status === 400 && data?.data?.code === 1010 || data.data.code === 1011) {
            setError('entity', {
                type: 'incorrect',
                message: t("PhoneOrPassIncorrect")
            })

        }
    }

    const signInEntity = async () => {
        if (handleCheckInput(entityData)) {
            setIsLoading(true);
            const data: any = await SignUpEntityService.signInEntityWithEmail(entityData);
            setIsLoading(false);
            dataFromSignUpEntity(data);
        }
        else {
            setIsLoading(true);
            const data: any = await SignUpEntityService.signInEntityWithPhone(entityData);
            setIsLoading(false);
            dataFromSignUpEntity(data);
        }
    };

    const resendVerification = async () => {
        let phoneNumberWithoutFirstChar;
        if (activeTab === 0) {
            phoneNumberWithoutFirstChar = individualData.phoneOrEmail.slice(1);
        }
        else {
            phoneNumberWithoutFirstChar = entityData.phoneOrEmail.slice(1);
        }
        // const phoneNumberWithoutFirstChar = individualData.phoneOrEmail.slice(1);
        console.log('phoneNumberWithoutFirstChar', phoneNumberWithoutFirstChar)
        await SignUpIndividualService.resendVerification({ phoneOrEmail: '%2B' + phoneNumberWithoutFirstChar });
        $("#exampleModal").modal("hide");
        toast.success(t('CodeResubmitted'), {
            position: toast.POSITION.TOP_CENTER,
        })
    }

    const resendWithEmail = async (email: any) => {
        await SignUpIndividualService.resendVerificationWithEmail({ phoneOrEmail: email });
        $("#exampleModal").modal("hide");
        toast.success(t('ResubmittedCodeEmail'), {
            position: toast.POSITION.TOP_CENTER,
        })
    }

    const verifyAgain = () => {
        $('#exampleModal').modal('hide');
        localStorage.removeItem('phone');
        localStorage.removeItem('email');

        if (activeTab === 0) {
            if (handleCheckInput(individualData)) {
                resendWithEmail(individualData.phoneOrEmail)
            }
            else {
                navigate(`re-verification?phone=${individualData.phoneOrEmail}&subject=$phone`, { replace: true, state: { phone: individualData.phoneOrEmail, password: individualData.password } });
                resendVerification();
            }
        }
        else {
            if (handleCheckInput(entityData)) {
                resendWithEmail(entityData.phoneOrEmail)
            }
            else {
                navigate(`re-verification?phone=${entityData.phoneOrEmail}&subject=$phone`, { replace: true, state: { phone: entityData.phoneOrEmail, password: entityData.password } });
                resendVerification();
            }
        }
    }

    const onSubmit = () => { };
    const onSubmitReg = () => { };

    const handleModalClose = () => {
        localStorage.removeItem('redirectToService')
    };

    useEffect(() => {
        const modalElement = document.getElementById('exampleModal');
        modalElement?.addEventListener('hide.bs.modal', handleModalClose);
        return () => {
            modalElement?.removeEventListener('hide.bs.modal', handleModalClose);
        };
    }, []);

    return (
        <div className={'modal fade'} id={'exampleModal'} tabIndex={-1} aria-labelledby={'exampleModalLabel'} aria-hidden={'true'}>
            <div className={'modal-dialog'}>
                <div className={'modal-content'}>
                    <div className={`modal-header`}>
                        <h1 className={'modal-title fs-5'} id={'exampleModalLabel'}>{t("Login or Register")}</h1>
                        <button type={'button'} className={'btn-close'} data-bs-dismiss={'modal'} aria-label={'Close'}></button>
                    </div>
                    <div className={'modal-body'}>
                        <ul className={'nav nav-pills mb-3'} id={'pills-tab'} role={'tablist'}>
                            <li className={'nav-item'} role={'presentation'}>
                                <button onClick={() => setActiveTab(0)} className={'nav-link active'} id={'pills-home-tab'} data-bs-toggle={'pill'}
                                    data-bs-target={'#pills-home'} type={'button'} role={'tab'} aria-controls={'pills-home'} aria-selected={'true'}>
                                    {t("Merchant")}
                                </button>
                            </li>
                            <li className={'nav-item'} role='presentation'>
                                <button onClick={() => setActiveTab(1)} className={'nav-link'} id={'pills-profile-tab'} data-bs-toggle={'pill'}
                                    data-bs-target={'#pills-profile'} type={'button'} role={'tab'} aria-controls={'pills-profile'} aria-selected={'false'}>
                                    {t("User")}
                                </button>
                            </li>
                            <li className={styles.navlinkItem}>
                                <button data-bs-dismiss={'modal'} className={`${styles.navlinkReg} nav-link`}>
                                    <NavLink to="/sign-up-physical" style={{ fontSize: '14px' }} aria-label={'Close'} className={styles.navlinkReg} >{t("Register")}</NavLink>
                                </button>
                            </li>
                        </ul>
                        <div className='tab-content' id='pills-tabContent'>
                            <div className='tab-pane fade show active' id='pills-home' role='tabpanel' aria-labelledby='pills-home-tab' tabIndex={0}>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className='giris'>
                                        <div className='form-floating mb-3'>
                                            <input
                                                value={individualData.phoneOrEmail}
                                                {...register('phoneOrEmailIndividual', { required: true })}
                                                onChange={(e) => setIndividualData({ ...individualData, phoneOrEmail: e.target.value })}
                                                type='text'
                                                className='form-control'
                                                id='floatingInput'
                                                placeholder='+99450 000 00 00'
                                            />
                                            <div className={styles.info}>
                                                <i className="pi pi-info-circle" style={{ fontSize: '1.2rem' }}></i>
                                                <div className={styles.infoText}>
                                                    {t("InfoPhoneText")}
                                                </div>
                                            </div>
                                            <label htmlFor={'floatingInput'}>{t("PhoneOrEmail")}</label>
                                        </div>
                                        <div className='form-floating'>
                                            <input
                                                value={individualData.password}
                                                onChange={(e) => setIndividualData({ ...individualData, password: e.target.value })}
                                                type={showPassword ? 'text' : 'password'}
                                                className='form-control'
                                                id='floatingPassword'
                                                placeholder='Password'
                                            />
                                            {showPassword ? <img className={styles.eyeIcon} src={eye2} onClick={togglePasswordVisibility} /> : <img className={styles.eyeIcon} src={eye} onClick={togglePasswordVisibility} />}
                                            <label htmlFor={'floatingPassword'}>{t("Password")}</label>
                                        </div>
                                        {errors.individual && errors.individual.type === "incorrect" && <p className={styles.incorrectMessage}>{errors.individual.message}</p>}
                                        {errors.individual && errors.individual.type === "notverified" &&
                                            <div className={styles.errorBlock}>
                                                <div className={styles.incorrectMessage}>
                                                    <p>{errors.individual.message}</p>
                                                </div>
                                                <span className={styles.btnSendCodeAgain} onClick={() => verifyAgain()}>{t("Confirm")}</span>
                                            </div>
                                        }
                                        <span data-bs-dismiss={'modal'}>
                                            <NavLink to='/forgotPassword' className={`${styles.login__link} m-2`}>{t("Forgot password")}</NavLink>
                                        </span>
                                        <div className='d-grid'>
                                            <button
                                                onClick={async () => await signInIndividual()}
                                                type='submit'
                                                className={`${styles.btnCnt} button button-c2c mt-1`}
                                                disabled={isLoading || !individualData.phoneOrEmail || !individualData.password}
                                            >
                                                {isLoading ? <i className="pi pi-spin pi-spinner" style={{ fontSize: '1.2rem' }}></i> : `${t("SignIn")}`}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className='tab-pane fade' id='pills-profile' role='tabpanel' aria-labelledby='pills-profile-tab' tabIndex={0}>
                                <form onSubmit={handleSubmit(onSubmitReg)}>
                                    <div className='giris'>
                                        <div className='form-floating mb-3'>
                                            <input
                                                value={entityData.phoneOrEmail}
                                                onChange={(e) => setEntityData({ ...entityData, phoneOrEmail: e.target.value })}
                                                type='text'
                                                className='form-control'
                                                id='floatingInput'
                                                placeholder='name@example.com'
                                            />
                                            <div className={styles.info}>
                                                <i className="pi pi-info-circle" style={{ fontSize: '1.2rem' }}></i>
                                                <div className={styles.infoText}>
                                                    {t("InfoPhoneText")}
                                                </div>
                                            </div>
                                            <label htmlFor={'floatingInput'}>{t("PhoneOrEmail")}</label>
                                        </div>
                                        <div className='form-floating'>
                                            <input
                                                onChange={(e) => setEntityData({ ...entityData, password: e.target.value })}
                                                type={showPasswordEntity ? 'text' : 'password'}
                                                className='form-control'
                                                id='floatingPassword'
                                                placeholder='Password'
                                            />
                                            {showPasswordEntity ? <img className={styles.eyeIcon} src={eye2} onClick={togglePasswordVisibilityEntity} /> : <img className={styles.eyeIcon} src={eye} onClick={togglePasswordVisibilityEntity} />}
                                            <label htmlFor='floatingPassword'>{t("Password")}</label>
                                        </div>
                                        {errors.entity && errors.entity.type === "incorrect" && <p className={styles.incorrectMessage}>{errors.entity.message}</p>}
                                        {errors.entity && errors.entity.type === "notverified" &&
                                            <div className={styles.errorBlock}>
                                                <div className={styles.incorrectMessage}>
                                                    <p>{errors.entity.message}</p>
                                                </div>
                                                <span className={styles.btnSendCodeAgain} onClick={() => verifyAgain()}>{t("Confirm")}</span>
                                            </div>}
                                        {errors.entity && errors.entity.type === "wait" &&
                                            <div className={styles.incorrectMessage}>
                                                {language === 'az' ?
                                                    <p className={styles.note}>Müraciətiniz nəzərdən keçirilir. Hər hansı sualınız olarsa, <span className={styles.noteNumber}> +994776778777</span> nömrəsi ilə PulPal ilə əlaqə saxlayın</p>
                                                    : <><p className={styles.note}>{t(`Wait`)}</p><span className={styles.noteNumber}> +994776778777</span></>
                                                }
                                            </div>
                                        }

                                        {errors.entity && errors.entity.type === "reject" &&
                                            <div className={styles.incorrectMessage}>
                                                {language === 'az' ?
                                                    <p className={styles.reject}>Satıcı yaratmaq üçün sorğunuz rədd edildi.
                                                        Hər hansı sualınız olarsa, <span className={styles.rejectNumber}> +994774137707</span> nömrəsi ilə PulPal ilə əlaqə saxlayın</p>
                                                    : <><p className={styles.reject}>{t(`Reject`)}</p><span className={styles.rejectNumber}> +994774137707</span></>
                                                }
                                            </div>
                                        }

                                        <span data-bs-dismiss={'modal'}>
                                            <NavLink to='/forgotPassword' className={`${styles.login__link} m-2`}>{t("Forgot password")}</NavLink>
                                        </span>
                                        <div className='d-grid'>
                                            <button
                                                onClick={async () => await signInEntity()}
                                                type='submit'
                                                className={`${styles.btnCnt} button button-c2c mt-1`}
                                                // disabled={isLoading}
                                                disabled={isLoading || !entityData.phoneOrEmail || !entityData.password}
                                            >
                                                {isLoading ? <i className="pi pi-spin pi-spinner" style={{ fontSize: '1.2rem' }}></i> : `${t("SignIn")}`}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Modal;