import { LocaleName } from "../../../models/Categories";
import { actionTypes } from "./types";

interface ChangeLangState {
    language: string | 'ru';
    langKey: LocaleName["valueRu"] | LocaleName["valueAz"] | LocaleName["valueEn"];
};

const initialState: ChangeLangState = {
    language: localStorage.getItem("language") || 'ru',
    langKey: localStorage.getItem("langKey") || 'valueRu',
};

const languageReducer: any = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.CHANGE_LANG: {
            localStorage.setItem('language', action.payload.language)
            localStorage.setItem('langKey', action.payload.langKey)
            return {
                ...state,
                langKey: action.payload.langKey,
                language: action.payload.language
            }
        }
        default: return state;
    }
};

export default languageReducer;