import axios from "axios";
import { c2cUrl } from "../http";

export default class C2CService {
    static async amountTransfer(transferOptions: any) {
        try {
            return await axios.post(`${c2cUrl}/register`, {
                ...transferOptions, amount: transferOptions.amount * 100
            })
        }
        catch (err) {
            console.log('err')
        }
    }
}