import React from 'react';
import AboutCard from './AboutCard';
import { aboutCardSources } from '../AboutModcards/AboutCard.sources';
import { AboutModcardsProps } from '../AboutModcards/AboutModcards.props';
import styles from './../About.module.scss';
import { useTranslation } from 'react-i18next';

const AboutModcards: React.FC<AboutModcardsProps> = () => {
    const { t } = useTranslation();
    return (
        <div className={styles.modcards}>
            <div className={'container'}>
                <div className={'row'}>
                    <AboutCard
                        image={{ src: aboutCardSources.svg01, alt: '' }}
                        title={`${t("The main benefits of working with us")}`}
                        desc={`${t("We know very well")}`}
                    />

                    <AboutCard
                        image={{ src: aboutCardSources.svg02, alt: '' }}
                        title={`${t("What is needed for your customer's convenience?")}`}
                        desc={`${t("Of course, your customer")}`}
                    />

                    <AboutCard
                        image={{ src: aboutCardSources.svg03, alt: '' }}
                        title={`${t("How do we work?")}`}
                        desc={`${t("You fill out")}`}
                    />
                </div>
            </div>
        </div>
    );
}

export default AboutModcards;