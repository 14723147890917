import BLogo from './../../assets/b-logo.svg';
import FacebookSVG from './../../assets/social/facebook.svg';
import InstagramSVG from './../../assets/social/instagram.svg';
import LinkedInSVG from './../../assets/social/linkedin.svg';
import YouTubeSVG from './../../assets/social/youtube.svg';
import PhoneSVG from './../../assets/phone.svg';
import MailSVG from './../../assets/mail.svg';

export const FooterResources = {
    bLogo: BLogo,
    facebookLogo: FacebookSVG,
    instagramLogo: InstagramSVG,
    linkedInSvg: LinkedInSVG,
    youTubeSvg: YouTubeSVG,
    phoneSvg: PhoneSVG,
    mailSvg: MailSVG
}