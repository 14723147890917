import axios from 'axios';
import AuthService from '../services/auth';

export const c2cUrl = process.env.REACT_APP_C2C;
export const messageToAdminUrl = process.env.REACT_APP_MESSAGE_TO_ADMIN;
export const localApiAuthUrl = process.env.REACT_APP_LOCAL_API_AUTH;
export const merchantApi = process.env.REACT_APP_API_MERCHANT;

export const cabinetUrl = process.env.REACT_APP_CABINET_URL;
export const merchantUrl = process.env.REACT_APP_MERCHANT_URL;
export const restorePassword =
  process.env.REACT_APP_MERCHANT_URL_RESTORE_PASSWORD;
export const pulpalServices = process.env.REACT_APP_PULPAL_SERVICES;
export const pulServSaveCredit = process.env.REACT_APP_PUL_SERV_SAVE_CREDIT;
export const payment = process.env.REACT_APP_PAYMENT;
export const imageService = process.env.REACT_APP_IMAGE_SERVICE;

export const user = localApiAuthUrl + '/auth';
export const userSignUp = user + '/signUp';
export const axiosAuth = axios.create({
  baseURL: user,
});

axios.interceptors.request.use((config) => {
  if (AuthService.getAuthData()?.auth) {
    config.headers = {
      ...config.headers,
      Authorization: AuthService.getAuthData().auth,
      'Content-Type': 'application/json; charset=utf8',
    };
  }
  return config;
});

// axios.interceptors.request.use(config => {
//   if (AuthService.getAuthData()?.auth) {
//     if (!config.url.includes('verifyByPhone')) { // Проверка URL запроса
//       config.headers = {
//         ...config.headers,
//         Authorization: AuthService.getAuthData().auth,
//         'Content-Type': 'application/json; charset=utf8',
//       }
//     }
//   }
//   return config;
// });

export const $auth = axios.create({
  baseURL: localApiAuthUrl + '/auth',
});

let refreshTokenPromise;

axios.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      error.config &&
      !error.config._isRetry
    ) {
      originalRequest._isRetry = true;
      if (!refreshTokenPromise) {
        refreshTokenPromise = $auth
          .post(
            '/refresh',
            {},
            {
              headers: {
                Authorization: `${AuthService.getAuthData()?.refresh}`,
              },
            }
          )
          .then(({ data }) => {
            AuthService.setAuthData(data);
            refreshTokenPromise = null;
            return data;
          })
          .catch(() => {
            AuthService.removeAuthData();
            //   redirectToSignInPage()
          });
      }
      return refreshTokenPromise.then((data) => {
        error.config.headers['Authorization'] = data?.auth;
        refreshTokenPromise = null;
        return axios.request(error.config);
      });
    } else if (error.response.status === 400) {
      return Promise.reject(error);
    }

    try {
    } catch (err) {
      Promise.reject(error);
    }
  }
);
