import React from 'react';
import { useTranslation } from 'react-i18next';
import { AboutSliderProps } from '../AboutSlider/AboutSlider.props';
import styles from './../About.module.scss';

const AboutSlider: React.FC<AboutSliderProps> = () => {
    const { t } = useTranslation();

    return (
        <section className={`${styles.about} page-slider`}>
            <div className={'container'}>
                <div className={styles['about__inner']}>
                    <div className={styles['about__box']}>
                        <h1 className={styles.h_one}>
                            {t("Physical person payment for opportunities")}
                        </h1>
                        <p className={'slider__text'}>
                            {t("PulPal electronic payment")}
                        </p>
                    </div>
                </div>
            </div>
        </section >
    );
}

export default AboutSlider;