import './Success.scss';
import userSuccessImg from '../../assets/user-success.svg';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const VoenSuccess = () => {
    const { t } = useTranslation();

    return (
        <main className="main success" id="top">
            <div className="container">
                <div className="m-5 text-center">
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-lg-6 text-center">
                            <img className="m-3 w-75" src={userSuccessImg} alt="voenSuccess" />
                        </div>
                    </div>
                    <h3 className="text-center mt-3">{t("CongratsVoenSuccess")}<span className="text-success"> {t("Success")} </span>{t("Received")}</h3>
                    <p className="text-center">
                        {t("OurStaff")}
                    </p>
                    <NavLink style={{background: '#00d27a', borderColor: 'inherit'}} className="btn btn-success mt-5" to='/'>{t("GoToMain")}</NavLink>
                </div>
            </div>
        </main>
    )
}

export default VoenSuccess;