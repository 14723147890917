import {Link} from 'react-router-dom'
import "./index.scss"

// interface PaymentViewProps {
//     icon?: any;
//     linkTo?: any;
//     imageSrc?: any;
//     children?: any;
//     labelStyle?: any;
// }

const PaymentView = ({ linkTo, icon, imageSrc, children, ...props }) => {
    return (
        <div className="hover-bg-100 py-x1 text-center rounded-3 position-relative">
            {imageSrc ? (
                imageSrc.replaceAll('data:image/png;base64,', '') !== 'undefined' ? (
                    <img style={{margin: "0 auto", width: 60,
                        height: 60,
                        display: "flex",
                        alignSelf: "center", borderRadius: "50%"}} src={icon} alt={children} />
                ) : (
                    <img style={{margin: "0 auto", width: 60,
                        height: 60,
                        display: "flex",
                        alignSelf: "center", borderRadius: "50%"}} src={icon} alt={''} />
                )
            ) : (
                <img style={{margin: "0 auto", width: 60,
                    height: 60,
                    display: "flex",
                    alignSelf: "center", borderRadius: "50%"
                }} src={icon} alt={''}/>
            )}
            <h5 className='mt-3 mb-2'>
                <Link to={linkTo} className='text-900 hover-primary stretched-link'>
                    {children}
                </Link>
            </h5>
        </div>
)
}

export default PaymentView
