const initialState = {
    authorized: false,
    userName: '',
    anotherPage: false
};

const helperActionTypes = {
    SET_AUTHORIZATION: 'SET_AUTHORIZATION',
    SET_USER_NAME: 'SET_USER_NAME',
    SET_ANOTHER_PAGE: 'SET_ANOTHER_PAGE'
};

const helperReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case helperActionTypes.SET_AUTHORIZATION:
            return {
                ...state,
                authorized: action.payload
            }
        case helperActionTypes.SET_USER_NAME:
            return {
                ...state,
                userName: action.payload
            }
        case helperActionTypes.SET_ANOTHER_PAGE:
            return {
                ...state,
                anotherPage: action.payload
            }
        default: return state
    }
}

export const setAuthorizationAC: any = (bool: boolean) => {
    return {
        type: helperActionTypes.SET_AUTHORIZATION,
        payload: bool
    }
}

export const setUserName: any = (name: any) => {
    return {
        type: helperActionTypes.SET_USER_NAME,
        payload: name
    }
}

export const setAnotherPageAC: any = (anotherPage: boolean) => {
    return {
        type: helperActionTypes.SET_ANOTHER_PAGE,
        payload: anotherPage
    }
}
export default helperReducer;
