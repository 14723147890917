import React from 'react';
import { BusinessPaymentCardProps } from './BusinessPayment.props';
import styles from '../Business.module.scss';
import { useTranslation } from 'react-i18next';

const BusinessPaymentCard: React.FC<BusinessPaymentCardProps> = ({ image, title, desc }) => {
    const {t} = useTranslation();
    return (
        <div className={styles['odeqeb__card']}>
            <div className={styles['odeqeb__card-pic']}>
                <img className={'mx-auto d-block'}
                    src={image.src}
                    alt={image.alt} />
            </div>
            <div className={styles['odeqeb__card-content']}>
                <div className={styles['odeqeb__card-title']}>{`${t(title)}`}</div>
                <div className={styles['odeqeb__card-desc']} dangerouslySetInnerHTML={{__html: desc}} />
            </div>
        </div>
    );
}

export default BusinessPaymentCard;