import React, { useEffect, useState } from 'react';
import { SignUpProps } from './SignUp.props';
import { SignUpResources } from './SignUp.resources';
import styles from './SignUp.module.scss';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import SignUpEntityService from '../../services/entity';

const SignUp: React.FC<SignUpProps> = () => {
    const { t } = useTranslation();
    const auth: any = localStorage.getItem('pulpalAuth');
    const [showPage, setShowPage] = useState(false);

    useEffect(() => {
        if (auth) {
            (async () => {
                const merchantData = await SignUpEntityService.getByUserId();
                if (
                    (merchantData?.data.length >= 1)
                ) {
                    console.log('merchantData', merchantData)
                    setShowPage(true)
                }
                else {
                    setShowPage(false)
                }
            })()
        }
    }, [])

    return (
        <section className={styles.signup}>
            <div className={'container'}>
                <div className={styles['signup__inner']}>
                    <div className={'row'}>
                        <div className={`col-12 col-lg-6 ${styles['signup__left']}`}>
                            <img src={SignUpResources.signUpPng} alt='' />
                        </div>
                        <div className={'col-12 col-lg-6'}>
                            <h2 className={'alttitle'}>
                                <img src={SignUpResources.mSvg} alt='' />{t("Are you ready to join?")}
                            </h2>
                            <p className={styles['signup__sub']}>{t("Ask our managers")}</p>
                            <p className={styles['signup__desc']}><img src={SignUpResources.checkSvg} alt='' />{t("No monthly subscription fee")}</p>
                            <p className={styles['signup__desc']}><img src={SignUpResources.checkSvg} alt='' />{t("All information about payments in your personal account")}</p>
                            <p className={styles['signup__desc']}><img src={SignUpResources.checkSvg} alt='' />{t("Commission starting from 2%")}</p>
                            <div className={styles['signup__footer']}>
                                <div className={styles['signup__footer-l']}>
                                    {
                                        showPage && auth ?
                                            <NavLink to='/add-merchant' className={`${styles.openCashbox} button button-c2c`}>{t("addCashbox")} &nbsp;<img className={styles.imgArrow} src={SignUpResources.arSvg} alt='' /></NavLink>
                                            :
                                            <NavLink to='/sign-up' className={`${styles.openCashbox} button button-c2c`}>{t("OPEN ONLINE CASHBOX")} &nbsp;<img className={styles.imgArrow} src={SignUpResources.arSvg} alt='' /></NavLink>
                                    }
                                </div>
                                <div className={styles['signup__footer-r']}>
                                    <a href='' className={styles['footer__links']} data-bs-toggle='modal' data-bs-target='#askQuestionModal'>{t("To ask a question")}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SignUp;