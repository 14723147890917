import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./AcceptationRequest.scss";
import { useLocation, useParams } from "react-router";
import SignUpEntityService from "../../services/entity";
import { NavLink } from "react-router-dom";
import { goToMerchant } from "../../utils/utils";
import PulPalLoader from "../PulPalLoader/PulPalLoader";
import AuthService from "../../services/auth";
import { useSelector } from "react-redux";

const AcceptationRequest = () => {
    const [loader, setLoader] = useState(false);
    const [merchantInfo, setMerchantInfo] = useState({
        id: '',
        website: '',
        country: '',
        house: '',
        fullname: '',
        building: '',
        email: '',
        voen: '',
        organizationName: '',
        mainActivities: '',
        city: '',
        apartment: '',
        position: '',
        phoneNumber: '',
        status: '',
        name: ''
    });

    const { t } = useTranslation();
    const loc = useLocation();
    const params = useParams();
    const { language } = useSelector((state: any) => state.languageReducer);

    const getMerchantById = async (id: string | undefined) => {
        setLoader(true);
        try {
            const data = await SignUpEntityService.getMerchant(id);
            const { merchant } = data.data;
            setMerchantInfo({
                ...merchantInfo,
                id: merchant.id,
                website: merchant?.website,
                country: merchant?.country,
                house: merchant?.house,
                fullname: merchant?.contactName,
                building: merchant?.building,
                email: merchant?.contactEmail,
                voen: merchant?.voen,
                organizationName: merchant?.organizationName,
                mainActivities: merchant?.mainActivity,
                city: merchant?.city,
                apartment: merchant?.apartment,
                position: merchant?.contactPosition,
                phoneNumber: merchant?.contactMobile,
                status: merchant?.status,
                name: merchant?.name
            });
            setLoader(false);
        } catch (error) {
            //@ts-ignore
            if (error.response?.status === 401) {
                try {
                    //@ts-ignore
                    await AuthService.refreshToken();
                    await getMerchantById(id);
                } catch (refreshError) {
                    console.error("Ошибка обновления токена:", refreshError);
                }
            } else {
                console.error("Ошибка при запросе мерчанта:", error);
            }
            setLoader(false);
        }
    };

    useEffect(() => {
        getMerchantById(params?.id);
    }, []);

    return (
        <div className="wrapper-acceptation">
            {
                loader
                    ?
                    <PulPalLoader />
                    :
                    <div className="acceptation-email">
                        <div className={merchantInfo.status == '30' ? 'merchant-rejected' : 'merchant-waited'}>{merchantInfo.status == '30' ? t("Rejected") : t("InProcessing")}</div>
                        <div className="inner-container">
                            <div className="display-flex">
                                <div>
                                    <span className="bold">ID:</span> {merchantInfo?.id}
                                </div>
                                <div>
                                    <span className="bold">{t("OrganizationName")}:</span>{" "}
                                    {merchantInfo?.organizationName}
                                </div>
                                <div>
                                    <span className="bold">{t("Website")}:</span> {merchantInfo?.website}
                                </div>
                                <div>
                                    <span className="bold">{t("Name")}:</span>{" "}
                                    {merchantInfo?.name}
                                </div>
                                <div>
                                    <span className="bold">{t("Email")}:</span> {merchantInfo?.email}
                                </div>
                                <div>
                                    <span className="bold">{t("PhoneNumber")}:</span>{" "}
                                    {merchantInfo?.phoneNumber}
                                </div>
                                <div>
                                    <span className="bold">{t("VOEN")}:</span> {merchantInfo?.voen}
                                </div>
                            </div>
                            <button
                                className="return-button"
                                type="button"
                                onClick={() => goToMerchant(language)}
                            >
                                {t("Return")}
                            </button>
                        </div>
                    </div>
            }

        </div>
    );
};

export default AcceptationRequest;
