import PulPalServicesActionTypes from './types'

const initialState = {
  categories: [],
  servicesByCategory: [],
  selectedService: {},
  servicesList: [],
  servicesListAll: [],
  error: '',
}

const pulPalServicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case PulPalServicesActionTypes.SET_CATEGORIES: {
      return { ...state, categories: action.payload }
    }
    case PulPalServicesActionTypes.SET_SERVICES: {
      return { ...state, servicesByCategory: action.payload }
    }
    case PulPalServicesActionTypes.SET_SELECTED_SERVICES: {
      return { ...state, selectedService: action.payload }
    }

    case PulPalServicesActionTypes.SET_SERVICE_LIST: {
      return { ...state, servicesList: action.payload }
    }

    case PulPalServicesActionTypes.SET_SERVICE_LIST_ALL: {
      return { ...state, servicesListAll: action.payload }
    }

    case PulPalServicesActionTypes.SET_ERROR: {
      return { ...state, error: action.payload }
    }
    default:
      return state
  }
}

export default pulPalServicesReducer
