import React, { useEffect, useState } from 'react';
import { HeaderProps, formValues } from './Header.props';
import LogoTopSVG from '../../assets/logo-top.svg';
import profileIcon from '../../assets/profileIcon.svg';
import arrow from '../../assets/arrow.svg';
import exit from '../../assets/exit.svg';
import HelpSVG from '../../assets/icons/help.svg';
import styles from './Header.module.scss';
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import { changeLangAction } from '../../redux/reducers/language/changeLangAction';
import SignUpIndividualService from '../../services/individual';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { setAuthorizationAC, setUserName } from '../../redux/reducers/helper/helper';
import { goToCabinet, goToMerchant } from '../../utils/utils';
import SignUpEntityService from '../../services/entity';
import AuthService from '../../services/auth';

const Header: React.FC<HeaderProps> = (props) => {
    const [showDropdown, setShowDropDown] = useState<boolean>(false);
    const [transferDropDown, setTransferDropDown] = useState<boolean>(false);
    const { language } = useSelector((state: any) => state.languageReducer);
    const { t, i18n } = useTranslation();
    const { handleSubmit } = useForm<formValues>();
    const [popUp, setPopUp] = useState(false);
    const dispatch = useAppDispatch();
    const authorized: boolean = useAppSelector((state: any) => state.helperReducer.authorized);
    const userName: string = useAppSelector((state: any) => state.helperReducer.userName);
    const [merchantDataLength, setMerchantDataLength] = useState(false);

    const auth = useSelector((state: any) => state.helperReducer.authorized);
    const navigate = useNavigate();

    const setLanguage = (lang: any) => {
        changeLang(lang);
        i18n.changeLanguage(lang, (err: any, t: any) => {
            if (err) return console.log("something went wrong loading", err);
            t("key"); // -> same as i18next.t
        });
    };

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setLanguage(event.target.value);
    };

    const changeLang = (language: string) => {
        let langKey: string;
        if (language === "ru") {
            langKey = "valueRu";
        } else if (language === "az") {
            langKey = "valueAz";
        } else langKey = "valueEn";
        dispatch(changeLangAction(language, langKey));
    };

    const signOut = async () => {
        await SignUpIndividualService.signOut();
        localStorage.removeItem('email');
        localStorage.removeItem('phone');
        localStorage.removeItem('username')
        dispatch(setAuthorizationAC(false));
        dispatch(setUserName(''));
        navigate('/');
    };

    const onSubmit = (data: any) => {
        // console.log(data)
    }

    const getMerchantData = async () => {
        const merchantData = await SignUpEntityService.getByUserId();
        console.log('merchantData', merchantData)
        if (merchantData.data.length > 0) {
            setMerchantDataLength(prev => !prev);
        }
    }

    useEffect(() => {
        if (auth) {
            getMerchantData();
        }
    }, [auth]);

    $('span').click(function (event) {
        event.stopPropagation();
    });

    const closeMenu = (condition: boolean) => {
        console.log('condition', condition)
        if (condition) {
            $(document).click(function (event) {
                var clickover = $(event.target);
                var $navbar = $(".navbar-collapse");
                var _opened = $navbar.hasClass("show");
                if (_opened === true && !clickover.hasClass("navbar-toggler") && !clickover.closest(".dropdown-menu").length) {
                    $navbar.collapse('hide');
                }
            });
        }
    };

    return (
        <header className={styles.header}>
            <div className={'container'}>
                <nav className={'navbar navbar-expand-lg fixed-top bg-body ' + styles.nav1}>
                    <div className={'container'}>
                        <NavLink className={styles['navbar-brand'] + ' navbar-brand'} to={'/'}>
                            <img src={LogoTopSVG} alt={'Pulpal.az'} />
                        </NavLink>
                        <button
                            className={'navbar-toggler'}
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#navbarSupportedContent'
                            aria-controls='navbarSupportedContent'
                            aria-expanded='false'
                            aria-label='Toggle navigation'>
                            <span className={'navbar-toggler-icon'}></span>
                        </button>

                        <div className={`collapse navbar-collapse`} id='navbarSupportedContent'>
                            <ul className={`navbar-nav me-auto mb-2 mb-lg-0`}>
                                <li onClick={() => closeMenu(true)} className={'nav-item'}><NavLink className={styles.nav1__links} to='/business'>{t("BUSINESS")}</NavLink></li>
                                <span>
                                    <li className={'nav-item dropdown'} onClick={() => {
                                        setTransferDropDown(!transferDropDown);
                                    }}>
                                        <a
                                            className={styles.nav1__links + ' dropdown-toggle' + (transferDropDown ? ' show' : '')}
                                            href='' role='button' data-bs-toggle='dropdown' aria-expanded='false'>
                                            {t("PAYMENTS")}
                                        </a>
                                        <ul className={'dropdown-menu shadow p-3 mb-5 bg-body rounded border border-0' + (transferDropDown ? ' show' : '')}>
                                            <li onClick={() => closeMenu(true)} className={'nav-item'}><a href={'/#c2c'} style={{ fontWeight: 'normal', fontSize: '19px' }} className={styles.nav2__links}>{t("transfer")}</a></li>
                                            <li onClick={() => closeMenu(true)} className={'nav-item'}><NavLink style={{ fontSize: '19px', fontWeight: 'normal' }} className={styles.nav2__links} to='/payments'>{t("Services")}</NavLink></li>
                                        </ul>
                                    </li>
                                </span>
                                <li onClick={() => closeMenu(true)} className={'nav-item'}><a href={'/#dev'} className={styles.nav1__links}>{t("APPLICATION")}</a></li>

                                <span>
                                    <li className={'nav-item dropdown'} onClick={() => {
                                        setShowDropDown(!showDropdown);
                                    }}>
                                        <a
                                            className={styles.nav1__links + ' dropdown-toggle' + (showDropdown ? ' show' : '')}
                                            href='' role='button' data-bs-toggle='dropdown' aria-expanded='false'>
                                            {t("WhoWeAre")}
                                        </a>
                                        <ul className={'dropdown-menu shadow p-3 mb-5 bg-body rounded border border-0' + (showDropdown ? ' show' : '')}>
                                            <li><NavLink className={'dropdown-item'} to='/about'>{t("AboutUs")}</NavLink></li>
                                            <li><a className={'dropdown-item'} href={'/#clients'}>{t("CUSTOMERS")}</a></li>
                                            <li><NavLink className={'dropdown-item'} to='/contacts'>{t("Contact")}</NavLink></li>
                                        </ul>
                                    </li>
                                </span>
                                <span className={styles.langSelect} style={{ display: 'flex', alignItems: 'center' }}>
                                    <select
                                        value={language}
                                        onChange={handleSelectChange}
                                        className={`${styles.minWidthSelect} form-select form-select-sm border border-0 ms-3`}
                                        aria-label='Default select example'
                                        id=""
                                    >
                                        <option value="ru">RU</option>
                                        <option value="az">AZ</option>
                                        <option value="en">EN</option>
                                    </select>
                                </span>
                                <li onClick={() => closeMenu(true)} className={`${styles.helpIcon} nav-item ms-3`}>
                                    <NavLink to='/contacts' className={'nav-link'}>
                                        <img src={HelpSVG} alt='' />
                                    </NavLink>
                                </li>
                            </ul>
                            {authorized
                                ?
                                <form onSubmit={handleSubmit(onSubmit)} action="#">
                                    <div className={styles.popUp}>
                                        <div className={styles.popUpWrapper} onClick={(e) => setPopUp(prev => !prev)}>
                                            <div className={styles.popUpHeader}>
                                                <img src={profileIcon} alt="" className={styles.profileIcon} />
                                                <p className={styles.username}>{userName}</p>
                                                <img src={arrow} alt="" className={popUp ? styles.arrowTop : styles.arrow} />
                                            </div>
                                        </div>
                                        <div className={popUp ? styles.popUpContent : styles.popUpContentNone}>
                                            <div className={styles.userProfile} onClick={() => goToCabinet()}>
                                                <p className={styles.profileWord}>{t("Merchant")}</p>
                                            </div>
                                            {
                                                merchantDataLength && auth && (
                                                    <div className={styles.userProfile} onClick={() => goToMerchant(language)}>
                                                        <p className={styles.profileWord}>{t("User")}</p>
                                                    </div>
                                                )
                                            }
                                            <div className={styles.line}></div>
                                            <button className={styles.exit} onClick={async () => { await signOut(); setPopUp(prev => !prev); }}>
                                                <img src={exit} className={styles.exitIcon} alt="exit_icon" />
                                                <p className={styles.exitWord}>{t("EXIT")}</p>
                                            </button>
                                        </div>
                                    </div>
                                    <div className={styles.mobMenuProfile}>
                                        <p className={styles.username}>{userName}</p>
                                        <div className={styles.userProfileMob} onClick={() => goToCabinet()}>
                                            <p className={styles.profileWord}>{t("Merchant")}</p>
                                        </div>
                                        <>
                                            {
                                                merchantDataLength && auth && (
                                                    <div style={{ display: 'flex' }} onClick={() => goToMerchant(language)}>
                                                        <p className={styles.profileWord}>{t("User")}</p>
                                                    </div>
                                                )
                                            }
                                        </>
                                        <button className={styles.exit} onClick={async () => { await signOut(); setPopUp(prev => !prev); }}>
                                            <img src={exit} className={styles.exitIcon} alt="exit_icon" />
                                            <p className={styles.exitWord}>{t("EXIT")}</p>
                                        </button>
                                    </div>
                                </form>
                                :
                                (<div className={`${styles.btnFlexible} button576`}>
                                    <a onClick={() => closeMenu(true)} href='#' className={'button button-c2c'} data-bs-toggle='modal' data-bs-target='#exampleModal'>{t("SignIn_SignUp")}</a>
                                </div>)
                            }
                        </div>
                    </div>
                </nav>
            </div>
            <div>
            </div>
        </header>
    );
}

export default Header;