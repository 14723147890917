import { messageToAdminUrl } from "../http";
import axios from "axios";

export default class MessageToAdminService {
    static async messageToAdmin(messageOptions: any) {
        try {
            return await axios.post(`${messageToAdminUrl}/anon/feedback`, messageOptions)
        }
        catch (err) {
            console.log('err')
        }
    }
}