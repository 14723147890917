import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "../PaymentListPage/index.scss";
import { useEffect, useState } from "react";
import { imageService } from "../../http";
import { PulPalServicesLoadingTypes } from "../../redux/reducers/pulPalServices/types";
import loading from '../../assets/Loading_icon.gif'
import { ServiceLink } from "../PaymentListPage/ServiceLink";

export const FilteredServices = ({ filteredList }) => {
  const { t } = useTranslation();
  const langKey = useSelector((state) => state.languageReducer.langKey);
  const [loadedImages, setLoadedImages] = useState({});
  const [imageError, setImageError] = useState({});
  const [imageCache, setImageCache] = useState({});
  const subjects = useSelector((state) => state.loadingReducer.subjects);

  const defaultImage = 'https://placehold.co/50x50/png';

  useEffect(() => {
    const images = filteredList.map((item) => {
      return new Promise((resolve, reject) => {
        if (imageCache[item.id]) {
          setLoadedImages((prevState) => ({ ...prevState, [item.id]: true }));
          resolve(item.id);
        } else {
          const img = new Image();
          img.src = `${imageService}/${item.id}`;
          img.onload = () => {
            setLoadedImages((prevState) => ({ ...prevState, [item.id]: true }));
            setImageCache((prevState) => ({ ...prevState, [item.id]: img }));
            resolve(item.id);
          };
          img.onerror = () => {
            setImageError((prevState) => ({ ...prevState, [item.id]: true }));
            resolve(item.id);
          };
        }
      });
    });

    Promise.all(images)
      .then(() => { })
      .catch(() => { });
  }, [filteredList, imageCache]);

  return (
    <div
      style={{
        padding: "0",
        margin: "0 auto",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
      }}
      className="paymentListFiltered"
    >
      <div className="paymentListInnerFiltered">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            margin: "0 auto",
          }}
          className="row g-3 listWrap"
        >
          {
            (subjects.has(!PulPalServicesLoadingTypes.GET_SERVICE_LIST_ALL) ?
              <div
                className='loading-gif'
                style={{ width: '1200px', minHeight: '60vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                <img src={loading} alt="loading" />
              </div>
              :
              <>
                {filteredList.length ? (
                  filteredList?.map((item, id) => {
                    return (
                      <div className="col-xxl-4 col-lg-6 card-item-filtered" key={id}>
                        <div className="d-flex align-items-center px-4 py-x1 bg-light rounded-3 border position-relative merchant_card service-card">
                          {!(loadedImages[item.id] || imageError[item.id]) && <div style={{ width: '35px', display: 'flex', alignItems: 'center', margin: '0 7.5px' }}><span style={{ transform: 'scale(0.5)', position: 'absolute', left: '40px' }} className='loader'></span></div>}
                          {loadedImages[item.id] && (
                            <img src={imageCache[item.id]?.src} alt={item?.name} width='50' />
                          )}
                          {imageError[item.id] && (
                            <img src={defaultImage} alt='Default' width='50' />
                          )}
                          <div className="ms-3 my-x1">
                            <h5 className="fs-0 fw-semi-bold mb-2" style={{ display: 'flex', alignItems: 'flex-start' }}>
                              <ServiceLink item={item} langKey={langKey}/>
                            </h5>
                            <h6 className="mb-0 text-600">{`min ${item.minAmount / 100
                              } ${item.currency}, max - ${item.maxAmount / 100} ${item.currency
                              }`}</h6>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) :
                  <div style={{ marginTop: "100px", textAlign: "center" }}>
                    {t("noResultsSearch")}
                  </div>
                }
              </>
            )}
        </div>
      </div>
    </div>
  );
};
