import React from 'react';
import { C2CTextProps } from '.././C2C/C2C.props';
import styles from '../Home.module.scss';

const C2CText: React.FC<C2CTextProps> = ({ iconSrc, desc, num }) => {
    return (
        <div className={`${styles['c2c__text']} d-flex justify-content-between`}>
            <img src={iconSrc.src} alt={iconSrc.alt} />
            <p className={styles['c2c__text-desc']} dangerouslySetInnerHTML={{__html: desc}} />
            {num && <p className={styles['c2c__text-num']}>{num}</p>}
        </div>
    );
}

export default C2CText;