import React, { useState } from 'react';
import { ServiceItemProps } from './Services.props';
import styles from '../Home.module.scss';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAnotherPageAC } from '../../../redux/reducers/helper/helper';
import GoogleSVG from './../../../assets/slider/google.svg';
import AppStore from './../../../assets/apple.svg';
import AppGallerySVG from './../../../assets/slider/appgallery.svg';

const ServiceItem: React.FC<ServiceItemProps> = ({ title, imageSrc, description, path, openRegisterInModal, backSide }) => {
    const dispatch = useDispatch();
    const [isFlipped, setIsFlipped] = useState(false);

    const handleFlip = () => {
        setIsFlipped(!isFlipped);
    }

    return (
        <div
            style={{ textDecoration: 'none' }}
            data-bs-toggle={openRegisterInModal ? 'modal' : ''}
            data-bs-target='#exampleModal'
            className={`${styles.wrapper} col-12 col-sm-6 col-md-4 ${(isFlipped && backSide) ? styles.flipped : ''}`}
            onClick={handleFlip}
        >
            {
                path?.includes('#')
                    ?
                    <a
                        style={{ textDecoration: 'none', position: 'relative' }} href={`${path}`}>
                        <div className={`${styles['services__item']} ${styles.front}`}>
                            <div className={styles['services__item-header']}>
                                <h4 className={styles.word} dangerouslySetInnerHTML={{ __html: title }} />
                                <img src={imageSrc} alt='' className={styles.blockImage} />
                            </div>
                            <p dangerouslySetInnerHTML={{ __html: description }} />
                        </div>
                        <div className={styles.back}>
                        <div className={styles.backInner}>
                                <a onClick={(event) => event.stopPropagation()} className={styles.apps_link} href='https://play.google.com/store/apps/details?id=az.pulpal&hl=ru&gl=US'><img src={GoogleSVG} alt='Google Play' /></a>
                                <a onClick={(event) => event.stopPropagation()} className={styles.apps_link} href='https://apps.apple.com/ru/app/pulpal/id1670623136'><img className={styles.appStore} src={AppStore} alt='Google Play' /></a>
                                <a onClick={(event) => event.stopPropagation()} className={styles.apps_link} href='https://appgallery.huawei.com/app/C105530163'><img src={AppGallerySVG} alt='App Gallery' /></a>
                            </div>
                        </div>
                    </a>
                    :
                    <NavLink
                        style={{ textDecoration: 'none', position: 'relative' }} to={`${path}`}>
                        <div onClick={() => dispatch(setAnotherPageAC(true))} className={`${styles['services__item']} ${styles.front}`}>
                            <div className={styles['services__item-header']}>
                                <h4 className={styles.word} dangerouslySetInnerHTML={{ __html: title }} />
                                <img src={imageSrc} alt='' className={styles.blockImage} />
                            </div>
                            <p dangerouslySetInnerHTML={{ __html: description }} />
                        </div>
                        <div className={styles.back}>
                        <div className={styles.backInner}>
                                <a onClick={(event) => event.stopPropagation()} className={styles.apps_link} href='https://play.google.com/store/apps/details?id=az.pulpal&hl=ru&gl=US'><img src={GoogleSVG} alt='Google Play' /></a>
                                <a onClick={(event) => event.stopPropagation()} className={styles.apps_link} href='https://apps.apple.com/ru/app/pulpal/id1670623136'><img className={styles.appStore} src={AppStore} alt='Google Play' /></a>
                                <a onClick={(event) => event.stopPropagation()} className={styles.apps_link} href='https://appgallery.huawei.com/app/C105530163'><img src={AppGallerySVG} alt='App Gallery' /></a>
                            </div>
                        </div>
                    </NavLink>
            }
        </div>
    );
}

export default ServiceItem;