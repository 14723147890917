import ServicesIcon from './../../../assets/icons/services.svg';
import ServicesS1Png from './../../../assets/services/s1.png';
import ServicesS2Png from './../../../assets/services/s2.png';
import ServicesS3Png from './../../../assets/services/s3.png';
import ServicesS4Png from './../../../assets/services/s4.png';
import ServicesS5Png from './../../../assets/services/s5.png';
import ServicesS6Png from './../../../assets/services/s6.png';
import ServicesS7Png from './../../../assets/services/s7.png';
import ServicesS8Png from './../../../assets/services/s8.png';
import ServicesS9Png from './../../../assets/services/s9.png';

export const ServiceSources = {
    icon: ServicesIcon,
    s1: ServicesS1Png,
    s2: ServicesS2Png,
    s3: ServicesS3Png,
    s4: ServicesS4Png,
    s5: ServicesS5Png,
    s6: ServicesS6Png,
    s7: ServicesS7Png,
    s8: ServicesS8Png,
    s9: ServicesS9Png

}