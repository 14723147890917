import LoadingActionTypes from './types'

export const LoadingActionCreators = {
  loadingStart: subject => ({
    type: LoadingActionTypes.LOADING_START,
    payload: subject,
  }),

  loadingEnd: subject => ({
    type: LoadingActionTypes.LOADING_END,
    payload: subject,
  }),
}
