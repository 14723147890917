import React from 'react';
import { ContactProps, ContactsProps } from './Contacts.props';
import { ContactsResources } from './Contacts.resources';
import styles from './Contacts.module.scss';
import ContactsMap from './ContactsMap';
import { useEffect } from 'react';

const Contacts: React.FC<ContactsProps> = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className={styles.contacts}>
            <ContactsMap />
            <div className={'container'}>
                <div className={'row'}>
                    <Contact
                        url={'https://api.whatsapp.com/send?phone=994776778777'}
                        icon={{ src: ContactsResources.whatsappIcon, alt: 'whatsapp' }}
                        title={'Whatsapp'} desc={'+994 77 677 87 77'}
                    />

                    <Contact
                        url={'https://m.me/Pulpal.az'}
                        icon={{ src: ContactsResources.messengerIcon, alt: 'messenger' }}
                        title={'Messenger'} desc={'Facebook Messenger'}
                    />

                    <Contact
                        url={'mailto:office@pulpal.az'}
                        icon={{ src: ContactsResources.mailIcon, alt: 'Email' }}
                        title={'E-mail'} desc={'office@pulpal.az'}
                    />

                    <Contact
                        url={'tel:+994776778777'}
                        icon={{ src: ContactsResources.phoneIcon, alt: 'phone' }}
                        title={'Telefon'} desc={'+994 77 677 87 77'}
                    />
                </div>
            </div>
        </div>
    );
}

const Contact: React.FC<ContactProps> = ({ icon, title, desc, url }) => {
    return (
        <div className='col-12 col-md-6 col-lg-3'>
            <a href={url}>
                <div className={styles['contacts__card']}>
                    <img src={icon.src} alt={icon.alt} />
                    <div className={styles['contacts__card-title']}>{title}</div>
                    <div className={styles['contacts__card-desc']}>{desc}</div>
                </div>
            </a>
        </div>
    );
}

export default Contacts;