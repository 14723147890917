import React from 'react';
import { ModulesHeaderProps } from './modulesHeader.props';
import styles from '../modules.module.scss';
import { ModulesResources } from '../modules.resources';
import { useTranslation } from 'react-i18next';

const ModulesHeader: React.FC<ModulesHeaderProps> = () => {
    const { t } = useTranslation();
    return (
        <section className={styles.bush}>
            <div className={'container'}>
                <div className={`${styles['bush__inner']} page-slider`}>
                    <div className={'row'}>
                        <div className={`col-12 col-md-6 ${styles['bush__left']}`}>
                            <h1>{t("Ready-made modules for your online store")}</h1>
                            <p>
                                {t("ImproveYourOnline")}
                            </p>
                        </div>
                        <div className={'col-md-6 d-none d-md-block'}>
                            <img className={'float-end img-fluid'} src={ModulesResources.modheader} alt={'Modullar'} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ModulesHeader;