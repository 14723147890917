import React from 'react';
import { LinkButtonProps } from './LinkButton.props';
import { NavLink } from 'react-router-dom';

const LinkButton: React.FC<LinkButtonProps> = ({ url, children }) => {
    return (
        <NavLink to={url}>
            {children}
        </NavLink>
    );
}

export default LinkButton;