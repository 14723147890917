import Home from '../src/assets/logos/01.svg'
import Network from '../src/assets/logos/04.svg'
import Tv  from '../src/assets/logos/05.svg'
import Phone  from '../src/assets/logos/03.svg'
import Other  from '../src/assets/logos/12.svg'
import Banking  from '../src/assets/logos/07.svg'
import Insurance  from '../src/assets/logos/09.svg'
import Education  from '../src/assets/logos/11.svg'
import OnlineStore  from '../src/assets/logos/online-storeIcon.svg'
import Penalty  from '../src/assets/logos/penaltyIcon.svg'
import Government  from '../src/assets/logos/06.svg'
import Transport  from '../src/assets/logos/taxi-front-viewIcon.svg'
import Mobile  from '../src/assets/logos/02.svg'
import Entertainment  from '../src/assets/logos/entertainmentIcon.svg'
import Online  from '../src/assets/logos/08.svg'
import Software  from '../src/assets/logos/softwareIcon.svg'
import Tourism  from '../src/assets/logos/hotel.tourismIcon.svg'
import Charity  from '../src/assets/logos/heartIcon.svg'
import CreditApplication  from '../src/assets/logos/creditIcon.svg'
import CheckIcon  from '../src/assets/check-mark.svg';


export const imagesForCategories = [
    {
        id: '2',
        image: Home,
        name: 'utilities',
    },
    {
        id: '3',
        image: Network,
        name: 'internet',
    },
    {
        id: '4',
        image: Tv ,
        name: 'tv',
    },
    {
        id: '6',
        image: Phone ,
        name: 'phone',
    },
    {
        id: '8',
        image: Other ,
        name: 'other',
    },
    {
        id: '9',
        image: Banking ,
        name: 'banking',
    },
    {
        id: '10',
        image: Insurance ,
        name: 'insurance',
    },
    {
        id: '11',
        image: Education ,
        name: 'education',
    },
    {
        id: '16',
        image: OnlineStore ,
        name: 'online_store',
    },
    {
        id: '12',
        image: Penalty ,
        name: 'penalty',
    },
    {
        id: '15',
        image: Government ,
        name: 'government',
    },
    {
        id: '14',
        image: Transport ,
        name: 'transport',
    },
    {
        id: '1',
        image: Mobile ,
        name: 'mobile',
        color: '#9BDAE4'
    },
    {
        id: '7',
        image: Entertainment ,
        name: 'entertainment',
    },
    {
        id: '5',
        image: Online ,
        name: 'online',
    },
    {
        id: '13',
        image: Software ,
        name: 'software',
    },

    {
        id: '18',
        image: Tourism ,
        name: 'hotels_and_tourism',
    },
    {
        id: '19',
        image: Charity ,
        name: 'charity',
    },
    {
        id: '20',
        image: CreditApplication ,
        name: 'credit_application',
    },
    {
        id: '21',
        image: CheckIcon ,
        name: 'check_icon',
    },
]
