import React, { useEffect, useState } from 'react';
import C2CText from './C2CText';
import { C2CSources } from './C2C.sources';
import styles from '../Home.module.scss';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import C2CService from '../../../services/c2c';
import { useForm } from 'react-hook-form';

type Transfer = {
	amount: string;
	phone: string;
};

const C2C: React.FC = () => {
	const { t } = useTranslation();
	const regex = /^[0-9\b]+$/;

	const [transferOptions, setTransferOptions] = useState({ amount: '', phone: '' });
	const {
		register,
		handleSubmit,
		formState: { errors },
	}: any = useForm<Transfer>({
		mode: "onBlur",
		reValidateMode: "onSubmit"
	});

	const transferAmount = async () => {
		const phoneNumberRegex = /^(50|51|55|70|77|99|10)[2-9][0-9]{6}$/;
		const inputPhoneNumber = transferOptions.phone;
		const isValidPhoneNumber = phoneNumberRegex.test(inputPhoneNumber);
		if (isValidPhoneNumber && +transferOptions.amount > 0 && !errors?.message?.amount && localStorage.getItem('pulpalAuth') && +transferOptions.amount <= 4000) {
			let res: any = await C2CService.amountTransfer(transferOptions);
			if (res?.data.redirectUrl) {
				window.location.href = res.data.redirectUrl;
			}
		}
		else if (isValidPhoneNumber && +transferOptions.amount > 0 && !errors?.message?.amount && !localStorage.getItem('pulpalAuth') && +transferOptions.amount <= 2000) {
			let res: any = await C2CService.amountTransfer(transferOptions);
			if (res?.data.redirectUrl) {
				window.location.href = res.data.redirectUrl;
			}
		}
	};

	const handleChangePhoneNumber = (e: any) => {
		if (e.target.value === "" || regex.test(e.target.value)) {
			setTransferOptions({ ...transferOptions, phone: e.target.value });
		}
	};

	const handleChangeAmount = (e: any) => {
		if (e.target.value === "" || regex.test(e.target.value)) {
			setTransferOptions({ ...transferOptions, amount: e.target.value });
		}
	};

	const onSubmit = (data: any) => {
		console.log(data);
	}

	return (
		<section className={styles.c2c} id='c2c'>
			<div className='container'>
				<div className='section__header'>
					<img src={C2CSources.c2cIcon} alt='c2c' />
					<h2 className='section__title'>{t("Card to card transfer")}</h2>
					<p className='section__header-text'>{t("Instant money transfer")}</p>
				</div>

				<div className={`row ${styles['c2c__inner']}`}>
					<div className='col-12 col-lg-5 col-xl-6 c2c__left'>
						<C2CText iconSrc={{ src: C2CSources.svg01, alt: '' }}
							desc={t('Limit without registration')} num={'2000 AZN'}
						/>

						<C2CText iconSrc={{ src: C2CSources.svg02, alt: '' }}
							desc={t('Limit for users')} num={'4000 AZN'}
						/>

						<C2CText iconSrc={{ src: C2CSources.svg03, alt: '' }}
							desc={t('Term of enrollment')} num={`0 ${t("min")} - 24 ${t("hours")}`}
						/>

						<C2CText iconSrc={{ src: C2CSources.svg04, alt: '' }}
							desc={t('Commission')} num={'1,5%'}
						/>

						<C2CText iconSrc={{ src: C2CSources.svg05, alt: '' }}
							desc={t('There may be additional')}
						/>

						<C2CText iconSrc={{ src: C2CSources.svg05, alt: '' }}
							desc={t('Banks may have')}
						/>
					</div>
					<div className='col-12 col-lg-7 col-xl-6'>
						<form onSubmit={handleSubmit(onSubmit)} action='#'>
							<div className='col-12 col-lg-7 col-xl-6'>
								<div className={styles['c2c__right']}>
									<div className={`${styles['c2c__box']} ${styles['c2c__box-blue']}`}>
										<p className={styles['c2c__box-title']}>{t("Your phone number")}</p>
										<p className={styles['c2c__box-subtitle']}>{t("Please enter your mobile phone number")}</p>

										<div className={`${styles['c2c__box-phone']} d-flex justify-content-start`}>
											<p>+994</p>
											<input value={transferOptions.phone} {...register('phone', { required: t("RequiredField"), pattern: { value: /^(50|51|55|70|77|99|10)[2-9][0-9]{6}$/, message: t("IncorrectPhoneNumber") } })}
												onChange={(e) => handleChangePhoneNumber(e)} className={`${styles['c2c__input']} ${styles['c2c__input-blue']}`}
												maxLength={9} placeholder='00 000 00 00' name='phone' type='tel' inputMode='numeric'
											/>
										</div>
										<div className='mt-3' style={{ color: "#fff" }}>
											{errors?.phone && <p>{errors?.phone?.message || t("RequiredField")}</p>}
										</div>

									</div>

									<div className={`${styles['c2c__box']} ${styles['c2c__box-orange']} d-flex justify-content-between align-items-end`}>

										<div>
											{/* {!errors?.amount && (
												<>
													<p className={styles['c2c__box-title']}>{t("Amount")}</p>
													<p className={styles['c2c__box-subtitle']}>{t("Enter the amount to be transferred")}</p>
												</>
											)} */}
											{errors?.amount && <p style={{ color: "#fff" }}>{errors?.amount?.message}</p>}
										</div>
										{localStorage.getItem('pulpalAuth')
											?
											(<div className={`${styles['c2c__box-phone']} d-flex justify-content-start bgc-red`}>
												<input value={transferOptions.amount} {...register('amount',
													{
														required: t("RequiredField"),
														minLength: { value: 1, message: 'Minimum 1 symbol' },
														// pattern: {
														// 	value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, message: '12313'
														// },
														min: {
															value: 1,
															message: t("MinAmount") + ' 0'
														},
														max: {
															value: 4000,
															message: t("MaxAmount") + ' 4000'
														},

													})} onChange={(e) => handleChangeAmount(e)} className={`${styles['c2c__input']} ${styles['c2c__input-orange']}`}
													placeholder='0'
													// name='sum'
													inputMode='numeric' />
												<p>₼</p>
											</div>)
											:
											(<div className={`${styles['c2c__box-phone']} d-flex justify-content-start bgc-red`}>
												<input value={transferOptions.amount} {...register('amount',
													{
														required: t("RequiredField"),
														minLength: { value: 1, message: t("MinOneSymbol") },
														min: {
															value: 1,
															message: t("MinAmount") + ' 0'
														},
														max: {
															value: 2000,
															message: t("MaxAmount") + ' 2000'
														},

													})} onChange={(e) => handleChangeAmount(e)} className={`${styles['c2c__input']} ${styles['c2c__input-orange']}`}
													placeholder='0'
													// name='sum'
													inputMode='numeric' />
												<p>₼</p>
											</div>)
										}
									</div>
								</div>
								<div className={styles['c2c__submit']}>
									{localStorage.getItem('language') === 'az' ?
										<p className={styles['c2c__agreement']}>{t("By pressing the TRANSFER button")}<br />
											<NavLink to='/agreement-policy' className={styles['footer__links']}>{t("User rules")}</NavLink>
											{t("You agree to the")}</p>
										:
										<p className={styles['c2c__agreement']}>{t("By pressing the TRANSFER button")}<br />
											{t("You agree to the")} <NavLink to='/agreement-policy' className={styles['footer__links']}>{t("User rules")}</NavLink>
										</p>
									}
									<input type='submit' onClick={transferAmount} className='button button-c2c' value={`${t("TRANSFER")}`}></input>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</section>
	);
}

export default C2C;