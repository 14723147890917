import "./PulPalLoader.scss"
import PulPalLoaderGif from '../../assets/pulpal.gif'

const PulPalLoader = () => (
    <div className='pulpal-loader'>
        <img style={{height: "15%"}} src={PulPalLoaderGif} alt='' />
    </div>
)

export default PulPalLoader
