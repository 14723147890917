import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import languageReducer from './reducers/language/languageReducer';
import helperReducer from './reducers/helper/helper';
import pulPalServicesReducer from './reducers/pulPalServices';
import loadingReducer from './reducers/loading';

const reducers = {
    languageReducer,
    helperReducer,
    pulPalServicesReducer,
    loadingReducer
};

const rootReducer = combineReducers(reducers);

const store = createStore(rootReducer, compose(applyMiddleware(thunk)));

export default store;
