import { cabinetUrl, merchantUrl } from './../http';
import AuthService from '../services/auth';

export const goToCabinet = () => {
    const currentHref = window.location.href;
    const encodedHref = btoa(currentHref);
    const encodeAuthData = btoa(JSON.stringify(AuthService.getAuthData()));
    const path = localStorage.getItem('redirectToService');
    window.location.href = (path ? cabinetUrl + path : cabinetUrl)!
        + `?redirect=${encodedHref}`
        + '&data=' + encodeAuthData;
}

export const goToMerchant = (lang: any) => {
    const currentHref = window.location.href;
    const encodedHref = btoa(currentHref);
    const encodeAuthData = btoa(JSON.stringify(AuthService.getAuthData()));
    window.location.href = merchantUrl! + "/merchants" + `/${lang}`
        + `?redirect=${encodedHref}`
        + '&data=' + encodeAuthData;
}
