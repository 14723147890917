import {t} from 'i18next'

export const PROVIDER_TYPE = [
  {
    value: '0',
    label: 'cash',
  },
  {
    value: '1',
    label: 'card',
  },
  {
    value: '2',
    label: 'taksit',
  },
  {
    value: '6',
    label: 'wallet',
  },
  {
    value: '7',
    label: 'QrAnyPay',
  },
];

export const TRANSACTION_NAMES = {
  '-100': 'walletReplenishment',
  '-102': 'cardTopUp',
  '-101': 'paymentServices',
  '-103': 'walletReplenishment'
}

export const TRANSACTION_TYPE = [
  {
    value: '-101',
    label: 'paymentServices',
  },
  {
    value: '-102',
    label: 'transferWalletCard',
  },
  {
    value: '-100',
    label: 'walletReplenishment',
  },
  {
    value: '-103 ',
    label: 'transferToWallet',
  },
];

export const CURRENCY_TYPE = [
  {
    value: 'AZN',
    label: 'AZN',
  },
];

export const AZERCELL_SERVICE_ID = 6;
export const BACKCELL_SERVICE_ID = 145;
export const NARMOBILE_SERVICE_ID = 146;

const phoneIds = [AZERCELL_SERVICE_ID, BACKCELL_SERVICE_ID, NARMOBILE_SERVICE_ID];

export function isPhoneServices(id){
  return phoneIds.includes(id) ? true : false
}

export const REACT_DATEPICKER_TRANSLATE = {
  ru: {
    days: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
    months: [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь',
    ],
  },
  en: {
    days: ['Mo', 'To', 'We', 'Th', 'Fr', 'Sa', 'Su'],
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
  },
  az: {
    days: ['Be', 'Ça', 'Ç', 'Ca', 'C', 'Ş', 'B'],
    months: [
      'Yanvar',
      'Fevral',
      'Mart',
      'Aprel',
      'May',
      'Iyun',
      'Iyul',
      'Avqust',
      'Sentyabr',
      'Oktyabr',
      'Noyabr',
      'Dekabr',
    ],
  },
};
