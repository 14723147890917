import React from 'react';
import { SliderItemProps } from './slider.props';
import styles from './../Home.module.scss';

const SliderItem: React.FC<SliderItemProps> = ({image, alt, title, text, children, isActive}) => {
    return (
        <div className={'carousel-item' + (isActive ? ' active' : '')}>
            <div className={'row'}>
                <div className={'col-10 offset-1 col-md-7 offset-md-0 col-lg-5 offset-lg-1'}>
                    <div className={styles.slider__left}>
                        <h1 className={styles.slider__title} dangerouslySetInnerHTML={{__html: title || ''}} />
                        <p className={styles.slider__text} dangerouslySetInnerHTML={{__html: text || ''}} />
                        <div className={styles.slider__apps}>
                            {children}
                        </div>
                    </div>
                </div>
                    
                <div className={'col-md-5 col-lg-5 d-none d-md-block'}>
                    <div className={styles.slider__right + ' text-center'}>
                        <img src={image}
                            alt={alt} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SliderItem;