import React from 'react';
import { BusinessPaymentProps } from './BusinessPayment.props';
import styles from '../Business.module.scss';
import BusinessPaymentCard from './BusinessPaymentCard';
import { BusinessResources } from '../Business.resources';
import { useTranslation } from 'react-i18next';

const BusinessPayment: React.FC<BusinessPaymentProps> = () => {
    const { t } = useTranslation();
    return (
        <section className={styles.odeqeb}>
            <div className={'container'}>
                <div className={styles['odeqeb__inner']}>
                    <div className={'row'}>
                        <div className={`col-12 col-lg-8 col-xl-6 ${styles['odeqeb__left']}`}>
                            <h2 className={'alttitle'}>
                                <img src={BusinessResources.sSvg} alt='' />{t("Payment of fees")}
                            </h2>
                            <div className={'d-flex justify-content-start mt-4'}>
                                <div className={'px-3'}>
                                    <p className={styles['odeqeb__list']}>{t("Installment cards")}</p>
                                    <p className={styles['odeqeb__list']}>Epul.az</p>
                                    <p className={styles['odeqeb__list']}>Ani Pay</p>
                                    <p className={styles['odeqeb__list']}>MilliÖn</p>
                                    <p className={styles['odeqeb__list']}>Emanat</p>
                                    <p className={styles['odeqeb__list']}>Kassam.az</p>
                                    <p className={styles['odeqeb__list']}>{t("All types of plastic cards")}</p>
                                </div>
                                <div className={'px-3'}>
                                    <p className={styles['odeqeb__list']}>{t("QR Code payment")}</p>
                                    <p className={styles['odeqeb__list']}>{t("Foreign cards")}</p>
                                    <p className={styles['odeqeb__list']}>{t("Refund")}</p>
                                    <p className={styles['odeqeb__list']}>{t("Transfer of payments")}</p>
                                    <p className={styles['odeqeb__list']}>{t("Payment via link")}</p>
                                </div>
                            </div>
                        </div>
                        <div className={'col-lg-4 col-xl-6 d-none d-lg-block'}>
                            <img className={`float-end ${styles['odeqeb__img']}`}
                                src={BusinessResources.odeqeb}
                                alt={'Ödənişlərin qəbulu'} />
                        </div>
                    </div>

                    <div className={styles['odeqeb__cards']}>
                        <BusinessPaymentCard image={{ src: BusinessResources.cards, alt: '' }}
                            title={t("Installment and other cards")}
                            desc={`${t("The contract with the PulPal")}`}
                        />

                        <BusinessPaymentCard image={{ src: BusinessResources.terminals, alt: '' }}
                            title={t("Through terminals")}
                            desc={`${t("We are used to meeting")}`}
                        />

                        <BusinessPaymentCard image={{ src: BusinessResources.onlayn, alt: '' }}
                            title={t("Online ones")}
                            desc={`${t("PulpalIsConvenient")}`}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default BusinessPayment;