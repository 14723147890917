import Footer from "../Footer/Footer"
import SignUp from "../SignUp/SignUp"
import { useAppSelector } from "../hooks/redux"
import C2C from "./C2C/C2C"
import Clients from "./Clients/Clients"
import Developers from "./Developers/Developers"
import Services from "./Services/Services"
import Slider from "./Slider/Slider"

export const HomePage: React.FC<any> = () => {
    const authorized: boolean = useAppSelector((state: any) => state.helperReducer.authorized);
    return (
        <div>
            <Slider />
            <Services />
            <C2C />
            <Developers />
            <Clients />
            <SignUp />
        </div>
    )
}