import bizcab from './../../assets/bizcab/bizcab.png';
import bizcab2x from './../../assets/bizcab/bizcab2x.png';
import mIcon from './../../assets/icons/m.svg';
import svg01 from './../../assets/bizcab/01.svg';
import svg02 from './../../assets/bizcab/02.svg';
import svg03 from './../../assets/bizcab/03.svg';
import svg04 from './../../assets/bizcab/04.svg';
import svg05 from './../../assets/bizcab/05.svg';
import svg06 from './../../assets/bizcab/06.svg';
import svg07 from './../../assets/bizcab/07.svg';
import svg08 from './../../assets/bizcab/08.svg';
import sSvg from './../../assets/icons/s.svg';
import odeqeb from './../../assets/odeqeb.png';
import cards from './../../assets/cards.png';
import terminals from './../../assets/terminals.png';
import onlayn from './../../assets/onlayn.png';
import sui from './../../assets/sui.png';
import gSvg from './../../assets/icons/g.svg';
import sui01 from './../../assets/sui/01.svg';
import sui02 from './../../assets/sui/02.svg';
import sui03 from './../../assets/sui/03.svg';
import sui04 from './../../assets/sui/04.svg';
import sui05 from './../../assets/sui/05.svg';
import sui06 from './../../assets/sui/06.svg';
import apiIcon from './../../assets/icons/api.svg';
import moduleIcon from './../../assets/icons/module.svg';
import googleSvg from './../../assets/slider/google.svg';
import appgallerySvg from './../../assets/slider/appgallery.svg';
import fmt from './../../assets/fmt.png';


export const BusinessResources = {
    bizcab,
    bizcab2x,
    mIcon,
    svg01,
    svg02,
    svg03,
    svg04,
    svg05,
    svg06,
    svg07,
    svg08,
    sSvg,
    odeqeb,
    cards,
    terminals,
    onlayn,
    sui,
    gSvg,
    sui01,
    sui02,
    sui03,
    sui04,
    sui05,
    sui06,
    apiIcon,
    moduleIcon,
    googleSvg,
    appgallerySvg,
    fmt
}