import axios from 'axios'

import { pulpalServices } from '../http'

export default class PulPalServices {
  static async getCategoriesList() {
    return axios.get(`${pulpalServices}/categories`)
  }
  static async getServicesListByCategory(id) {
    return axios.get(`${pulpalServices}/services-by-category?categoryId=${id}`)
  }
  static async getServiceById(id) {
    return axios.get(`${pulpalServices}/services/${id}`)
  }

  static async getServiceList() {
    return axios.get(`${pulpalServices}/services-simple`)
  }

  static async getServiceListAll() {
    return axios.get(`${pulpalServices}/services`)
  }
}
