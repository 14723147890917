import React from 'react'
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { withTranslation } from "react-i18next"
import { t } from 'i18next'
import withRouter from '../../HOC/widthRouter'
import { addZero } from '../../utils/functions'
import {
  AZERCELL_SERVICE_ID,
  BACKCELL_SERVICE_ID,
  isPhoneServices,
  NARMOBILE_SERVICE_ID,
} from '../../utils/consts'
import Select from "react-select";

export const verdictFields = new Set()
verdictFields.add('dlNumber')
verdictFields.add('dlSeries')

class PaymentFields extends React.Component {
  constructor(props) {
    super(props)

    const groupId = props.service.fieldGroups[0]?.id
    const fields = this.createFields(groupId)
    const emptyQr = fields.filter(f => !f.value && f.qrfield)
    const emptyBar = fields.filter(f => !f.value && f.barfield)

    this.state = {
      groupId,
      fields: fields,
      qrModal: emptyQr.length === 1 ? emptyQr[0].id : undefined,
      barModal: emptyBar.length === 1 ? emptyBar[0].id : undefined,
      phoneNumber: '',
      phoneNumberValid: false,
      isModalVisible: false,
      startDate: null,
      langKey: 'valueRu',
    }

    props.onValueChange(this.state)
  }

  saveValue(id, value, commit) {
    const fields = this.state.fields.map(field =>
      field.id === id ? { ...field, value: value } : field,
    )

    this.setState({ fields })
    this.props.onValueChange({ fields, groupId: this.state.groupId }, commit)
  }

  phoneNumberSaveValue(id, value, commit) {
    let prefix = ''
    let number = ''
    this.setState({ phoneNumber: value })
    prefix = value.substring(0, 2)
    number = value.substring(2)
    let fields = [...this.state.fields]
    fields[0] = { ...fields[0], value: prefix }
    fields[1] = { ...fields[1], value: number }
    this.setState({ fields })
    this.props.onValueChange({ fields, groupId: this.state.groupId }, commit)
  }
  
  returnValue(id) {
    const field = this.state.fields.find(field => field.id === id)
    return field?.value || ''
  }

  renderPicker(id, localName, pickerItems, example) {
    const { isClientExist } = this.props
    const value = this.returnValue(id)
    
    return (
      <div className='service_fields-item mb-3 render-picker'>
        {/* <div className='service_fields-item-title form-label'>{localName}</div> */}
        <Select
          classNamePrefix='prefix-input form-react-select'
          options={pickerItems}
          value={pickerItems.find(option => option.value === value)}
          onChange={selectedOption => {
            this.saveValue(id, selectedOption.value);
          }}
          isDisabled={isClientExist}
          placeholder={localName}
        />
      </div>
    )
  }

  renderPhoneNumber() {
    const {
      service: { fields },
    } = this.props

    return (
      <div className='service_fields-item mb-3'>
        {fields
          .filter(v => v.fieldType !== 'hidden')
          .map(item => {
            if (item.fieldType === 'number') {
              return (
                <div key={item.id}>
                  {this.renderTextFieldForPhone(
                    item.id.toString(),
                    item.fieldType,
                    // item.localeName['valueAz'],
                    item.localeName[this.props.language.langKey],
                    item?.placeholder,
                    item.transformRegex,
                  )}
                </div>
              )
            }
            return null
          })}
      </div>
    )
  }

  arrayForPicker(pickerItems) {
    let fields = []
    for (const i in pickerItems) {
      const item = pickerItems[i]
      let field = { label: '', key: '', value: '' }
      // field.label = item.localeName['valueAz']
      field.label = item.localeName[this.props.language.langKey]
      field.key = item.value
      field.value = item.value
      fields.push(field)
    }
    return fields
  }

  createGroupPickerArray() {
    const { service } = this.props
    let fields = []
    for (const group of service.fieldGroups) {
      let field = { label: '', key: 0, value: 0 }
      // field.label = group.localeName['valueAz']
      field.label = group.localeName[this.props.language.langKey]
      field.key = group.id
      field.value = group.id
      fields.push(field)
    }
    return fields
  }

  onPickerGroupValueChange(value) {
    const state = {
      groupId: value,
      fields: this.createFields(value),
    }
    this.setState(state)
    this.props.onValueChange(state)
    return state.fields
  }

  createFields(groupId) {
    const {
      service: { fields: serviceField, fieldGroups: groups },
    } = this.props

    let resultFields = []

    if (groups.length >= 2) {
      const groupFields = serviceField.filter(
        field => field.groupId === groupId,
      )
      if (groupFields) {
        for (const i in groupFields) {
          const item = groupFields[i]
          let field = {
            id: item.id.toString(),
            name: item.name,
            value: item.defaultValue,
          }
          resultFields.push(field)
        }
      }
    } else {
      for (const item of serviceField) {
        let field = {
          id: item.id.toString(),
          name: item.name,
          value: item.defaultValue,
        }
        resultFields.push(field)
      }
    }

    return resultFields
  }

  renderPickerForGroup() {
    const { isClientExist } = this.props
    const items = this.createGroupPickerArray()
    if (items.length === 1) {
      return
    }

    return (
      <div className='service_fields-item mb-3'>
        <select
          className='form-select'
          onChange={e => {
            this.onPickerGroupValueChange(+e.target.value)
          }}
          value={this.state.groupId}
          disabled={isClientExist}
        >
          {items.map(item => {
            return (
              <option key={item.key} value={item.value}>
                {item.label}
              </option>
            )
          })}
        </select>
      </div>
    )
  }

  renderFields(groups, fields) {
    if (!fields) {
      return undefined
    }

    return (
      <>
        {groups && this.renderPickerForGroup()}
        {fields
          .filter(
            v =>
              v.fieldType !== 'hidden' &&
              (!verdictFields.has(v.name) || this.props.showVerdictFields),
          )
          .map((item, index) => {
            if (item.fieldType === 'select') {
              return (
                <div className='service_fields-item' key={item.id}>
                  {this.renderPicker(
                    item.id.toString(),
                    // item.localeName['valueAz'],
                    item.localeName[this.props.language.langKey],
                    this.arrayForPicker(item.enums),
                    item?.placeholder,
                    item.transformRegex,
                  )}
                </div>
              )
            }
            if (item.fieldType === 'date') {
              return (
                <div className='service_fields-item' key={item.id}>
                  {this.renderDateField(
                    item.id.toString(),
                    item.fieldType,
                    // item.localeName['valueAz'],
                    item.localeName[this.props.language.langKey],
                    item?.placeholder,
                    item.transformRegex,
                  )}
                </div>
              )
            }
            return (
              <div className='service_fields-item render-textfield' key={item.id}>
                {this.renderTextField(
                  item.id.toString(),
                  item.fieldType,
                  // item.localeName['valueAz'],
                  item.localeName[this.props.language.langKey],
                  item?.placeholder,
                  item.transformRegex,
                )}
              </div>
            )
          })}
      </>
    )
  }

  renderTextField(id, kind, localName, example, regExp) {
    const { isClientExist } = this.props

    const value = this.returnValue(id)

    let inputErrorClassNames = ''

    if (!new RegExp(regExp).test(value) && value?.length > 0) {
      inputErrorClassNames += 'input-error'
    }

    return (
      <div className='service_fields-item mb-3'>
        <label
          className={
            inputErrorClassNames
              ? 'service_fields-item-title service_fields-item-title--error'
              : 'service_fields-item-title'
          }
        >
          {localName}
        </label>
        <input
          className={`form-control ${inputErrorClassNames}`}
          style={this.props?.azercell ? { paddingLeft: '125px'} : {}}
          onChange={e => {
            if (!isClientExist) {
              this.saveValue(id, e.target.value)
            }
          }}
          placeholder={example}
          value={this.returnValue(id)}
          readOnly={isClientExist}
        />
        {inputErrorClassNames && (
          <div
            style={{ color: 'red', textAlign: 'center' }}
            className='service_fields-item-error'
          >
            {t("dataIsNotCorrect")}
          </div>
        )}
      </div>
    )
  }

  renderDateField(id, kind, localName, example) {
    const { isClientExist } = this.props
    const { startDate } = this.state

    return (
      <div className='service_fields-item mb-3'>
        <label className='service_fields-item-title'>{localName}</label>
        <DatePicker
          className='service_fields-item--date form-control '
          disabled={isClientExist}
          dateFormat='dd.MM.yyyy'
          selected={startDate}
          showYearDropdown
          yearDropdownItemNumber={50}
          scrollableYearDropdown
          onChange={date => {
            if (!isClientExist) {
              const customDate = date
                ? `${addZero(date.getDate())}.${addZero(
                  date.getMonth() + 1,
                )}.${addZero(date.getFullYear())}`
                : null
              this.setState({ startDate: date })
              this.saveValue(id, customDate)
            }
          }}
        />
      </div>
    )
  }

  renderTextFieldForPhone(id, kind, localName, example, regExp) {
    const { isClientExist, azercell } = this.props

    let value = ''
    if (azercell) {
      value = this.state.phoneNumber
    } else {
      value = this.returnValue(id) || ''
      if (value?.length > 9) {
        value = value?.substring(value?.length - 9)
      }
    }

    let inputErrorClassNames = ''

    if (
      azercell &&
      (!new RegExp('^(50|51|10|70|77|55|99)').test(value?.substring(0, 2)) ||
        !new RegExp(regExp).test(value?.substring(2))) &&
      value?.length > 0
    ) {
      inputErrorClassNames += 'input-error'
    }

    if (!azercell) {
      if (!new RegExp(regExp).test(value) && value?.length > 0) {
        inputErrorClassNames += 'input-error'
      }
    }

    return (
      <>
        <div className='service_fields-item mb-3'>
          <label
            className={
              inputErrorClassNames
                ? 'service_fields-item-title service_fields-item-title--error'
                : 'service_fields-item-title'
            }
          >
            {localName}
          </label>
          <div className='service_phone-field'>
            <input
              style={{ marginTop: '10px' }}
              className={`form-control ${inputErrorClassNames}`}
              placeholder={example}
              onChange={e => {
                azercell
                  ? this.phoneNumberSaveValue(+id, e.target.value)
                  : this.saveValue(id, e.target.value)
              }}
              readOnly={isClientExist}
              maxLength={9}
              value={value}
            />
          </div>
          {inputErrorClassNames && (
            <div
              style={{ color: 'red', textAlign: 'center' }}
              className='service_fields-item-error'
            >
              {t("dataIsNotCorrect")}
            </div>
          )}
        </div>
      </>
    )
  }

  render() {
    const {
      service,
      service: { fields: serviceField },
    } = this.props

    const serviceDescription =
      `<div class="service_description-field-title">${'information'}!</div>` +
      // service?.localDesc?.['valueAz']
      service?.localDesc?.[this.props.language.langKey]

    return (
      <div className={`service_fields ${isPhoneServices(service?.id) ? 'phone-fields' : ""}`}>
        {/*{service?.localDesc?.['valueAz'] && (*/}
        {service?.localDesc?.[this.props.language.langKey] && (
          <div
            className='service_fields-item service_description-field'
            dangerouslySetInnerHTML={{
              __html: serviceDescription,
            }}
          ></div>
        )}
        { service.fieldGroups.length >= 2
            ? this.renderFields(
              true,
              service.fields.filter(
                field => field.groupId === this.state.groupId,
              ),
            )
            : this.renderFields(false, serviceField)}
      </div>
    )
  }
}

// const mapStateToProps = state => {
//   const { language } = state
//   return {
//     language,
//   }
// }

const mapStateToProps = (state) => {
  return {
    language: state.languageReducer,
  }
}

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withTranslation()(PaymentFields)))
