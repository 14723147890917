import axios from "axios";
import { localApiAuthUrl, restorePassword } from "../http";
import AuthService from "./auth";
import { log } from "console";

interface IregOptions {
    firstName: string,
    lastName: string,
    email: string | null,
    password: string,
    phone: string,
    language: any,
    timezone: string
};

interface IverifyOptions {
    phoneOrEmail: string,
    subject: number,
    operation: any
};

interface IrestoreOptions {
    phone: string
}

const user = localApiAuthUrl + "/auth";
const userSignUpIndividual = user + "/signUp";
const userSignInIndividual = user + "/signIn";
const resendVerification = user + '/resendVerification';
const verifyByPhone = user + '/verifyByPhone';
const signOut = user + "/signOut";
const changePassword = user + "/changePassword";

export default class SignUpIndividualService {
    static async signUpIndividual(regOptions: IregOptions) {
        try {
            return await axios.post(`${userSignUpIndividual}`, regOptions);
        } catch (err) {
            console.log('ERROR SIGNUP Individual')
        }
    };

    static async resendVerification(verifyOptions: any) {
        try {
            return await axios.post(`${resendVerification}` + `?phoneOrEmail=${verifyOptions.phoneOrEmail}&subject=${0}`, {})
        }
        catch (err) {
            console.log('ERROR RESEND VERIFICATION');
        }
    }

    static async resendVerificationWithEmail(verifyOptions: any) {
        try {
            return await axios.post(`${resendVerification}` + `?phoneOrEmail=${verifyOptions.phoneOrEmail}&subject=${1}`, {})
        }
        catch (err) {
            console.log('ERROR RESEND VERIFICATION');
        }
    }


    static async verifyByPhone(verifyOptions: any) {
        try {
            return await axios.post(`${verifyByPhone}?code=${verifyOptions.verifyCode}&phone=${verifyOptions.phone}`, {});
            // console.log('data try', data)
            // return data;
        }
        catch (err: any) {
            console.log('data err', err)
            return err.response;
            // console.log('err verify by phone', err)
            // console.log('ERROR VERIFY BY PHONE');
        }
    }

    static async signInIndividual(signInOptions: any) {
        try {
            return await axios.post(`${userSignInIndividual}`, signInOptions)
        }
        catch (err) {

        }
    }

    static async signInIndividualWithEmail(signInOptions: any) {
        try {
            return await axios.post(`${userSignInIndividual}`, { email: signInOptions.phoneOrEmail, password: signInOptions.password })
        }
        catch (err: any) {
            return err.response;
        }
    }

    static async signInIndividualWithPhone(signInOptions: any) {
        try {
            const dat = await axios.post(`${userSignInIndividual}`, { phone: signInOptions.phoneOrEmail, password: signInOptions.password });
            console.log('dat', dat)
            return await axios.post(`${userSignInIndividual}`, { phone: signInOptions.phoneOrEmail, password: signInOptions.password })
        }
        catch (err: any) {
            console.log('err', err)
            return err.response;
        }
    }

    // static async signInIndividualWithPhone(signInOptions: any) {
    //     const requestOptions = {
    //         method: 'POST',
    //         headers: { 'Content-Type': 'application/json' },
    //         body: JSON.stringify({ phone: signInOptions.phoneOrEmail, password: signInOptions.password })
    //     };

    //     const data = await fetch(userSignInIndividual, requestOptions)
    //         .then(response => response.json())
    //         .catch(error => console.log(error));
    //         console.log('data', data);
    //         return data;
    // }

    // static async signInIndividualWithPhone(signInOptions: any) { 
    //     try { 
    //       const response = await axios.post(`${userSignInIndividual}`, { phone: signInOptions.phoneOrEmail, password: signInOptions.password }, { 
    //         responseType: 'text' 
    //       }) 
    //       console.log('response', response) 
    //       return Promise.resolve(response) 
    //     } 
    //     catch (err) { 
    //       return Promise.reject(err) 
    //     } 
    //   }




    static async restorePassword(phoneNumber: any) {
        console.log('restore phone', phoneNumber)
        try {
            return await axios.post(`${restorePassword}`, { phone: phoneNumber });
        }
        catch (err) {
            console.log('ERROR SIGN IN')
        }
    }

    static async restorePasswordByEmail(email: any) {
        console.log('restore email', email)
        try {
            return await axios.post(`${restorePassword}`, { email: email });
        }
        catch (err) {
            console.log('ERROR SIGN IN')
        }
    }


    static async restorePasswordChange(newPasswordOptions: any) {
        try {
            console.log('newPasswordOptions', newPasswordOptions);
            return await axios.post(`${user}/restoreChangePassword?operation=${newPasswordOptions.operation}`, { phone: newPasswordOptions.phone, password: newPasswordOptions.password })
        }
        catch (err) {
            console.log('ERROR RESTORE PASSWORD CHANGE')
        }
    }

    static async restorePasswordChangeByEmail(newPasswordOptions: any) {
        try {
            console.log('newPasswordOptions', newPasswordOptions);
            return await axios.post(`${user}/restoreChangePassword?operation=${newPasswordOptions.operation}`, { email: newPasswordOptions.email, password: newPasswordOptions.password })
        }
        catch (err) {
            console.log('ERROR RESTORE PASSWORD CHANGE')
        }
    }

    static async signOut() {
        try {
            await axios.post(`${signOut}`, {})
            AuthService.removeAuthData();
            localStorage.removeItem('phone');
        }
        catch (err) {
            console.log('err')
        }
    }

    static async changePassword(passwordOptions: any) {
        try {
            return await axios.post(`${changePassword}`, passwordOptions)
        }
        catch (err) {
            console.log('ERROR CHANGE PASSWORD')
        }
    }

    static async getSelf() {
        try {
            return await axios.post(`${user}/self`, {});
            // console.log('SELF!!!!!', data )
        }
        catch (err) {
            console.log(err);
        }
    }
} 