import PulPalServices from '../../../services/pulPalServices'
import { LoadingActionCreators } from '../loading/action-creators'
import PulPalServicesActionTypes, { PulPalServicesLoadingTypes } from './types'

export const PulPalServicesActionCreators = {
  setCategories: categories => ({
    type: PulPalServicesActionTypes.SET_CATEGORIES,
    payload: categories,
  }),

  setServices: services => ({
    type: PulPalServicesActionTypes.SET_SERVICES,
    payload: services,
  }),

  setSelectedServices: selectedService => ({
    type: PulPalServicesActionTypes.SET_SELECTED_SERVICES,
    payload: selectedService,
  }),

  setServicesList: serviceList => ({
    type: PulPalServicesActionTypes.SET_SERVICE_LIST,
    payload: serviceList,
  }),

  setServicesListAll: serviceListAll => ({
    type: PulPalServicesActionTypes.SET_SERVICE_LIST_ALL,
    payload: serviceListAll,
  }),

  setError: payload => ({
    type: PulPalServicesActionTypes.SET_ERROR,
    payload,
  }),

  getCategoriesList: () => async dispatch => {
    try {
      dispatch(
        LoadingActionCreators.loadingStart(
          PulPalServicesLoadingTypes.GET_CATEGORIES,
        ),
      )

      dispatch(PulPalServicesActionCreators.setCategories([]))

      const { data: categories } = await PulPalServices.getCategoriesList()

      if (!categories) throw new Error()

      dispatch(PulPalServicesActionCreators.setCategories(categories))
      dispatch(PulPalServicesActionCreators.setError(''))
    } catch (e) {
      dispatch(PulPalServicesActionCreators.setError('Error'))
    } finally {
      dispatch(
        LoadingActionCreators.loadingEnd(
          PulPalServicesLoadingTypes.GET_CATEGORIES,
        ),
      )
    }
  },

  getServicesListByCategory: categoryId => async dispatch => {
    try {
      dispatch(
        LoadingActionCreators.loadingStart(
          PulPalServicesLoadingTypes.GET_SERVICES,
        ),
      )

      dispatch(PulPalServicesActionCreators.setServices([]))

      const { data: services } = await PulPalServices.getServicesListByCategory(
        categoryId,
      )

      dispatch(PulPalServicesActionCreators.setServices(services))
      dispatch(PulPalServicesActionCreators.setError(''))
    } catch (e) {
      dispatch(PulPalServicesActionCreators.setError('Error'))
    } finally {
      dispatch(
        LoadingActionCreators.loadingEnd(
          PulPalServicesLoadingTypes.GET_SERVICES,
        ),
      )
    }
  },

  getServiceById: serviceId => async dispatch => {
    try {
      dispatch(
        LoadingActionCreators.loadingStart(
          PulPalServicesLoadingTypes.GET_SELECTED_SERVICES,
        ),
      )

      dispatch(PulPalServicesActionCreators.setSelectedServices({}))

      const { data: service } = await PulPalServices.getServiceById(serviceId)

      dispatch(PulPalServicesActionCreators.setSelectedServices(service))
      dispatch(PulPalServicesActionCreators.setError(''))
    } catch (e) {
      dispatch(PulPalServicesActionCreators.setError('Error'))
    } finally {
      dispatch(
        LoadingActionCreators.loadingEnd(
          PulPalServicesLoadingTypes.GET_SELECTED_SERVICES,
        ),
      )
    }
  },

  getServiceList: () => async dispatch => {
    try {
      dispatch(
        LoadingActionCreators.loadingStart(
          PulPalServicesLoadingTypes.GET_SERVICE_LIST,
        ),
      )

      dispatch(PulPalServicesActionCreators.setServicesList([]))

      const { data: services } = await PulPalServices.getServiceList()

      dispatch(PulPalServicesActionCreators.setServicesList(services))

      dispatch(PulPalServicesActionCreators.setError(''))
    } catch (e) {
      dispatch(PulPalServicesActionCreators.setError('Error'))
    } finally {
      dispatch(
        LoadingActionCreators.loadingEnd(
          PulPalServicesLoadingTypes.GET_SERVICE_LIST,
        ),
      )
    }
  },

  getServiceListAll: () => async dispatch => {
    try {
      dispatch(
        LoadingActionCreators.loadingStart(
          PulPalServicesLoadingTypes.GET_SERVICE_LIST_ALL,
        ),
      )

      dispatch(PulPalServicesActionCreators.setServicesListAll([]))

      const { data: services } = await PulPalServices.getServiceListAll()

      dispatch(PulPalServicesActionCreators.setServicesListAll(services))

      dispatch(PulPalServicesActionCreators.setError(''))
    } catch (e) {
      dispatch(PulPalServicesActionCreators.setError('Error'))
    } finally {
      dispatch(
        LoadingActionCreators.loadingEnd(
          PulPalServicesLoadingTypes.GET_SERVICE_LIST_ALL,
        ),
      )
    }
  },
}
