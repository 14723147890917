import React from 'react';
import { BusinessBushProps } from './BusinessBush.props';
import styles from '../Business.module.scss';
import { useTranslation } from 'react-i18next';


const BusinessBush: React.FC<BusinessBushProps> = () => {
    const { t } = useTranslation();
    return (
        <section className={`${styles.bush} page-slider`}>
            <div className='container'>
                <div className={styles['bush__inner']}>
                    <div className={'row'}>
                        <div className={`col-12 col-md-6 ${styles['bush__left']}`}>
                            <h1>
                                {t("YourBusiness")}
                            </h1>
                            <p>
                                {t("PulPal payment")}
                            </p>
                        </div>
                        <div className={`col-md-6 d-none d-md-block ${styles['bush__right']}`}>
                            <div className={styles['bush__right-pic']}></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default BusinessBush;