import "./AddMerchant.scss";
import moment from "moment-timezone";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SignUpEntityService from "../../services/entity";
import { Password } from "primereact/password";
import { PassHeader, PassFooter } from "../passwordInputs/passwordInputs";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import SignUpIndividualService from "../../services/individual";
import { toast } from 'react-toastify';

const AddMerchant: React.FC = () => {
    const [fullName, setFullName] = useState("");
    const [entityUserOptions, setEntityUserOptions] = useState({
        organizationName: "",
        website: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        language: localStorage.getItem("language"),
        timezone: moment.tz.guess(),
        voen: "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const lang = localStorage.getItem('language');
    const { language } = useSelector((state: any) => state.languageReducer);


    const handleChangePhoneNumber = (e: any) => {
        const regex = /^[0-9\b]+$/;
        if (e.target.value === "" || regex.test(e.target.value)) {
            setEntityUserOptions({ ...entityUserOptions, phone: e.target.value });
        }
    };

    const { t } = useTranslation();
    const navigate = useNavigate();
    const {
        control,
        register,
        handleSubmit,
        setError,
        formState: { errors },
    }: any = useForm({ mode: "all" });

    useEffect(() => {
        window.scrollTo(50, 50);
    }, []);

    const handleChangeInput = (e: any) => {
        const { value, maxLength } = e.target;
        const message = value.slice(0, maxLength);
        setEntityUserOptions({ ...entityUserOptions, voen: message });
    };

    const createMerchant = async (e: any) => {
        const phoneNumberRegex = /^(50|51|55|70|77|99|10)[2-9][0-9]{6}$/;
        const inputPhoneNumber = entityUserOptions.phone;
        const isValidPhoneNumber = phoneNumberRegex.test(inputPhoneNumber);

        // const regexPass = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
        // const passwordInput = entityUserOptions.password;
        // const isValidPassword = regexPass.test(passwordInput);
        if (
            isValidPhoneNumber &&
            // isValidPassword &&
            entityUserOptions.organizationName &&
            entityUserOptions.email &&
            entityUserOptions.phone &&
            entityUserOptions.voen &&
            fullName &&
            Object.keys(errors).length === 0
            // && handlePasswordCheck()
        ) {
            setIsLoading(true);


            const self = await SignUpIndividualService.getSelf();
            console.log('self', self);
            if (self?.status === 200) {
                const merchant = await SignUpEntityService.createMerchant({
                    contactEmail: entityUserOptions.email,
                    contactName: entityUserOptions.firstName + entityUserOptions.lastName,
                    enterprenuer: false,
                    language: lang,
                    name: entityUserOptions.organizationName,
                    userId: self.data.id,
                    website: entityUserOptions.website
                })
                if (merchant?.status === 200) {
                    const questionnaire = await SignUpEntityService.createQuestionnaire({
                        phone: '+994' + entityUserOptions.phone,
                        merchantId: merchant.data.id,
                        organizationName: entityUserOptions.organizationName,
                        voen: entityUserOptions.voen,
                        contactName: entityUserOptions.firstName + entityUserOptions.lastName,
                        userId: self.data.id
                    })
                    // goToMerchant();
                    // navigate('/voenSuccess');
                    if (questionnaire?.status === 200) {
                        setEntityUserOptions({
                            organizationName: "",
                            website: "",
                            firstName: "",
                            lastName: "",
                            email: "",
                            phone: "",
                            language: localStorage.getItem("language"),
                            timezone: moment.tz.guess(),
                            voen: "",
                        })
                        setFullName('')

                        {
                            language === 'az'
                                ?
                                toast.success(('Müraciətiniz nəzərdən keçirilir. Hər hansı sualınız olarsa, +994776778777 nömrəsi ilə PulPal ilə əlaqə saxlayın'), {
                                    position: toast.POSITION.TOP_CENTER,
                                })
                                :
                                toast.success((t(`Wait`) + '+994776778777'), {
                                    position: toast.POSITION.TOP_CENTER,
                                })
                        }
                    }
                    console.log('questionnaire', questionnaire)
                }
                else {
                    toast.error((t('appLimit')), {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            }
        } else {
            setError("phone", {
                type: "server",
                message: "ThisPhoneIsAlreadyUsed",
            });
            setError("email", {
                type: "server",
                message: "ThisPhoneIsAlreadyUsed",
            });
        }
        setIsLoading(false);
    };

    console.log('errors', errors)

    const onFullNameChange = (fullName: string) => {
        const regexFullName = /^[а-яА-Яa-zA-Z\s]+/;
        if (fullName === "" || regexFullName.test(fullName)) {
            setFullName(fullName);
        }
    };

    const onSubmit = async () => {
    };

    return (
        <main className="main" id="top">
            <div className="container-fluid">
                <div className="row min-vh-100 bg-100 mns">
                    <div
                        className="col-6 d-none d-lg-block position-relative"
                        style={{ position: "relative" }}
                    >
                        <div className="bg-holder-img holder-img"></div>
                    </div>
                    <div className="col-sm-10 col-md-6 px-sm-0 align-self-center mx-auto py-5">
                        <div className="row justify-content-center g-0">
                            <div className="col-lg-10 col-xl-10 col-xxl-10">
                                <div className="card">
                                    <div className="card-body p-4">
                                        <div className="row flex-between-center">
                                            <div className="col-auto">
                                                <h3 className="appeal">{t("AppForEntrepreneurs")}</h3>
                                            </div>
                                        </div>
                                        <form
                                            onSubmit={handleSubmit(onSubmit)}
                                            action="#"
                                            className="mt-4"
                                        >
                                            <div className="row gx-2">
                                                <div className="mb-3 col-sm-6">
                                                    <label className="form-label labels-color">
                                                        {t("OrganizationName")}
                                                    </label>
                                                    <input
                                                        className={
                                                            errors.org
                                                                ? "form-control inputsSpecial errorInputs"
                                                                : "form-control"
                                                        }
                                                        type="text"
                                                        value={entityUserOptions.organizationName}
                                                        {...register("org", {
                                                            required: true,
                                                            minLength: 2,
                                                        })}
                                                        onChange={(e) =>
                                                            setEntityUserOptions({
                                                                ...entityUserOptions,
                                                                organizationName: e.target.value,
                                                            })
                                                        }
                                                        id="org"
                                                        name="org"
                                                    />
                                                    {errors.org && errors.org.type === "required" && (
                                                        <p className="error_message">
                                                            {t("RequiredField")}
                                                        </p>
                                                    )}
                                                    {errors.org && errors.org.type === "minLength" && (
                                                        <p className="error_message">
                                                            {t("MinTwoSymbols")}
                                                        </p>
                                                    )}
                                                </div>
                                                <div className="mb-3 col-sm-6">
                                                    <div className="websiteLabelWrapper">
                                                        <label className="form-label labels-color websiteLabel">
                                                            {t("WebsiteOrSoc")}
                                                        </label>
                                                    </div>
                                                    <input
                                                        className={
                                                            errors.orgEmail
                                                                ? "form-control inputsSpecial errorInputs"
                                                                : "form-control"
                                                        }
                                                        value={entityUserOptions.website}
                                                        type="text"
                                                        {...register("orgEmail", { required: false })}
                                                        onChange={(e) =>
                                                            setEntityUserOptions({
                                                                ...entityUserOptions,
                                                                website: e.target.value,
                                                            })
                                                        }
                                                        id="orgEmail"
                                                        name="orgEmail"
                                                    />
                                                    {errors.orgEmail &&
                                                        errors.orgEmail.type === "required" && (
                                                            <p className="error_message">
                                                                {t("RequiredField")}
                                                            </p>
                                                        )}
                                                    {errors.orgEmail &&
                                                        errors.orgEmail.type === "pattern" && (
                                                            <p className="error_message">Incorrect email</p>
                                                        )}
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label labels-color">
                                                    {t("Firstname")}, {t("Lastname")}
                                                </label>
                                                <input
                                                    className={
                                                        errors.fullName
                                                            ? "form-control inputsSpecial errorInputs"
                                                            : "form-control"
                                                    }
                                                    type="text"
                                                    value={fullName}
                                                    {...register("fullName", {
                                                        required: true,
                                                        pattern: {
                                                            value: /^[а-яА-Яa-zA-Z]{3,}\s[а-яА-Яa-zA-Z]{3,}$/,
                                                        },
                                                        // pattern: { value: /^[а-яА-Яa-zA-Z\s]+/ },
                                                    })}
                                                    onChange={(e) => onFullNameChange(e.target.value)}
                                                    id="fullName"
                                                    name="fullName"
                                                />
                                            </div>
                                            {errors.fullName &&
                                                errors.fullName.type === "required" && (
                                                    <p className="error_message">{t("RequiredField")}</p>
                                                )}
                                            {errors.fullName &&
                                                errors.fullName.type === "pattern" && (
                                                    <p className="error_message">
                                                        {t("InvalidFullname")}
                                                    </p>
                                                )}
                                            <div className="row gx-2">
                                                <div className="mb-3 col-sm-6 relativeInput">
                                                    <label className="form-label labels-color">
                                                        {t("Telephone number")}
                                                    </label>
                                                    <span className="phone-input-prefix">+994</span>
                                                    <input
                                                        className={
                                                            errors.phone
                                                                ? "form-control inputsSpecial errorInputs phoneInput"
                                                                : "form-control phoneInput"
                                                        }
                                                        type="tel"
                                                        name="phone"
                                                        value={entityUserOptions.phone}
                                                        {...register("phone", {
                                                            required: "Incorrect phone number",
                                                            pattern: {
                                                                value: /^(50|51|55|70|77|99|10)[2-9][0-9]{6}$/,
                                                                message: "Incorrect phone number",
                                                            },
                                                        })}
                                                        onChange={(e) => handleChangePhoneNumber(e)}
                                                        id="card-tel"
                                                        maxLength={9}
                                                    />
                                                    {errors.phone &&
                                                        errors.phone.type === "minLength" && (
                                                            <p className="error_message">
                                                                {t("IncorrectPhoneNumber")}
                                                            </p>
                                                        )}
                                                    {errors.phone && errors.phone.type === "required" && (
                                                        <p className="error_message">
                                                            {t("RequiredField")}
                                                        </p>
                                                    )}
                                                    {errors.phone && errors.phone.type === "pattern" && (
                                                        <p className="error_message">
                                                            {t("IncorrectPhoneNumber")}
                                                        </p>
                                                    )}
                                                    {errors.phone && errors.phone.type === 'server' && <p style={{ width: '200px' }} className='error_message'>{t("ThisPhoneIsAlreadyUsed")}</p>}
                                                </div>
                                                <div className="mb-3 col-sm-6">
                                                    <label className="form-label labels-color">
                                                        {t("Email")}
                                                    </label>
                                                    <input
                                                        className={
                                                            errors.email
                                                                ? "form-control inputsSpecial errorInputs"
                                                                : "form-control"
                                                        }
                                                        type="text"
                                                        value={entityUserOptions.email}
                                                        {...register("email", {
                                                            required: true,
                                                            pattern: {
                                                                value:
                                                                    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                                            },
                                                        })}
                                                        onChange={(e) =>
                                                            setEntityUserOptions({
                                                                ...entityUserOptions,
                                                                email: e.target.value,
                                                            })
                                                        }
                                                        id="email"
                                                        name="email"
                                                    />
                                                    {errors.email && errors.email.type === "required" && (
                                                        <p className="error_message">
                                                            {t("RequiredField")}
                                                        </p>
                                                    )}
                                                    {errors.email && errors.email.type === "pattern" && (
                                                        <p className="error_message">
                                                            {t("IncorrectEmail")}
                                                        </p>
                                                    )}
                                                </div>
                                                {errors.phone && errors.phone.type === "server" && (
                                                    <p
                                                        style={{ width: "400px" }}
                                                        className="error_message"
                                                    >
                                                        {t("PhoneOrEmailUsed")}
                                                    </p>
                                                )}
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label labels-color">
                                                    {t("TIN")}
                                                </label>
                                                <input
                                                    className={
                                                        errors.voen
                                                            ? "form-control inputsSpecial errorInputs"
                                                            : "form-control"
                                                    }
                                                    value={entityUserOptions.voen}
                                                    {...register("voen", {
                                                        required: true,
                                                        minLength: 8,
                                                        maxLength: 10,
                                                    })}
                                                    onChange={(e) => handleChangeInput(e)}
                                                    type="number"
                                                    id="voen"
                                                    name="voen"
                                                    maxLength="10"
                                                />
                                                {errors.voen && errors.voen.type === "required" && (
                                                    <p className="error_message">{t("RequiredField")}</p>
                                                )}
                                                {errors.voen && errors.voen.type === "minLength" && (
                                                    <p className="error_message">{t("Minimum")}</p>
                                                )}
                                            </div>
                                            <div className="mb-3">
                                                <button
                                                    type="submit"
                                                    onClick={createMerchant}
                                                    className="btn btn-primary d-block w-100 mt-4 reg btnCnt"
                                                    name="submit"
                                                    disabled={isLoading}
                                                >
                                                    {isLoading ? (
                                                        <i
                                                            className="pi pi-spin pi-spinner"
                                                            style={{ fontSize: "1.2rem" }}
                                                        ></i>
                                                    ) : (
                                                        `${t("ApplyReg")}`
                                                    )}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default AddMerchant;
