import React, { useState } from 'react';
import { ContactsMapProps } from './Contacts.props';
import styles from './Contacts.module.scss';
import loading from '../../assets/Loading_icon.gif';

const ContactsMap: React.FC<ContactsMapProps> = () => {
    const [isLoading, setIsLoading] = useState(true);
    const handleMapLoad = () => {
        setIsLoading(false);
    };

    return (
        <div className={styles.map}>
            <div className={'container'}>
                <div className={styles['map__inner']}>
                    {isLoading && <div className={styles.loader}><img src={loading} alt="loading" /></div>}
                    <iframe
                        width='100%' height='100%'
                        frameBorder='0' scrolling='no'
                        marginHeight={0} marginWidth={0}
                        onLoad={handleMapLoad}
                        src='https://www.openstreetmap.org/export/embed.html?bbox=49.873555%2C40.3792%2C49.873555%2C40.3792&amp;layer=mapnik&amp;marker=40.3792%2C49.873555'></iframe>
                </div>
            </div>
        </div>
    );
}

export default ContactsMap;