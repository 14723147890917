import './index.scss'
import { useEffect, useState } from 'react'
import backHeadImage from '../../assets/spot-illustrations/corner-4.png';
import { useSelector } from 'react-redux'
import PaymentView from "./PaymentView";
import { imagesForCategories } from "../../imagesForCategories";
import ItemList from "./ItemList";
import { PulPalServicesLoadingTypes } from "../../redux/reducers/pulPalServices/types";
import { useTranslation } from 'react-i18next';
import { useActions } from '../hooks/useAction';
import loading from '../../assets/Loading_icon.gif'
import { Search } from '../../components/Search/Seach';
import { FilteredServices } from '../../components/PaymentPage/FilteredSearvices';


const paymentSkeleton = new Array(19).fill(0)
export const PaymentPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();

  const categories = useSelector((state) => state?.pulPalServicesReducer?.categories);
  const langKey = useSelector((state) => state.languageReducer.langKey);
  const subjects = useSelector((state) => state.loadingReducer.subjects);
  const servicesListAll = useSelector((state) => state?.pulPalServicesReducer?.servicesListAll);

  const { getCategoriesList: getCategoriesListAction } = useActions();
  const { getServiceListAll } = useActions()
  const [inputString, setInputString] = useState("");

  const paymentImage = (name) => {
    const image = imagesForCategories?.find((image) => image.name === name)
    return image?.image
  }

  useEffect(() => {
    getServiceListAll()
  }, [])

  const filteredList = inputString?.trim() ? servicesListAll?.filter((ser) => {//test
    if (ser.localeName.valueAz.toLowerCase().includes(inputString?.trim()?.toLowerCase()) || ser.localeName.valueRu.toLowerCase().includes(inputString.toLowerCase()) || ser.localeName.valueEn.toLowerCase().includes(inputString.toLowerCase())) {
      return ser;
    }
  }) : [];

  const handleInputChange = (event) => {
    setInputString(event.target.value);
  };


  useEffect(() => {
    ; (async () => {
      await getCategoriesListAction()
    })()
  }, [getCategoriesListAction])

  const categoriesItems = []
  categories?.forEach((item) => {
    const paymentIcon = paymentImage(item.name)
    if
      (paymentIcon)
      categoriesItems.push(
        <PaymentView
          linkTo={`/payment-list/${item.id}`}
          icon={paymentIcon}
        >
          {item.localeName[langKey]}
        </PaymentView>,
      )
  })

  return (
    <div className='content' id='payments'>
      <div className='card mb-3 mt-3'>
        <div
          className='bg-holder d-none d-lg-block bg-card'
          style={{ backgroundImage: `url(${backHeadImage})` }}
        ></div>
        <div className='card-body position-relative'>
          <div className='row'>
            <div className='col-lg-8'>
              <h3 style={{ fontSize: '1.728rem' }} className='mb-0'>{t('Payments')}</h3>
            </div>
          </div>
        </div>
      </div>
      <div className='card mt-3'>
        <div className='card-body'>
          <div className='row gx-3 itemWrapper'>
            <Search inputString={inputString} handleInputChange={handleInputChange} />
            {inputString && filteredList ? <FilteredServices filteredList={filteredList} /> :
              (subjects.has(PulPalServicesLoadingTypes.GET_CATEGORIES)
                ?
                <div
                  className='loading-gif'
                  style={{ width: '1200px', minHeight: '60vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  <img src={loading} alt="loading" />
                </div>
                :
                <ItemList
                  classNames={'categories'}
                  itemClassNames={'categories__item'}
                  items={[...categoriesItems]}
                />
              )}
          </div>
        </div>
      </div>
    </div>
  )
}

