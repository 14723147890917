import SignUp from "../SignUp/SignUp"
import { useAppSelector } from "../hooks/redux"
import BusinessApplication from "./BusinessApplication/BusinessApplication"
import BusinessBush from "./BusinessBush/BusinessBush"
import BusinessCabinet from "./BusinessCabinet/BusinessCabinet"
import BusinessPayment from "./BusinessPayment/BusinessPayment"
import BusinessSales from "./BusinessSales/BusinessSales"
import { useEffect } from 'react';

export const BusinessPage = () => {

    const authorized: boolean = useAppSelector((state: any) => state.helperReducer.authorized);
    const anotherPage: boolean = useAppSelector((state: any) => state.helperReducer.anotherPage);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (anotherPage) {
            window.scrollTo(0, 2700)
        }
    })
    return (
        <div>
            <BusinessBush></BusinessBush>
            <BusinessCabinet></BusinessCabinet>
            <BusinessPayment></BusinessPayment>
            <BusinessSales></BusinessSales>
            <BusinessApplication></BusinessApplication>
            <SignUp />
        </div>
    )
}