import '../src/styles/globals.scss';
import { useEffect, useRef } from 'react';
import Contacts from './components/Contacts/Contacts';
import { Route, Routes, useLocation } from 'react-router';
import { HomePage } from './components/Home/HomePage';
import { AboutPage } from './components/About/AboutPage';
import { BusinessPage } from './components/Business/BusinessPage';
import Modal from './components/Modal/Modal';
import { ModulesPage } from './components/Modules/ModulesPage';
import AgreementPolicy from './components/Agreement/AgreementPolicy';
import PrivacyPolicy from './components/Agreement/PrivacyPolicy';
import AskQuestionModal from './components/AskQuestion/AskQuesiton';
import Verification from './components/Registration/Verification';
import RegistrationEntity from './components/Registration/RegistrationEntity';
import RegistrationIndividual from './components/Registration/RegistrationIndividual';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import AuthService from './services/auth';
import VerificationEntity from './components/Registration/VerificationEntity';
import Verified from './components/Verified/Verified';
import { useAppDispatch } from './components/hooks/redux';
import { setAuthorizationAC, setUserName } from './redux/reducers/helper/helper';
import RestorePassword from './components/RestorePassword/RestorePassword';
import OtpCodePage from './components/RestorePassword/OtpPage';
import NewPassword from './components/RestorePassword/NewPassword';
import UserSuccess from './components/Success/UserSuccess';
import VoenSuccess from './components/Success/VoenSuccess';
import OtpPageEmail from './components/RestorePassword/ByEmail/OtpPageEmail';
import NewPasswordByEmail from './components/RestorePassword/ByEmail/NewPasswordByEmail';
import { useTranslation } from 'react-i18next';
import NotFound from './components/NotFound/NotFound';
import AcceptationRequest from './components/AcceptationRequest/AcceptationRequest';
import Layout from './components/Layout/Layout';
import VerificationAfterSignUp from './components/VerifyAfterSignUp/VerifyAfterSignUp';
import { PaymentPage } from './components/PaymentPage';
import { PaymentListPage } from './components/PaymentListPage';
import ServicePayComponent from './components/ServicePayComponent/ServicePayComponent';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Reverification from './components/Reverification/Reverification';
import 'react-toastify/dist/ReactToastify.css';
import SignUpIndividualService from './services/individual';
//@ts-ignore
import AddMerchant from './components/AddMerchant/AddMerchant';
import Rate from './components/Rate/Rate';

const App = () => {
  const dispatch = useAppDispatch();
  const toast: any = useRef(null);
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    console.log('refresh')
    const auth: any = localStorage.getItem('pulpalAuth');
    console.log(`auth:`, auth)
    const authData = JSON.parse(auth);
    if (auth) {
      ; (async () => {
        try {
          const data = await AuthService.refresh(authData.refresh);

          if (data === undefined) throw new Error()

          AuthService.setAuthData(data?.data)
          dispatch(setAuthorizationAC(true));
          const self = await SignUpIndividualService.getSelf();
          dispatch(setUserName(self?.data.firstName));
        } catch (error) {
          dispatch(setAuthorizationAC(false));
          AuthService.removeAuthData()
        }
      })();
    }
  }, []);

  return (
    <>
      <Routes>
        <Route element={<Layout />}>
          <Route path='' element={<HomePage />} />
          <Route path='/about' element={<AboutPage />} />
          <Route path='/contacts' element={<Contacts />} />
          <Route path='/business' element={<BusinessPage />} />
          <Route path='/modules' element={<ModulesPage />} />
          <Route path='/agreement-policy' element={<AgreementPolicy />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/sign-up' element={<RegistrationEntity />} />
          <Route path='/sign-up/verification' element={<VerificationEntity />} />
          <Route path='/sign-up-physical' element={<RegistrationIndividual />} />
          <Route path={`/sign-up-physical/verification`} element={<Verification />} />
          <Route path={`/sign-up-physical/forgotPassword`} element={<ForgotPassword />} />
          <Route path={`/forgotPassword`} element={<RestorePassword />} />
          <Route path={`/forgotPassword/otp`} element={<OtpCodePage />} />
          <Route path={`/forgotPassword/otpEmail`} element={<OtpPageEmail />} />
          <Route path={`/forgotPassword/otp/newPassword`} element={<NewPassword />} />
          <Route path={`/forgotPassword/otpEmail/newPasswordByEmail`} element={<NewPasswordByEmail />} />
          <Route path={`/verified`} element={<Verified />} />
          <Route path={`/verification-page`} element={<VerificationAfterSignUp />} />
          <Route path={`/merchant-status/:id`} element={<AcceptationRequest />} />
          <Route path='/payments' element={<PaymentPage />} />
          <Route path='/payment-list/:id' element={<PaymentListPage />} />
          <Route path='/service-pay/:id' element={<ServicePayComponent />} />
          <Route path='/re-verification' element={<Reverification />} />
          <Route path='/add-merchant' element={<AddMerchant />} />
          <Route path='/cusperco-partner-tariffs' element={<Rate />} />
        </Route>
        <Route path='/userSuccess' element={<UserSuccess />} />
        <Route path='/voenSuccess' element={<VoenSuccess />} />
        <Route path='/*' element={<NotFound />} />
      </Routes>
      {/* <ToastContainer style={{ zIndex: 99999 }} position="top-center"/> */}
      <Modal />
      <AskQuestionModal />
      <ToastContainer style={{ zIndex: 99999 }} position="top-center" />
    </>
  );
}

export default App;
