import DevIconSVG from './../../../assets/icons/dev.svg';
import DblJPG from './../../../assets/dbl.jpg';
import ApiIconSVG from './../../../assets/icons/api.svg';
import RabIconSVG from './../../../assets/icons/rab.svg';
import ModuleIconSVG from './../../../assets/icons/module.svg';
import RayIconSVG from './../../../assets/icons/ray.svg';

export const DeveloperSources = {
    devIcon: DevIconSVG,
    dbl: DblJPG,
    apiIcon: ApiIconSVG,
    rab: RabIconSVG,
    module: ModuleIconSVG,
    ray: RayIconSVG
}