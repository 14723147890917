import { useLocation, useNavigate } from "react-router-dom";
import styles from "../restorePassword.module.scss";
import { Password } from "primereact/password";
import { useState } from "react";
import SignUpIndividualService from "../../../services/individual";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";

const NewPasswordByEmail = () => {
  const locationData = useLocation();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    control,
    register,
    handleSubmit,
    setError,
    formState: {
      errors,
    }
  }: any = useForm({ mode: 'all' });


  const handlePasswordCheck = () => {
    if (newPassword === confirmPassword) {
      return true;
    } else {
      setError('password', {
        type: 'confirm',
        message: ''
      })
      return false
    }
  };

  const confirmNewPassword = async (e: any) => {
    const regexPass = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    const passwordInput = newPassword;
    const isValidPassword = regexPass.test(passwordInput);
    e.preventDefault();
    if (isValidPassword && handlePasswordCheck()) {
      const data = await SignUpIndividualService.restorePasswordChangeByEmail({
        email: locationData.state?.email,
        password: newPassword,
        operation: localStorage.getItem("operationEmail"),
      });
      console.log("restore change password DATA", data);
      if (data?.status === 200) {
        navigate(`/`);
        toast.success(t('PasswordChanged'), {
          position: toast.POSITION.TOP_CENTER,
      })
      }
    }
  };

  const onSubmit = async () => {
    handlePasswordCheck();
  };

  return (
    <div className={styles.newPasswordWrapper}>
      <div className={styles.newPasswordInner}>
        <div className={styles.titlePass}>
          <p>{t("CreatePassword")}</p>
        </div>
        <div className={styles.newPasswordTitle}>
          <p>
            {t("ChoosePassword")}
          </p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} action="">
          <div className={styles.passwordWrapper}>
            <div style={{position: 'relative'}} className={`${styles.wrap} mb-3 col-sm-6`}>
              <Controller
                control={control}
                name='password'
                rules={{ required: true }}
                render={({ field }) => (
                  <Password
                    className={errors.password ? "p-invalid" : ""}
                    value={field.value}
                    placeholder={t("Password")}
                    {...register('password', {
                      required: true,
                      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&]).{8,}$/
                    })}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      setNewPassword(e.target.value)
                    }}
                    toggleMask
                    feedback={false}
                  />
                )}
              />
                <div className={styles.infoNewPass}>
                <i className="pi pi-info-circle" style={{ fontSize: '1rem' }}></i>
                <div className={styles.infoTextPass}>
                  <ul
                    style={{ marginLeft: "20px" }}
                    className={styles.listFlexible}
                  >
                    <li>{t("OneLowercase")}</li>
                    <li>{t("OneUppercase")}</li>
                    <li>{t("OneNumeric")}</li>
                    <li>{t("OneSpecialSymbol")}</li>
                    <li>{t("Minimum")}</li>
                  </ul>
                </div>
              </div>
              {errors.password && errors.password.type === "required" && <p className='error_message'>{t("RequiredField")}</p>}
            </div>
            <div style={{position: 'relative'}} className={`${styles.wrap} mb-3 col-sm-6`}>
              <Controller
                control={control}
                name='repeatPassword'
                rules={{ required: true }}
                render={({ field }) => (
                  <Password
                    className={errors.password ? "p-invalid" : ""}
                    value={field.value}
                    placeholder={t("ConfirmPassword")}
                    {...register('password', {
                      required: true,
                      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&]).{8,}$/
                    })}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      setConfirmPassword(e.target.value)
                    }}
                    toggleMask
                    feedback={false}
                  />
                )}
              />
               <div className={styles.infoNewPassTwo}>
                <i className="pi pi-info-circle" style={{ fontSize: '1rem' }}></i>
                <div className={styles.infoTextPassTwo}>
                  <ul
                    style={{ marginLeft: "20px" }}
                    className={styles.listFlexible}
                  >
                    <li>{t("OneLowercase")}</li>
                    <li>{t("OneUppercase")}</li>
                    <li>{t("OneNumeric")}</li>
                    <li>{t("OneSpecialSymbol")}</li>
                    <li>{t("Minimum")}</li>
                  </ul>
                </div>
              </div>
              {errors.password && errors.password.type === "required" && <p className='error_message'>{t("RequiredField")}</p>}
              {errors.password && errors.password.type === 'confirm' && <p className='error_message'>{t("PasswordMismatch")}</p>}
            </div>
          </div>
          <button
            onClick={(e) => confirmNewPassword(e)}
            type="submit"
            className={`${styles.btnCnt} button button-c2c mt-3`}
          >
            {t("Confirm")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default NewPasswordByEmail;