import IconText from '../../../elements/IconText/IconText';
import React from 'react';
import { BusinessResources } from '../Business.resources';
import { BusinessCabinetProps } from './BusinessCabinet.props';
import styles from '../Business.module.scss';
import { useTranslation } from 'react-i18next';


const BusinessCabinet: React.FC<BusinessCabinetProps> = () => {
    const {t} = useTranslation();
    return (
        <section className={styles.bizcab}>
            <div className='container'>
                <div className={styles['bizcab__inner']}>
                    <div className='row'>
                        <div className='col-12 col-lg-7 text-center'>
                            <img className={styles['bizcab__inner-img']} src={BusinessResources.bizcab}
                                //srcSet={`${BusinessResources.bizcab}, ${BusinessResources.bizcab2x} 2x`}
                                alt='Sizin Biznes kabinet' />
                        </div>
                        <div className='col-12 col-lg-5'>
                            <h2 className='alttitle'>
                                <img src={BusinessResources.mIcon} alt='' />{t("Your Business cabinet")}
                            </h2>
                            <div className={styles['bizcab__content']}>
                                <IconText image={{src: BusinessResources.svg01, alt: ''}} desc={t("Creation of products")} />
                                <IconText image={{src: BusinessResources.svg02, alt: ''}} desc={t("Creation of services")} />
                                <IconText image={{src: BusinessResources.svg03, alt: ''}} desc={t("Financial and inventory reports")} />
                                <IconText image={{src: BusinessResources.svg04, alt: ''}} desc={t("Extensive settings")} />
                                <IconText image={{src: BusinessResources.svg05, alt: ''}} desc={t("Invoice, debt and monthly payment")} />
                                <IconText image={{src: BusinessResources.svg06, alt: ''}} desc={t("Creating a customer base")} />
                                <IconText image={{src: BusinessResources.svg07, alt: ''}} desc={t("Creating a user profile")} />
                                <IconText image={{src: BusinessResources.svg08, alt: ''}} desc={t("Various currencies")} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default BusinessCabinet