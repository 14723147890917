import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import styles from './restorePassword.module.scss';
import SignUpIndividualService from '../../services/individual';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

const RestorePassword = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [phoneNumber, setphoneNumber] = useState('+994');
    const [email, setEmail] = useState('');
    const {
        register,
        handleSubmit,
        formState: {
            errors,
        }
    }: any = useForm({ mode: 'onBlur' });

    useEffect(() => {
        window.scrollTo(0, 75);
    }, []);

    console.log('errros', errors);

    const restorePasswordFunc = async () => {
        if (!errors.phone && phoneNumber.length === 13) {
            const res = await SignUpIndividualService.restorePassword(phoneNumber)
            if (res?.status === 200) {
                localStorage.setItem('operation', res?.data.operation);
                navigate(`/forgotPassword/otp`, { replace: true, state: { phone: phoneNumber } });
            }
        }
    };

    const restorePasswordFuncByEmail = async () => {
        if (!errors.email && email.length >= 6) {
            const res = await SignUpIndividualService.restorePasswordByEmail(email)
            if (res?.status === 200) {
                localStorage.setItem('operationEmail', res?.data.operation);
                navigate(`/forgotPassword/otpEmail`, { replace: true, state: { email: email } });
            }
        }
    };

    const onSubmit = () => { }
    const onSubmitEmail = () => { }

    return (
        <div className={styles.restoreWrapper}>
            <div className={styles.restoreInner}>
                <div className={'modal-dialog'}>
                    <div className={'modal-content'}>
                        <div className={`modal-header`}>
                            <h1 className={`${styles.restoreTitle} modal-title fs-5`} id={'restoreModalLabel'}>{t("RestorePassword")}</h1>
                        </div>
                        <div className={'modal-body'}>

                            <ul className={'nav nav-pills mb-3'} id={'pills-tab'} role={'tablist'}>
                                <li className={'nav-item'} role={'presentation'}>
                                    <button className={'nav-link active'} id={'pills-home-tab'} data-bs-toggle={'pill'}
                                        data-bs-target={'#pills-home'} type={'button'} role={'tab'} aria-controls={'pills-home'} aria-selected={'true'}>
                                        {t("ByPhone")}
                                    </button>
                                </li>
                                <li className={'nav-item'} role='presentation'>
                                    <button className={'nav-link'} id={'pills-profile-tab'} data-bs-toggle={'pill'}
                                        data-bs-target={'#pills-profile'} type={'button'} role={'tab'} aria-controls={'pills-profile'} aria-selected={'false'}>
                                        {t("ByEmail")}
                                    </button>
                                </li>
                                <li className={styles.navlinkItem}>
                                </li>
                            </ul>

                            <div className='tab-content' id='pills-tabContent'>
                                <div className='tab-pane fade show active' id='pills-home' role='tabpanel' aria-labelledby='pills-home-tab' tabIndex={0}>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className='form-floating mb-3'>
                                            <input
                                                value={phoneNumber}
                                                className={errors.phone ? "form-control errorInputs" : "form-control"}
                                                {...register('phone', {
                                                    required: true,
                                                    pattern: { value: /^\+\d{3}(10|50|51|55|70|77|99)[2-9]{1}\d{6}$/, message: 'Incorrect phone number' }
                                                })}
                                                onChange={(e) => setphoneNumber(e.target.value)}
                                                type="tel"
                                                id="floatingInput"
                                                maxLength={13}
                                                minLength={13}
                                                placeholder='+99450 000 00 00'
                                            />
                                            <div className={styles.info}>
                                                <i className="pi pi-info-circle" style={{ fontSize: '1.2rem' }}></i>
                                                <div className={styles.infoText}>
                                                    {t("InfoPhoneText")}
                                                </div>
                                            </div>
                                            <label htmlFor={'floatingInput'}>{t("PhoneNumber")}</label>
                                            {errors.phone && errors.phone.type === "pattern" && <p className='error_message'>{t("IncorrectPhoneNumber")}</p>}
                                            {errors.phone && errors.phone.type === 'required' && <p className='error_message'>{t("RequiredField")}</p>}
                                        </div>
                                        <div className='d-grid'>
                                            <button onClick={(e) => {
                                                restorePasswordFunc();
                                                e.preventDefault();
                                            }}
                                                type='submit' className='button button-c2c mt-3'>{t("Next")}</button>
                                        </div>

                                    </form>
                                </div>

                                <div className='tab-pane fade' id='pills-profile' role='tabpanel' aria-labelledby='pills-profile-tab' tabIndex={0}>
                                    <form onSubmit={handleSubmit(onSubmitEmail)}>
                                        <div className='form-floating mb-3'>
                                            <input
                                                value={email}
                                                {...register('email', { required: true, pattern: { value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/ } })}
                                                onChange={(e) => setEmail(e.target.value)}
                                                className={errors.email ? "form-control errorInputs" : "form-control"}
                                                id='floatingInput'
                                                name='email'
                                                placeholder={`${t('Email')}`}
                                                type='text'
                                            />
                                            {errors.email && errors.email.type === "required" && <p className='error_message'>{t("RequiredField")}</p>}
                                            {errors.email && errors.email.type === "pattern" && <p className='error_message'>{t("IncorrectEmail")}</p>}
                                            <label htmlFor={'floatingInput'}>{t("Email")}</label>
                                        </div>
                                        <div className='d-grid'>
                                            <button onClick={(e) => {
                                                restorePasswordFuncByEmail();
                                                e.preventDefault();
                                            }}
                                                type='submit' className='button button-c2c mt-3'>{t("Next")}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div>
                </div>
            </div >
        </div >
    )
}

export default RestorePassword;