// import Image from 'next/image';
import React from 'react';
import { IconTextProps } from './IconText.props';
import styles from './IconText.module.scss';

const IconText: React.FC<IconTextProps> = ({ image, desc }) => {
    return (
        <div className={styles['icon-text']}>
            <img src={image.src} alt={image.alt} />
            <p className={styles['icon-text__desc']}>{desc}</p>
        </div>
    );
}

export default IconText;