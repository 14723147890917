import React from 'react';
import { ModulesCardProps, ModulesCardsProps } from './modulesCards.props';
import styles from './../modules.module.scss';
import { ModulesResources } from '../modules.resources';
import { useTranslation } from 'react-i18next';

const ModulesCards: React.FC<ModulesCardsProps> = () => {
    const { t } = useTranslation();
    return (
        <div className={styles.modcards}>
            <div className={'container'}>
                <div className={'row'}>

                    <ModulesCard
                        logo={{ src: ModulesResources.wordpress, alt: '' }}
                        title={`${t("What is a WordPress Plugin?")}`}
                        desc={`${t("A WordPress Plugin")}`}
                        installation={`${t("Installation (one time):")}` + ' 150 azn'}
                        date={`${t("Annual technical support:")}` + ' 100 azn'}
                    />

                    <ModulesCard
                        logo={{ src: ModulesResources.opencart, alt: '' }}
                        title={`${t("Opencart module - what is it?")}`}
                        desc={`${t("You can add new")}`}
                        installation={`${t("Installation (one time):")}` + ' 150 azn'}
                        date={`${t("Annual technical support:")}` + ' 100 azn'}
                    />

                    <ModulesCard
                        logo={{ src: ModulesResources.tilda, alt: '' }}
                        title={`${t("Tilda Pulbishing")}`}
                        desc={`${t("You can add new")}`}
                        installation={`${t("Installation (one time):")}` + ' 150 azn'}
                        date={`${t("Annual technical support:")}` + ' 100 azn'}
                    />
                    <NewModulesCard  desc={`${t("OrderPlugin")}`} />
                </div>
            </div>
        </div>
    );
}

const ModulesCard: React.FC<ModulesCardProps> = ({ logo, title, desc, installation, date }) => {
    return (
        <div className={'col-12 col-md-6 col-lg-4'}>
            <div className={styles['modcards__card']}>
                <div className={styles['modcards__card-pic']}>
                    {logo && <img className={'mx-auto d-block'} src={logo.src} alt={logo.alt} />}
                </div>
                <div className={styles['modcards__card-content']}>
                    <div className={styles['modcards__card-title']}>{title}</div>
                    <div className={styles['modcards__card-desc']}>{desc}</div>
                </div>
                <div className={styles['modcards__card-footer']}>
                    {installation && <div dangerouslySetInnerHTML={{ __html: installation }} />}
                    {date && <div dangerouslySetInnerHTML={{ __html: date }} />}
                </div>
            </div>
        </div>
    );
}

const NewModulesCard: React.FC<ModulesCardProps> = ({ desc }) => {
    return (
        <div data-bs-toggle='modal' data-bs-target='#askQuestionModal' className={'col-12 col-md-6 col-lg-4'}>
            <div className={styles['modcards__card-new']}>
                <div className={styles['modcards__card-dash']}>
                    <img className={'mx-auto d-block'} src={ModulesResources.plusIcon} alt={''} />
                    <p>{desc}</p>
                </div>
            </div>
        </div>
    );
}

export default ModulesCards;