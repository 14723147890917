import SignupPNG from './../../assets/signup.png';
import MSVG from './../../assets/icons/m.svg';
import CheckSVG from './../../assets/icons/check.svg';
import ARSVG from './../../assets/icons/ar.svg';

export const SignUpResources = {
    signUpPng: SignupPNG,
    mSvg: MSVG,
    checkSvg: CheckSVG,
    arSvg: ARSVG
}