import { useForm } from "react-hook-form";
import styles from "./restorePassword.module.scss";
import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SignUpIndividualService from "../../services/individual";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

let currentOtpIdx: number = 0;

const OtpCodePage: React.FC = (): JSX.Element => {
  const [otp, setOtp] = useState<string[]>(new Array(4).fill(""));
  const [activeOtpIdx, setActiveOtpIdx] = useState<number>(0);
  const { handleSubmit, setError, register, formState: { errors } } = useForm();
  const inputRef = useRef<HTMLInputElement>(null);
  const locationData = useLocation();
  const navigate = useNavigate();
  console.log("locationData", locationData);
  const { t } = useTranslation();
  let left = locationData.state?.phone.slice(0, 4);
  let right = locationData.state?.phone.slice(9, locationData.state?.phone);
  let final = `${left}****${right}`;

  console.log('errors', errors);

  useEffect(() => {
    window.scrollTo(0, 75);
  }, []);

  useEffect(() => {
    inputRef.current?.focus();
  }, [activeOtpIdx]);

  const handleOnChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = target;
    const newOtp: string[] = [...otp];
    newOtp[currentOtpIdx] = value.substring(value.length - 1);

    if (!value) {
      setActiveOtpIdx(currentOtpIdx - 1);
    } else {
      setActiveOtpIdx(currentOtpIdx + 1);
    }
    setOtp(newOtp);
  };

  const handleOnKeyDown = (
    { key }: React.KeyboardEvent<HTMLInputElement>,
    idx: number
  ) => {
    currentOtpIdx = idx;
    if (key === "Backspace") setActiveOtpIdx(currentOtpIdx - 1);
  };

  const sendVerifyCode = async (e: any) => {
    e.preventDefault();
    const data = await SignUpIndividualService.verifyByPhone({
      phone: "%2B" + locationData.state?.phone.slice(1),
      verifyCode: otp.join(""),
    });
    console.log(data);
    if (data?.status === 200) {
      navigate(`/forgotPassword/otp/newPassword`, {
        replace: true,
        state: { phone: locationData.state?.phone },
      });
    }
    else {
      setError('otp', {
        type: 'wrong'
      })
    }
  };

  const onSubmit = () => { };

  return (
    <div className={styles.otpWrapper}>
      <div className={styles.otpInner}>
        <div className={styles.type}>
          <p className={styles.typecode}>{t("TypeInYourCode")}</p>
        </div>
        <p className={styles.otpTitle}>{t("SendSecCode")} {final}</p>
        <p
          onClick={() => {
            SignUpIndividualService.resendVerification({
              phoneOrEmail: "%2B" + locationData.state?.phone.slice(1),
            });
            toast.success(t('CodeResubmitted'), {
              position: toast.POSITION.TOP_CENTER,
          })
          }
          }
          className={styles.resend}
        >
          {t("Resend")}
        </p>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.otpForm}>
          <div className={styles.otpCodeInputWrapper}>
            {otp.map((_, idx) => {
              return (
                <div key={idx} className={errors.otp?.type === 'wrong' ? styles.otpInputWrapperError : styles.otpInputWrapper}>
                  <input
                    type="number"
                    value={otp[idx]}
                    {...register('otp', { required: true })}
                    className={styles.otpInput}
                    ref={idx === activeOtpIdx ? inputRef : null}
                    onChange={handleOnChange}
                    onKeyDown={(e) => handleOnKeyDown(e, idx)}
                  />
                </div>
              );
            })}
          </div>
          {errors.otp && errors.otp.type === "wrong" && <p className='error_message'>{t("IncConfCode")}</p>}
          <button
            onClick={(e) => sendVerifyCode(e)}
            type="submit"
            className={`${styles.btnCnt} button button-c2c mt-3`}
          >
            {t("Continue")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default OtpCodePage;
