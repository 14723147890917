import React, { useState } from 'react';
import styles from './Verified.module.scss';

const Verified: React.FC = () => {
    const verifiedEntity = () => {
        
    };

    return (
        <div className={styles.verifiedWrapper}>
            <button className='button button-c2c' onClick={() => verifiedEntity()}>VERIFIED</button>
        </div>
    )
}

export default Verified;