import React from 'react';
import { BusinessResources } from '../Business.resources';
import { BusinessApplicationProps } from './BusinessApplication.props';
import styles from '../Business.module.scss';
import { useTranslation } from 'react-i18next';
import AppStore from './../../../assets/apple.svg';

const BusinessApplication: React.FC<BusinessApplicationProps> = () => {
    const { t } = useTranslation();
    return (
        <section id='businessApp' className={styles.fmt}>
            <div className={'container'}>
                <div className={styles['fmt__inner']}>
                    <div className={'row'}>
                        <div className={`col-12 col-lg-6 order-md-2 order-lg-1 ${styles['fmt__left']}`}>
                            <h2 className={'alttitle'}>
                                <img src={BusinessResources.mIcon} alt='' />
                                {t("Functional Mobile App")}
                            </h2>
                            <p>
                                {t("AccessOurSevices")}
                            </p>
                            <h6>{t("Download Mobile App")}</h6>
                            <div style={{ marginTop: "20px" }} className={styles['fmt__apps']}>
                                <a href='https://play.google.com/store/apps/details?id=az.pulpal&hl=ru&gl=US'>
                                    <img src={BusinessResources.googleSvg} alt={'Google Play'} />
                                </a>
                                <a href='https://appgallery.huawei.com/app/C105530163'>
                                    <img src={BusinessResources.appgallerySvg} alt={'App Gallery'} />
                                </a>
                                <a style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '150px', position: 'relative' }}
                                    href='https://apps.apple.com/ru/app/pulpal/id1670623136'>
                                    <img style={{ transform: 'scale(0.5)', position: 'absolute', top: '-20px', left: '-65px' }} src={AppStore} alt='Google Play' />
                                </a>
                            </div>
                        </div>
                        <div className={`col-12 col-lg-6 order-md-1 order-lg-2 text-center ${styles['fmt__right']}`}>
                            <img src={BusinessResources.fmt} alt={'Funksional Mobil Tətbiq'} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default BusinessApplication;