import { bindActionCreators } from 'redux'
import { useDispatch } from 'react-redux'
import { useMemo } from 'react'
import { allActionCreators } from '../../redux/reducers/action-creators'

export const useActions = () => {
    const dispatch = useDispatch()
    return useMemo(() => {
        return bindActionCreators(allActionCreators, dispatch)
    }, [dispatch])
}
