import SignUp from "../SignUp/SignUp"
import ModulesHeader from "./ModulesHeader/ModulesHeader"
import ModulesCards from "./ModulesCards/ModulesCards"
import { useEffect } from 'react';

export const ModulesPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <div>
            <ModulesHeader></ModulesHeader>
            <ModulesCards></ModulesCards>
            <SignUp></SignUp>
        </div>
    )
}