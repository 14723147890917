import './Registration.scss';
import { RegistrationResources } from "./Registration.resources";
import SignUpIndividualService from "../../services/individual";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from 'react';
import SignUpEntityService from '../../services/entity';
import { useForm } from 'react-hook-form';
import { goToMerchant } from '../../utils/utils';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const VerificationEntity = () => {
    const [verifyCode, setVerifyCode] = useState('');
    const phoneNumber = localStorage.getItem('phone');
    const userInfo = useLocation().state;
    const { handleSubmit } = useForm();
    const lang = localStorage.getItem('language');
    console.log('userInfo', userInfo);
    console.log('entity')
    const navigate = useNavigate();
    const { t } = useTranslation();

    const sendVerifyCode = async () => {
        const data = await SignUpIndividualService.verifyByPhone({ verifyCode, phone: '%2B994' + phoneNumber });
        console.log('data sendVerifyCode', data)
        if (data?.data.status === 0) {
            const data = await SignUpEntityService.signInEntity({ phone: '+994' + userInfo.phone, password: userInfo.password });
            console.log('data', data);
            if (data?.status === 200) {
                localStorage.setItem('pulpalAuth', JSON.stringify(data.data));
                const self = await SignUpIndividualService.getSelf();
                console.log('self', self);
                if (self?.status === 200) {
                    const merchant = await SignUpEntityService.createMerchant({
                        contactEmail: '',
                        contactName: self.data.firstName + self.data.lastName,
                        enterprenuer: false,
                        language: lang,
                        name: userInfo.organizationName,
                        userId: self.data.id,
                        website: userInfo.website
                    })
                    console.log('merchant', merchant)
                    if (merchant?.status === 200) {
                        const questionnaire = await SignUpEntityService.createQuestionnaire({
                            phone: '+994' + userInfo.phone,
                            merchantId: merchant.data.id,
                            organizationName: userInfo.organizationName,
                            voen: userInfo.voen,
                            contactName: self.data.firstName + self.data.lastName,
                            userId: self.data.id
                        })
                        // goToMerchant();
                        navigate('/voenSuccess');
                        console.log('questionnaire', questionnaire)
                    }
                    console.log('merchant', merchant)
                }
            }
            // localStorage.setItem('verified', 'verified')
        }
        else {
            console.log('error 1');
        }
    };

    const resendVerification = async () => {
        await SignUpIndividualService.resendVerification({ phoneOrEmail: '%2B994' + phoneNumber });
        toast.success(t('CodeResubmitted'), {
            position: toast.POSITION.TOP_CENTER,
        })
    }
    
    const onSubmit = () => {

    };

    return (
        <main className="main" id="top">
            <div className="container-fluid">
                <div className="row min-vh-100 flex-center g-0 to-center">
                    <div className="col-lg-8 col-xxl-5 py-3 position-relative">
                        <img className="bg-auth-circle-shape" src={RegistrationResources.bgShape} alt="" width="250" />
                        <img className="bg-auth-circle-shape-2" src={RegistrationResources.shape1} alt="" width="150" />
                        <div className="card overflow-hidden z-index-1">
                            <div className="card-body p-0">
                                <div className="row g-0 h-100">
                                    <div className="col-md-5 text-center bg-card-gradient">
                                        <div className="position-relative p-4 pt-md-5 pb-md-7 light">
                                            <div className="bg-holder bg-auth-card-shape bg-half-circle">
                                            </div>
                                            <div className="z-index-1 position-relative"><a className="link-light mb-4 font-sans-serif fs-4 d-inline-block fw-bolder link-pulpal" href="index.html">
                                                <img src={RegistrationResources.pulpalSvg} alt="" width="35" className="pulpalSvg" />PulPal</a>
                                                <p className="opacity-75 text-white text-size">{t("ThePowerOfPulpal")}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7 d-flex flex-center">
                                        <div className="p-4 p-md-5 flex-grow-1">
                                            <div className="text-center text-md-start">
                                                <h4 className="mb-0 check">{this}</h4>
                                                <p className="mb-4 labels-color label-huge">{t("SMS")}</p>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-sm-8 col-md">
                                                    <form onSubmit={handleSubmit(onSubmit)} className="mb-3">
                                                        <input value={verifyCode} onChange={(e) => setVerifyCode(e.target.value)} className="form-control" type="tel" placeholder={`${t("VerificationCode")}`} />
                                                        <div className="mb-3"></div>
                                                        <button onClick={(e) => { sendVerifyCode(); e.preventDefault() }} className="btn btn-primary d-block w-100 mt-3 reg" type="submit" name="submit">{t("Confirm")}</button>
                                                    </form>
                                                    <a onClick={() => resendVerification()} className="fs--1 text-600 text-code">{t("DidntReceiveCode")}<span className="d-inline-block ms-1">&rarr;</span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default VerificationEntity;
