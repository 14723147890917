import "./index.scss";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { imagesForCategories } from "../../imagesForCategories";
import LoaderComponent from "../../components/LoaderComponent/LoaderComponent";
import ServiceSkeleton from "../../components/Skeletons/ServiceSkeleton/index";
import { PulPalServicesLoadingTypes } from "../../redux/reducers/pulPalServices/types";
import { useActions } from "../hooks/useAction";
import ServiceSkeleton975 from "../Skeletons/ServiceSkeleton/ServiceSkeleton975";
import ServiceSkeleton767 from "../Skeletons/ServiceSkeleton/ServiceSkeleton767";
import ServiceSkeleton380 from "../Skeletons/ServiceSkeleton/ServiceSkeleton380";
import loading from "../../assets/Loading_icon.gif";
import { imageService } from "../../http";
import { ServiceLink } from "./ServiceLink";

interface ImageLoadedState {
  [key: number]: string | undefined;
}

const paymentListSkeleton = new Array(6).fill(0);

export const PaymentListPage = () => {
  const { t } = useTranslation();
  const { id: categoryId } = useParams();
  const [categoryColor, setCategoryColor] = useState("");

  const servicesByCategory = useSelector(
    (state: any) => state.pulPalServicesReducer.servicesByCategory
  );
  const categories = useSelector(
    (state: any) => state.pulPalServicesReducer.categories
  );
  const langKey = useSelector((state: any) => state.languageReducer.langKey);
  const subjects = useSelector((state: any) => state.loadingReducer.subjects);
  const categoryIdToNum = Number(categoryId);
  const [imageLoaded, setImageLoaded] = useState<ImageLoadedState>({});

  const paymentImageColor = () => {
    const image = imagesForCategories?.find((image) => image.id === categoryId);
    const iconColor: any = image?.color;
    setCategoryColor(iconColor);
  };

  const { getCategoriesList: getCategoriesListAction } = useActions();

  const { getServicesListByCategory: getServicesListByCategoryAction } =
    useActions();

  const resCat = categories.filter((item: any) => item.id === categoryIdToNum);

  useEffect(() => {
    (async () => {
      await getServicesListByCategoryAction(categoryId);
      await getCategoriesListAction();
      // paymentImageColor()
    })();
  }, [getServicesListByCategoryAction, categoryId, getCategoriesListAction]);

  return (
    <div className="paymentList">
      <div className="card mt-3 paymentListInner">
        <div className="card-header bg-light">
          <nav
            style={
              { "--falcon-breadcrumb-divider": "»" } as React.CSSProperties
            }
            aria-label="breadcrumb"
          >
            <div className="breadcrumb">
              <div className="breadcrumb-item">
                <Link className="payments-link" to="/payments">
                  {t("Payments")}
                </Link>
              </div>
              {/*»*/}
              <div
                className="breadcrumb-item active text-wrapper"
                aria-current="page"
              >
                {resCat.length ? (
                  resCat.map((item: any) => (
                    <span className="item-localename">
                      {item.localeName[langKey]}
                    </span>
                  ))
                ) : (
                  <div className="loader-container">
                    <LoaderComponent />
                  </div>
                )}
              </div>
            </div>
          </nav>
        </div>
        <div className="card-body">
          <div className="row g-3">
            {subjects.has(PulPalServicesLoadingTypes.GET_SERVICES) ? (
              <div className="loading-gif">
                <img src={loading} alt="loading" />
              </div>
            ) : servicesByCategory.length ? (
              servicesByCategory.map((item: any, id: any) => {
                return (
                  <div className="col-xxl-4 col-lg-6 card-item" key={id}>
                    <div
                      className="d-flex align-items-center px-4 py-x1 bg-light rounded-3 border position-relative merchant_card service-card"
                    >
                      {!imageLoaded[item.id] && (
                        <span style={{ transform: 'scale(0.5)', position: 'absolute', left: '40px' }} className="loader"></span>
                      )}
                      <img
                        src={`${imageService}/${item.id}`} 
                        alt={item?.name}
                        width="50"
                        onLoad={() =>
                          setImageLoaded((prevState) => ({
                            ...prevState,
                            [item.id]: "loaded",
                          }))
                        }
                        onError={(event) => {
                          setImageLoaded(prevState => ({ ...prevState, [item.id]: 'error' }));
                          //@ts-ignore
                          event.target.src = 'https://placehold.co/50x50/png';
                        }
                        }
                      />
                      <div className="ms-3 my-x1">
                        <h5 className="fs-0 fw-semi-bold mb-2">
                          <ServiceLink item={item} langKey={langKey}/>
                        </h5>
                        <h6 className="mb-0 text-600">{`min ${item.minAmount / 100
                          } ${item.currency}, max - ${item.maxAmount / 100} ${item.currency
                          }`}</h6>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="not-server loading-gif">{t("categoryEmpty")}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
