import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from "react-google-recaptcha";
import MessageToAdminService from '../../services/messageToAdmin';
import styles from './AskQuestion.module.scss';

const AskQuestionModal: React.FC<any> = () => {
    const { t, i18n } = useTranslation();
    const options = ["General issues", "Cooperation", "Technical issues", "Complaint or suggestion"];
    const [messageOptions, setMessageOptions] = useState({ subject: `${t(options[0])}`, text: '', userName: '', contacts: '', recaptcha: "03AKH6MRHqlvxa5RGu2g7QoCRmvYgQFzF3WlPdaknGhKPEG1Q4ipe8KZVSfVZrkP9tb2S6Rpf8daHTIW0ceCg8Ih2Rkozpc85BSTn2mKzwuahI5H9PjTHa1cH_rdSiAWKMrxPpfzjcUBTOCPi40QOJXvfrJZr8l9_JyZkuOWYIGtwZYW970X1inw9IidkqUthD4MROy2ILrC0O5BK9vZjauLeBylBvXo0fINh836n2Y2g88zuI97LLpS50vC6-wQwEVOrR7uMqbDsPgJG5mzs8KkkhxOGeQ39Kq0eks3Av0gqWzrP-u1S82dqArdKVZVHQ5Oxj9fG2UFcFnFHswGGR5f8PsYXf6MSE9Lma1nN2ufXIn21ih62WB3cuOG2Vj-4wTIbqa1LKgvHWIfW1A6RyxoXpSwZ4aDI7JxZvgPbuShU8uo-g0B5VRamAhabE6U_hz23M8D2Y3iO2DV9w4t3pEfkC0opYGNjPo6iMfz-XZ2S6RwrvzfcDvbQKGEIwtnNeL8YFObW2E3lZPu7l4TyhBeSO1P6cXFqQYg" });
    useEffect(() => {
        setMessageOptions({ ...messageOptions, subject: `${t(options[0])}` })
    }, [t]);

    return (
        <div className={'modal fade'} id={'askQuestionModal'} tabIndex={-1} aria-labelledby={'exampleModalLabel'} aria-hidden={'true'}>
            <div className={'modal-dialog'}>
                <div className={'modal-content'}>
                    <div className={`modal-header`}>
                        <h1 className={'modal-title fs-5'} id={'exampleModalLabel'}>{t("To ask a question")}</h1>
                        <button type={'button'} className={'btn-close'} data-bs-dismiss={'modal'} aria-label={'Close'}></button>
                    </div>
                    <div className={'modal-body'}>
                        <div className='tab-content' id='pills-tabContent'>
                            <div className='tab-pane fade show active' id='pills-home' role='tabpanel' aria-labelledby='pills-home-tab' tabIndex={0}>
                                <form action='#'>
                                    <div className=''>
                                        <div className='form-floating'>
                                            <select
                                                onChange={(e) => setMessageOptions({ ...messageOptions, subject: e.target.value })}
                                                className={`form-select form-select-sm p-2 border border-1 ${styles.text}`}
                                                aria-label='Default select example'
                                                id=""
                                                required
                                            >
                                                {options.map((optionValue, idx) => {
                                                    return (
                                                        <option key={idx}>{t(optionValue)}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        <textarea
                                            onChange={(e) => setMessageOptions({ ...messageOptions, text: e.target.value })}
                                            required
                                            className={`form-control mt-2 mb-2 p-2 ${styles.textarea}`}
                                            id="exampleFormControlTextarea1"
                                            placeholder={`${t("Message or question")}`}
                                        >
                                        </textarea>
                                        <div className='form-floating mb-2'>
                                            <input
                                                onChange={(e) => setMessageOptions({ ...messageOptions, userName: e.target.value })}
                                                required
                                                className='form-control'
                                                id='Name'
                                                placeholder={`${t("Name")}`}
                                            />
                                            <label htmlFor={'Name'}>{t("Name")}</label>
                                        </div>
                                        <div className='form-floating mb-2'>
                                            <input required onChange={(e) => setMessageOptions({ ...messageOptions, contacts: e.target.value })}
                                                className='form-control' id='CommunicationFormat'
                                                placeholder={`${"Contacts for communication (mobile or email)*"}`}
                                            />
                                            <label htmlFor={'CommunicationFormat'}>{t("Contacts for communication (mobile or email)*")}</label>
                                        </div>
                                        {/* <ReCAPTCHA
                                            sitekey="Your client site key"
                                        /> */}
                                        <div className='d-grid'>
                                            <input value={`${t("SEND")}`} onClick={() => MessageToAdminService.messageToAdmin(messageOptions)} type='submit' className='button button-c2c mt-3'></input>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}

export default AskQuestionModal;
