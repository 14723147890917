import React, { useEffect, useRef, useState } from 'react';
import { ClientsProps } from './Clients.props';
import { ClientsSources } from './Clients.sources';
// import dynamic from 'next/dynamic';
import styles from '../Home.module.scss';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
import { useTranslation } from 'react-i18next';

// const OwlCarousel = dynamic(() => import('react-owl-carousel'), { ssr: false });

const Clients: React.FC<ClientsProps> = () => {

    const [isMounted, setIsMounted] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        setIsMounted(true);
    }, []);

    if (!isMounted) {
        return null;
    }

    const options = {
        loop: !0,
        margin: 15,
        autoplay: !0,
        responsive: {
            0: {
                items: 2
            },
            300: {
                items: 2
            },
            500: {
                items: 3
            },
            600: {
                items: 5
            },
            1e3: {
                items: 7
            }
        }
    }

    return (
        <section className={styles.clients} id={'clients'}>
            <div className={'container'}>
                <div className={'section__header'}>
                    <img src={ClientsSources.clientsLogo} alt={'Developers'} />
                    <h2 className={'section__title'}>{t("Our customers")}</h2>
                </div>
                <OwlCarousel autoPlay className={styles.clients__cs + ' owl-carousel'} {...options}>
                    <div className={styles.clients__item}><img src={ClientsSources.logo01} alt='' /></div>
                    <div className={styles.clients__item}><img src={ClientsSources.logo02} alt='' /></div>
                    <div className={styles.clients__item}><img src={ClientsSources.logo03} alt='' /></div>
                    <div className={styles.clients__item}><img src={ClientsSources.logo04} alt='' /></div>
                    <div className={styles.clients__item}><img src={ClientsSources.logo05} alt='' /></div>
                    <div className={styles.clients__item}><img src={ClientsSources.logo06} alt='' /></div>
                    <div className={styles.clients__item}><img src={ClientsSources.logo07} alt='' /></div>
                    <div className={styles.clients__item}><img src={ClientsSources.logo08} alt='' /></div>
                    <div className={styles.clients__item}><img src={ClientsSources.logo09} alt='' /></div>
                    <div className={styles.clients__item}><img src={ClientsSources.logo10} alt='' /></div>
                    <div className={styles.clients__item}><img src={ClientsSources.logo11} alt='' /></div>
                </OwlCarousel>

            </div>
        </section>
    );
}

export default Clients;