import React from 'react';
// import { AboutCardProps } from './aboutModcards.props';
import { AboutCardProps } from '../AboutModcards/AboutModcards.props';
import styles from './../About.module.scss';

const AboutCard: React.FC<AboutCardProps> = ({ image, title, desc }) => {
    return (
        <div className={'col-12 col-md-6 col-lg-4'}>
            <div className={styles['about__card']}>
                <div className={styles['about__card-pic']}>
                    <img className='mx-auto d-block' src={image.src} alt={image.alt} />
                </div>
                <div className={styles['modcards__card-content']}>
                    <div className={styles['modcards__card-title']}>{title}</div>
                    <div className={styles['modcards__card-desc']} dangerouslySetInnerHTML={{__html: desc}} />
                </div>
            </div>
        </div>
    );
}

export default AboutCard;