import { AZERCELL_SERVICE_ID } from './consts'

export const findIdxInArray = (array, id) => {
  return array.findIndex(item => item.id === id)
}

export const createNewItemInArray = (array, newItem, top = 'false') => {
  if (!top) return [...array, newItem]
  return [newItem, ...array]
}

export const updateItemInArray = (array, updatedItem, oldItemIndex) => {
  return [
    ...array.slice(0, oldItemIndex),
    updatedItem,
    ...array.slice(oldItemIndex + 1),
  ]
}

export const deleteItemInArray = (array, deletedItemIndex) => {
  return [
    ...array.slice(0, deletedItemIndex),
    ...array.slice(deletedItemIndex + 1),
  ]
}

export const addZero = num => {
  if (num >= 0 && num <= 9) {
    return '0' + num
  } else {
    return num
  }
}

export const generateRandomString = length => {
  var result = ''
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result + new Date().getTime()
}

export const commission = (amount, percent, minimal) => {
  if (percent === 0) {
    return 0
  }
  var com = Math.ceil(amount * percent) / 100
  if (com < minimal) {
    return minimal
  }
  return com
}

export const isAzercellService = serviceId => {
  return serviceId === AZERCELL_SERVICE_ID
}

export const getServiceIconById = serviceId => {
  return `https://placehold.jp/3d4070/ffff3c/150x150.png?text=${serviceId}`
}

export const getNotServiceItemIcon = merchantId => {
  const merchantIdIcons = {
    '-102': `https://placehold.jp/3d4070/ffff3c/150x150.png?text=${merchantId}`,
    '-100': `https://placehold.jp/3d4070/ffff3c/150x150.png?text=${merchantId}`,
    '-103': `https://placehold.jp/3d4070/ffff3c/150x150.png?text=${merchantId}`,
    510: `https://placehold.jp/3d4070/ffff3c/150x150.png?text=${merchantId}`,
    181: `https://placehold.jp/3d4070/ffff3c/150x150.png?text=${merchantId}`,
  }

  return merchantIdIcons[merchantId]
}
