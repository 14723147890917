import axios from "axios";
import { user, userSignUp, merchantApi } from "../http";
import AuthService from "./auth";

const userSignInIndividual = user + "/signIn";
const verifyByEmail = user + '/verifyByEmail';

export default class SignUpEntityService {
    static async signUpEntity(regOptions: any) {
        try {
            return await axios.post(`${userSignUp}`, regOptions);
        } catch (err) {
            console.log('ERROR SIGNUP Individual')
        }
    };

    static async signInEntity(signInOptions: any) {
        console.log('signInOptions', signInOptions)
        try {
            return await axios.post(`${userSignInIndividual}`, signInOptions)
        }
        catch (err) {
            console.log('ERROR SIGN IN')
        }
    };

    
    static async signInEntityWithEmail(signInOptions: any) {
        console.log('signInOptions', signInOptions)
        try {
            return await axios.post(`${userSignInIndividual}`, { email: signInOptions.phoneOrEmail, password: signInOptions.password})
        }
        catch (err: any) {
            console.log('ERROR SIGN IN')
            return err.response;
        }
    };


    static async signInEntityWithPhone(signInOptions: any) {
        console.log('signInOptions', signInOptions)
        try {
            return await axios.post(`${userSignInIndividual}`, { phone: signInOptions.phoneOrEmail, password: signInOptions.password})
        }
        catch (err: any) {
            console.log('ERROR SIGN IN')
            return err.response;
        }
    };

    static async verifyByEmail(code: string) {
        try {
            const data = await axios.post(`${verifyByEmail}?code=${code}`, {});
            return data;
        }
        catch (err) {
            console.log('ERROR VERIFY BY PHONE');
        }
    }

    static async createMerchant(merchantOptions: any) {
        try {
            return await axios.post(`${merchantApi}/merchants/create`, merchantOptions)
        }
        catch (err) {
            console.log('ERROR create merchant')
        }
    }

    static async createQuestionnaire(questionnaireOptions: any) {
        console.log('questionnaireOptions', questionnaireOptions)
        try {
            return await axios.put(`${merchantApi}/merchants/questionnaire`, {
                apartment: "",
                building: "",
                city: "",
                contactEmail: "",
                contactMobile: questionnaireOptions.phone,
                contactName: questionnaireOptions.contactName,
                contactPosition: "",
                country: "",
                hasNoWebSite: false,
                hasWebSite: true,
                id: questionnaireOptions.merchantId,
                mainActivity: "",
                organizationName: questionnaireOptions.organizationName,
                street: "",
                voen: questionnaireOptions.voen,
                website: questionnaireOptions.website,
            }, {
                headers: {
                    "User-Id": questionnaireOptions.userId,
                    "Merchant-Id": questionnaireOptions.merchantId
                }
            })
        }
        catch (err) {
            console.log('ERROR create questionnaire')
        }
    }
    
    static async getByUserId(){
        return await axios.get(`${merchantApi}/merchants/getByUserId`)
    }

    static async getMerchant(id: any){
        const data =  await axios.get(`${merchantApi}/merchants`, {
            headers: {
                "Merchant-Id": id
            }
        })
        console.log('1234 data', data)
        return data;
    }

    static async getMerchantByUserId(userId: any, merchantId: any){
        const data =  await axios.get(`${merchantApi}/merchants`, {
            headers: {
                "Merchant-Id": merchantId,
                "User-Id": userId
            }
        })
        console.log('1234 data', data)
        return data;
    }
}