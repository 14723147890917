import { Link } from "react-router-dom";
import Modal from "../Modal/Modal";
import { useState } from "react";

export const ServiceLink = ({ item, langKey }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClick = () => {
    localStorage.setItem('redirectToService', `/payment-list/${item.categoryId}/service-pay/${item.id}`)
    setIsModalOpen(true);
  };

  return (
    <>
      {item.isAuth ? 
        <a
          href='#'
          className="text-900 hover-primary stretched-link"
          data-bs-toggle='modal'
          data-bs-target='#exampleModal'
          onClick={handleClick}
        >
          {item.localeName[langKey]}
        </a>
        :
        <Link
          className="text-900 hover-primary stretched-link"
          to={`/service-pay/${item.id}`}
        >
          {item.localeName[langKey]}
        </Link>
      }
      {isModalOpen && <Modal />}
    </>
)};
