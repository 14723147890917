import { RegistrationResources } from "../../Registration/Registration.resources";
import { useState } from "react";
import SignUpEntityService from "../../../services/entity";
import { useLocation, useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const OtpPageEmail = () => {
    const [code, setCode] = useState('');
    const navigate = useNavigate();
    const { t } = useTranslation();
    const userInfo = useLocation().state;
    console.log('userInfo otp email', userInfo);
    const onSubmit = () => { };
    const {
        register,
        handleSubmit,
        setError,
        formState: {
            errors,
        }
    }: any = useForm({ mode: 'onBlur' });


    const sendCode = async () => {
        const data = await SignUpEntityService.verifyByEmail(code);
        console.log('data code', data);
        if (code.length === 36) {
            if (data?.status === 200 && data.data.status === 0) {
                navigate('/forgotPassword/otpEmail/newPasswordByEmail', { state: { email: userInfo.email } })
            }
            else {
                setError('code', {
                    type: 'wrong',
                    message: ''
                })
            }
        }
    }

    console.log('errors', errors)
    return (
        <main className="main" id="top">
            <div className="container-fluid">
                <div className="row min-vh-100 flex-center g-0 to-center">
                    <div className="col-lg-8 col-xxl-5 py-3 position-relative">
                        <img className="bg-auth-circle-shape" src={RegistrationResources.bgShape} alt="" width="250" />
                        <img className="bg-auth-circle-shape-2" src={RegistrationResources.shape1} alt="" width="150" />
                        <div className="card overflow-hidden z-index-1">
                            <div className="card-body p-0">
                                <div className="row g-0 h-100">
                                    <div className="col-md-5 text-center bg-card-gradient">
                                        <div className="position-relative p-4 pt-md-5 pb-md-7 light">
                                            <div className="bg-holder bg-auth-card-shape bg-half-circle">
                                            </div>
                                            <div className="z-index-1 position-relative"><a className="link-light mb-4 font-sans-serif fs-4 d-inline-block fw-bolder link-pulpal" href="index.html">
                                                <img src={RegistrationResources.pulpalSvg} alt="" width="35" className="pulpalSvg" />PulPal</a>
                                                <p className="opacity-75 text-white text-size">{t("ThePowerOfPulpal")}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7 d-flex flex-center">
                                        <div className="p-4 p-md-5 flex-grow-1">
                                            <div className="text-center text-md-start">
                                                <h4 className="mb-0 check">{t("EnterVerificationCode")}</h4>
                                                <p className="mb-4 labels-color label-huge">{t("SecurityCodeEmail")}</p>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-sm-8 col-md">
                                                    <form onSubmit={handleSubmit(onSubmit)} className="mb-3">
                                                        <input
                                                            type="text"
                                                            value={code}
                                                            {...register('code', {
                                                                required: true,
                                                                minLength: {
                                                                    value: 36,
                                                                    message: t("IncConfCode"),
                                                                },
                                                            })}
                                                            onChange={(e) => setCode(e.target.value)}
                                                            className={errors.code ? 'form-control errorInputs' : 'form-control'}
                                                            placeholder={`${t("VerificationCode")}`}
                                                            maxLength={36}
                                                        />
                                                        {errors.code && errors.code.type === 'required' && <p className='error_message'>{t("IncConfCode")}</p>}
                                                        {errors.code && errors.code.type === 'minLength' && <p className='error_message'>{t("MinLength36")}</p>}
                                                        {errors.code && errors.code.type === 'wrong' && <p className='error_message'>{t("IncConfCode")}</p>}
                                                        <button onClick={sendCode} className="btn btn-primary d-block w-100 mt-3 reg" type="submit" name="submit">{t("Continue")}</button>
                                                    </form>
                                                    <a style={{ cursor: 'pointer' }} className="fs--1 text-600 text-code">{t("DidntReceiveCode")}<span className="d-inline-block ms-1">&rarr;</span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default OtpPageEmail;