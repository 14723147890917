import ClientsLogo from './../../../assets/icons/clients.svg';
import Logo01 from './../../../assets/logos/01.jpg';
import Logo02 from './../../../assets/logos/02.jpg';
import Logo03 from './../../../assets/logos/03.jpg';
import Logo04 from './../../../assets/logos/04.jpg';
import Logo05 from './../../../assets/logos/05.jpg';
import Logo06 from './../../../assets/logos/06.jpg';
import Logo07 from './../../../assets/logos/07.jpg';
import Logo08 from './../../../assets/logos/08.jpg';
import Logo09 from './../../../assets/logos/09.jpg';
import Logo10 from './../../../assets/logos/10.jpg';
import Logo11 from './../../../assets/logos/11.jpg';

export const ClientsSources = {
    clientsLogo: ClientsLogo,
    logo01: Logo01,
    logo02: Logo02,
    logo03: Logo03,
    logo04: Logo04,
    logo05: Logo05,
    logo06: Logo06,
    logo07: Logo07,
    logo08: Logo08,
    logo09: Logo09,
    logo10: Logo10,
    logo11: Logo11
}