import { Divider } from "primereact/divider";
import { useTranslation } from "react-i18next";

export const PassHeader = () => {
  const { t } = useTranslation();
  return <div className="font-bold mb-3">{t("PickPassword")}</div>;
};

export const PassFooter = () => {
  const { t } = useTranslation();
  return (
    <>
      {/* <Divider /> */}
      {/* <p className="mt-2">{t("Suggestions")}</p> */}
      <ul
        style={{ marginLeft: "20px", marginTop: '10px', lineHeight: 1.2 }}
        // className="pl-2 ml-2 mt-0 line-height-3 pl-5"
        className="focus-effect"
      >
        <li>{t("OneLowercase")}</li>
        <li>{t("OneUppercase")}</li>
        <li>{t("OneNumeric")}</li>
        <li>{t("OneSpecialSymbol")}</li>
        <li>{t("Minimum")}</li>
      </ul>
    </>
  );
};

export const header = <div className="font-bold mb-3">Pick a password</div>;
export const footer = (
  <>
    <Divider />
    <p className="mt-2">Suggestions</p>
    <ul
      style={{ marginLeft: '20px' }} className="pl-2 ml-2 mt-0 line-height-1 pl-5"
    >
      <li>At least one lowercase</li>
      <li>At least one uppercase</li>
      <li>At least one numeric</li>
      <li>Minimum 8 characters</li>
    </ul>
  </>
);
