const PulPalServicesActionTypes = {
  SET_CATEGORIES: 'SET_CATEGORIES',
  SET_SERVICES: 'SET_SERVICES',
  SET_SELECTED_SERVICES: 'SET_SELECTED_SERVICES',
  SET_SERVICE_LIST: 'SET_SERVICE_LIST',
  SET_SERVICE_LIST_ALL: 'SET_SERVICE_LIST_ALL',
  SET_ERROR: 'SET_ERROR',
}

export const PulPalServicesLoadingTypes = {
  GET_CATEGORIES: 'GET_CATEGORIES',
  GET_SERVICES: 'GET_SERVICES',
  GET_SELECTED_SERVICES: 'GET_SELECTED_SERVICES',
  GET_SERVICE_LIST: 'GET_SERVICE_LIST',
  GET_SERVICE_LIST_ALL: 'GET_SERVICE_LIST_ALL'
}

export default PulPalServicesActionTypes
