import SignUp from "../SignUp/SignUp"
import { useAppSelector } from "../hooks/redux";
import AboutModcards from "./AboutModcards/AboutModcards"
import AboutSlider from "./AboutSlider/AboutSlider"
import { useEffect } from 'react';

export const AboutPage = () => {
    const authorized: boolean = useAppSelector((state: any) => state.helperReducer.authorized);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <AboutSlider />
            <AboutModcards />
            <SignUp />
        </div>
    )
}