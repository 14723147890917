import LoadingActionTypes from './types'

const initialState = {
  subjects: new Set(),
}

const loadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case LoadingActionTypes.LOADING_START: {
      return { ...state, ...{ subjects: state.subjects.add(action.payload) } }
    }
    case LoadingActionTypes.LOADING_END: {
      const subjects = state.subjects
      subjects.delete(action.payload)
      return { ...state, ...{ subjects } }
    }
    default:
      return state
  }
}

export default loadingReducer
