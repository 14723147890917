import '../Registration/Registration.scss';
import { RegistrationResources } from '../Registration/Registration.resources';
import SignUpIndividualService from "../../services/individual";
import { useNavigate } from "react-router-dom";
import { useState } from 'react';

const ForgotPassword = () => {
    const [verifyCode, setVerifyCode] = useState('');
    const navigate = useNavigate();

    const [newPass, setNewPass] = useState('');
    const [phoneNumber, setphoneNumber] = useState('');

    const restorePasswordFunc = async () => {
        const res = await SignUpIndividualService.restorePassword(phoneNumber)
        if (res?.status === 200) {
            localStorage.setItem('operation', res?.data.operation);
            // const data = await SignUpIndividualService.verifyByPhone({});
            console.log('restore password DATA')
        }
    };

    const verifyByPhoneFunc = async () => {
        const data = await SignUpIndividualService.verifyByPhone({ phone: '%2B' + phoneNumber.slice(1), verifyCode: verifyCode });
        console.log('verify DATA', data)
    };

    const setNewPassword = async () => {
        const data = await SignUpIndividualService.restorePasswordChange({ phone: phoneNumber, password: newPass, operation: localStorage.getItem('operation') });
        console.log('restore change password DATA', data)
    };

    return (
        <main className="main" id="top">
            <div className="container-fluid">
                <div className="row min-vh-100 flex-center g-0 to-center">
                    <div className="col-lg-8 col-xxl-5 py-3 position-relative">
                        <img className="bg-auth-circle-shape" src={RegistrationResources.bgShape} alt="" width="250" />
                        <img className="bg-auth-circle-shape-2" src={RegistrationResources.shape1} alt="" width="150" />
                        <div className="card overflow-hidden z-index-1">
                            <div className="card-body p-0">
                                <div className="row g-0 h-100">
                                    <div className="col-md-5 text-center bg-card-gradient">
                                        <div className="position-relative p-4 pt-md-5 pb-md-7 light">
                                            <div className="bg-holder bg-auth-card-shape bg-half-circle">
                                            </div>
                                            <div className="z-index-1 position-relative"><a className="link-light mb-4 font-sans-serif fs-4 d-inline-block fw-bolder link-pulpal" href="index.html">
                                                <img src={RegistrationResources.pulpalSvg} alt="" width="35" className="pulpalSvg" />PulPal</a>
                                                <p className="opacity-75 text-white text-size">With the power of PulPal, you can now focus only on functionaries for your digital products, while leaving the UI design on us!</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7 d-flex flex-center">
                                        <div className="p-4 p-md-5 flex-grow-1">
                                            <div className="text-center text-md-start">
                                                <h4 className="mb-0 check">Yoxlama kodunu daxil edin</h4>
                                                <p className="mb-4 labels-color label-huge">Qeyd etdiyiniz telefon nömrəsinə SMS göndərildi.</p>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-sm-8 col-md">
                                                    <h1>restore password</h1>
                                                    <form className="mb-3">
                                                        <input value={phoneNumber} onChange={(e) => setphoneNumber(e.target.value)} className="form-control" type="tel" placeholder="Phone Number" />
                                                        <div className="mb-3"></div>
                                                        <button onClick={(e) => {
                                                            e.preventDefault();
                                                            restorePasswordFunc()
                                                        }} className="btn btn-primary d-block w-100 mt-3 reg" type="submit" name="submit">Göndər</button>
                                                    </form>

                                                    <h1>verifyByPhone</h1>
                                                    <form action="">
                                                        <input placeholder='code' onChange={(e) => setVerifyCode(e.target.value)} />
                                                        <input placeholder='phone' type="text" onChange={(e) => setphoneNumber(e.target.value)} />
                                                        <button onClick={(e) => {
                                                            e.preventDefault();
                                                            verifyByPhoneFunc();
                                                        }}>verifyByPhone</button>
                                                    </form>

                                                    <h1>restore change password</h1>
                                                    <form className="mb-3">
                                                        <input onChange={(e) => setNewPass(e.target.value)} type="text" />
                                                        <button onClick={(e) => {
                                                            e.preventDefault();
                                                            setNewPassword()
                                                        }}>Send new password</button>
                                                    </form>

                                                    {/* <a onClick={() => SignUpIndividualService.resendVerification({ phoneOrEmail: '%2B' + phoneNumber })} className="fs--1 text-600 text-code" href="#!">Kod gəlmədi?<span className="d-inline-block ms-1">&rarr;</span></a> */}
                                                </div>
                                                <div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default ForgotPassword;