import './Registration.scss';
import { Controller, useForm } from "react-hook-form";
import moment from "moment-timezone";
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import { RegistrationResources } from './Registration.resources';
import SignUpIndividualService from "../../services/individual";
import { useTranslation } from 'react-i18next';
import { Password } from 'primereact/password';
import { PassFooter, PassHeader } from '../passwordInputs/passwordInputs';

const RegistrationIndividual: React.FC = () => {
    const { t } = useTranslation();
    const [confirmPassword, setConfirmPassword] = useState('');
    const [agree, setAgree] = useState(false);
    const [regOptions, setRegOptions] = useState({
        firstName: "",
        lastName: "",
        email: null,
        password: "",
        phone: "",
        language: localStorage.getItem('language'),
        timezone: moment.tz.guess()
    });
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const {
        register,
        control,
        handleSubmit,
        setError,
        formState: {
            errors,
        }
    }: any = useForm({ mode: 'all' });

    useEffect(() => {
        window.scrollTo(50, 50)
    }, []);

    const handlePasswordCheck = () => {
        if (regOptions.password === confirmPassword) {
            return true;
        } else {
            setError('password', {
                type: 'confirm',
                message: ''
            })
            return false
        }
    };

    const handleChangePhoneNumber = (e: any) => {
        const regex = /^[0-9\b]+$/;
        if (e.target.value === "" || regex.test(e.target.value)) {
            setRegOptions({ ...regOptions, phone: e.target.value });
        }
    };

    console.log('errors', errors);

    const signUpPhysical = async () => {
        const phoneNumberRegex = /^(50|51|55|70|77|99|10)[2-9][0-9]{6}$/;
        const inputPhoneNumber = regOptions.phone;
        const isValidPhoneNumber = phoneNumberRegex.test(inputPhoneNumber);

        const regexPass = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
        const passwordInput = regOptions.password;
        const isValidPassword = regexPass.test(passwordInput);
        if (
            (isValidPhoneNumber && isValidPassword) &&
            regOptions.firstName &&
            regOptions.lastName &&
            regOptions.phone &&
            agree &&
            Object.keys(errors).length === 0 &&
            handlePasswordCheck()
        ) {
            setIsLoading(true);
            const data = await SignUpIndividualService.signUpIndividual({ ...regOptions, phone: '+994' + regOptions.phone });
            console.log(data);
            if (data?.status === 200 || data?.status === 204) {
                navigate(`verification?phone=${'+994' + regOptions.phone}&subject=$phone`, { replace: true, state: { phone: '994' + regOptions.phone, password: regOptions.password } });
                localStorage.setItem('phone', regOptions.phone);
            }
            else {
                setError('phone', {
                    type: 'server',
                    message: 'ThisPhoneIsAlreadyUsed'
                })
            }
            setIsLoading(false);
        }
    };

    const checkValidFirstName = (e: any) => {
        const onlyLetters = /^[a-zA-Zа-яА-Я]+$/;
        if (regOptions.firstName || onlyLetters.test(e.target.value)) {
            setRegOptions({ ...regOptions, firstName: e.target.value })
        }
    };

    const checkValidLastName = (e: any) => {
        const onlyLetters = /^[a-zA-Zа-яА-Я]+$/;
        if (regOptions.lastName || onlyLetters.test(e.target.value)) {
            setRegOptions({ ...regOptions, lastName: e.target.value })
        }
    };

    const onSubmit = () => {
        console.log('onSubmit');
        handlePasswordCheck();
    };

    return (
        <main className="main" id="top">
            <div className="container-fluid">
                <div className="row min-vh-100 flex-center g-0 to-center">
                    <div className="col-lg-8 col-xxl-5 py-3 position-relative">
                        <img className="bg-auth-circle-shape"
                            src={RegistrationResources.bgShape} alt="" width="250" />
                        <img className="bg-auth-circle-shape-2" src={RegistrationResources.shape1} alt="" width="150" />
                        <div className="card overflow-hidden z-index-1">
                            <div className="card-body p-0">
                                <div className="row g-0 h-100">
                                    <div className="col-md-5 text-center bg-card-gradient">
                                        <div className="position-relative p-4 pt-md-5 pb-md-7 light">
                                            <div className="bg-holder bg-auth-card-shape bg-half-circle">
                                            </div>
                                            <div className="z-index-1 position-relative">
                                                <a className="link-light mb-4 font-sans-serif fs-4 d-inline-block fw-bolder link-pulpal" href="index.html">
                                                    <img src={RegistrationResources.pulpalSvg} alt="" width="35" className="pulpalSvg" />PulPal
                                                </a>
                                                <p className="opacity-75 text-white">{t("ThePowerOfPulpal")}</p>
                                            </div>
                                        </div>
                                        <div className="mt-3 mb-4 mt-md-4 mb-md-5 light">
                                            <p className="pt-3 text-white">{t("AlreadyUser")}<br /><button data-bs-toggle='modal' data-bs-target='#exampleModal' className="btn btn-outline-light mt-2 px-4 btn-hover">{t("Enter")}</button></p>
                                        </div>
                                    </div>
                                    <div className="col-md-7 d-flex flex-center">
                                        <div className="p-4 p-md-5 flex-grow-1 regForm">
                                            <h3 className="reg-label">{t("Registration")}</h3>
                                            <form onSubmit={handleSubmit(onSubmit)} action="#">
                                                <div className="row gx-2">
                                                    <div className="mb-3 col-sm-6">
                                                        <label className="form-label labels-color" htmlFor="card-name">{t("Firstname")}</label>
                                                        <input
                                                            className={errors.firstName ? "form-control inputsSpecial errorInputs" : "form-control"}
                                                            value={regOptions.firstName}
                                                            {...register('firstName', { required: true, minLength: 3, pattern: { value: /^[a-zA-Zа-яА-Я]+$/ } })}
                                                            onChange={(e) => checkValidFirstName(e)}
                                                            type="text"
                                                            id="card-name"
                                                        />
                                                        {errors.firstName && errors.firstName.type === "required" && <p className='error_message'>{t("RequiredField")}</p>}
                                                        {errors.firstName && errors.firstName.type === "minLength" && <p className='error_message'>{t("MinThreeSymbols")}</p>}
                                                        {errors.firstName && errors.firstName.type === "pattern" && <p className='error_message'>{t("OnlyLetters")}</p>}
                                                    </div>
                                                    <div className="mb-3 col-sm-6">
                                                        <label className="form-label labels-color" htmlFor="card-name">{t("Lastname")}</label>
                                                        <input
                                                            className={errors.lastName ? "form-control inputsSpecial errorInputs" : "form-control"}
                                                            value={regOptions.lastName}
                                                            {...register('lastName', { required: true, minLength: 3, pattern: { value: /^[a-zA-Zа-яА-Я]+$/ } })}
                                                            onChange={(e) => checkValidLastName(e)}
                                                            type="text"
                                                            id="card-name"
                                                        />
                                                        {errors.lastName && errors.lastName.type === "required" && <p className='error_message'>{t("RequiredField")}</p>}
                                                        {errors.lastName && errors.lastName.type === "minLength" && <p className='error_message'>{t("MinThreeSymbols")}</p>}
                                                        {errors.lastName && errors.lastName.type === "pattern" && <p className='error_message'>{t("OnlyLetters")}</p>}
                                                    </div>
                                                </div>
                                                <div className="mb-3 phone-input-wrapper">
                                                    <span className='phone-input-prefix'>+994</span>
                                                    <label className="form-label labels-color" htmlFor="card-tel">{t("PhoneNumber")}</label>
                                                    <input
                                                        value={regOptions.phone}
                                                        {...register('phone', {
                                                            required: 'Incorrect phone number',
                                                            pattern: { value: /^(50|51|55|70|77|99|10)[2-9][0-9]{6}$/, message: 'Incorrect phone number' }
                                                        })}
                                                        className={errors.phone ? "form-control inputsSpecial errorInputs phone-input" : "form-control phone-input"}
                                                        type="tel"
                                                        onChange={(e) => handleChangePhoneNumber(e)}
                                                        maxLength={9}
                                                        id="card-tel"
                                                    />
                                                    {errors.phone && errors.phone.type === "minLength" && <p className='error_message'>{t("IncorrectPhoneNumber")}</p>}
                                                    {errors.phone && errors.phone.type === "required" && <p className='error_message'>{t("RequiredField")}</p>}
                                                    {errors.phone && errors.phone.type === "pattern" && <p className='error_message'>{t("IncorrectPhoneNumber")}</p>}
                                                    {errors.phone && errors.phone.type === 'server' && <p className='error_message'>{t("ThisPhoneIsAlreadyUsed")}</p>}

                                                </div>
                                                <div className="row gx-2">
                                                    {/* <div className="mb-3 col-sm-6">
                                                        <label className="form-label labels-color" htmlFor="card-password">{t("Password")}</label>
                                                        <input className="form-control" type="password"
                                                            // autocomplete="on"
                                                            {...register('password', {
                                                                required: 'Incorrect password',
                                                                pattern: { value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/, message: 'Incorrect password number' }
                                                            })}
                                                            onChange={(e) => setRegOptions({ ...regOptions, password: e.target.value })}
                                                            id="card-password" />
                                                    </div> */}
                                                    <div className='mb-3 col-sm-6 wrap'>
                                                        <label className="form-label labels-color">{t("Password")}</label>
                                                        <Controller
                                                            control={control}
                                                            name='password'
                                                            rules={{ required: true }}
                                                            render={({ field }) => (
                                                                <Password
                                                                    className={errors.password ? "p-invalid" : ""}
                                                                    value={field.value}
                                                                    {...register('password', {
                                                                        required: true,
                                                                        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&]).{8,}$/
                                                                    })}
                                                                    onChange={(e) => {
                                                                        field.onChange(e.target.value);
                                                                        setRegOptions({ ...regOptions, password: e.target.value });
                                                                    }}
                                                                    toggleMask
                                                                    header={PassHeader}
                                                                    footer={PassFooter}
                                                                />
                                                            )}
                                                        />
                                                        {errors.password && errors.password.type === "required" && <p className='error_message'>{t("RequiredField")}</p>}
                                                        {errors.password && errors.password.type === 'confirm' && <p className='error_message'>{t("PasswordMismatch")}</p>}
                                                    </div>
                                                    <div className='mb-3 col-sm-6 wrap'>
                                                        <label className="form-label labels-color">{t("RepeatPassword")}</label>

                                                        <Controller
                                                            control={control}
                                                            name='repeatPassword'
                                                            rules={{ required: true }}
                                                            render={({ field }) => (
                                                                <Password
                                                                    className={errors.password ? "p-invalid" : ""}
                                                                    value={field.value}
                                                                    {...register('password', {
                                                                        required: true,
                                                                        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&]).{8,}$/
                                                                    })}
                                                                    onChange={(e) => {
                                                                        field.onChange(e.target.value);
                                                                        setConfirmPassword(e.target.value)
                                                                    }}
                                                                    toggleMask
                                                                    header={PassHeader}
                                                                    footer={PassFooter}
                                                                />
                                                            )}
                                                        />
                                                        {errors.password && errors.password.type === "required" && <p className='error_message'>{t("RequiredField")}</p>}
                                                    </div>
                                                </div>
                                                <div className="form-check userRules">
                                                    <input className="form-check-input checkbox-color" type="checkbox" id="card-register-checkbox"
                                                        checked={agree}
                                                        {...register('check', {
                                                            required: `${t('ImportantToAccept')}`,
                                                        })}
                                                        onChange={(e) => setAgree(prev => !prev)}
                                                    />
                                                    <label className="form-label labels-color" htmlFor="card-register-checkbox">
                                                        {localStorage.getItem('language') === 'az' ? <>
                                                            <a className="user-rules" href="/agreement-policy" target='_blank'>İstifadəçi qaydaları</a> və <a className="user-rules" href="/privacy-policy" target='_blank'>Məxfilik siyasəti</a> <span>ilə razıyam</span>
                                                        </>
                                                            : <><span>{t("IagreeWith")}</span> <a className="user-rules" href="/agreement-policy" target='_blank'>{t("WithUserRules")}</a> {t("and")} <a className="user-rules" href="/privacy-policy" target='_blank'>{t("WithPrivacyPolicy")}</a></>}
                                                    </label>
                                                    {errors.check && errors.check.type === "required" && <p className='error_message'>{errors.check.message}</p>}
                                                </div>
                                                <div className="mb-3">
                                                    <button
                                                        className="btn btn-primary d-block w-100 mt-3 falcon-primary reg btnCnt"
                                                        type="submit"
                                                        name="submit"
                                                        onClick={signUpPhysical}
                                                        disabled={isLoading}
                                                    >
                                                        {isLoading ?  <i className="pi pi-spin pi-spinner" style={{ fontSize: '1.2rem' }}></i> : `${t("Registration")}`}
                                                    </button>
                                                </div>
                                            </form>
                                            {/* <div className="position-relative mt-4">
                                                <div className="divider-content-center labels-color soc-net-block">
                                                    <div className='hr'></div>
                                                    <p className='hr-text'>{t("OrSocNetworks")}</p>
                                                </div>
                                            </div>
                                            <div className="row g-2 mt-2">
                                                <div className="col-sm-6"><a style={{ fontWeight: '600' }} className="btn btn-outline-google-plus btn-sm d-block w-100" href="#">
                                                    <span style={{ scale: '1.4' }} className="fab fa-google-plus-g me-2" data-fa-transform="grow-8"></span>
                                                    google</a></div>

                                                <div className="col-sm-6"><a style={{ fontWeight: '600' }} className="btn btn-outline-facebook btn-sm d-block w-100" href="#">
                                                    <span style={{ scale: '1.4' }} className="fab fa-facebook-square me-2" data-fa-transform="grow-8"></span>
                                                    facebook</a></div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default RegistrationIndividual;