import React from 'react';
import './AgreementPolicy.scss';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const AgreementPolicy: React.FC<any> = () => {
  const { language } = useSelector((state: any) => state.languageReducer);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const setAgreementPolicy = () => {

    switch (language) {
      case 'az': return (
        <div className='footer-modal'>
          <div className='footer-modal__header'>
            <h4>PulPal xidmətinin ümumi şərtləri barədə razılaşma</h4>
          </div>
          <div className='footer-modal__content'>
            <ol>
              <li>
                PulPal MMC (daha sonra – “PulPal”) internet şəbəkə
                istifadəçisinə (daha sonra – “Müştəri”)- öz xidmətlərindən
                aşağıda verilmiş razılaşmada (daha sonra -
                “Razılaşma”)göstərildiyi kimi istifadəni təmin edir. Razılaşma
                Müştərinin razılğı əsasında Razılaşmanın 3-cü bəndində
                göstərildiyi kimi qüvvəyə minir.
              </li>
              <li>
                PulPal Müştərilərin ortaq banklarla pul köçürülmələrinin
                həyata keçirilməsi, habelə bank kartlarının, terminal
                şəbəkələrinin istifadəsi üçün göstərişlərin verilməsini təklif
                edir. Hal-hazırda mövcud olan bütün xidmətlər, habelə onların
                inkişafı və yeni xidmət sahələrinin əlavə olunması mövcud
                razılaşmanın predmeti hesab olunur.
              </li>
              <li>
                PulPal xidmətlərindən istifadə mövcud razılaşmanın məxfilik
                siyasəti (PulPal-a keçid ), həmçinin ayrıca xidmətlərin
                istifadəsilə tənzimlənir. Hər hansı bir xidmətdən (və ya onun
                ayrıca funksiyası) istifadə edən və ya qeydiyyatdan keçən
                Müştəri Razılaşmanın şərtlərini istisnasız və qeyri-şərtsiz
                tam həcmdə qəbul etmiş sayılır. PulPal Müştəriyə mövcud
                razılaşmaya dəyişikliklər və əlavələr edilməsini Razılaşmanın
                yeni redaktəsində aşağıdakı internet ünvanında (PulPal-a keçid
                ) təklif edir. Müştərinin belə təklifləri qəbul etməsi ona
                PulPal-ın istənilən xidmətlərindən yeni şərtlərlə istifadəsinə
                şərait yaradır.
              </li>
              <li>
                Müştəridə Razılaşmada edilən dəyişikliklərlə əlaqədar yaranan
                fikir ayrılığı səbəbilə Razılaşmanın 3-cü bəndinə əsasən
                PulPal xidmətlərindən istifadəni dayandırmalıdır.
              </li>
              <li>
                PulPal-ın bəzi xidmətləri və ya xidmətlərin bəzi
                funksiyalarından istifadə etmək üçün Müştəri qeydiyyatdan
                keçmə prosedurunu yerinə yetirməli və bundan sonra Müştəri
                üçün bənzərsiz hesab nümunəsi açılmalıdır.
              </li>
              <li>
                Qeydiyyat üçün Müştəri verilən suallar vasitəsilə özü barədə
                qeydiyyat formasında təqdim olunan dəqiq və dolğun məlumatı
                qeyd etməli və bu məlumatı aktual vəziyyətdə saxlamalıdır.
                Əgər Müştəri doğru olmayan informasiya təqdim edirsə və ya
                PulPal-ın bu məlumatların natamamlığını və ya etibarsızlığını
                güman etmək üçün səbəblər varsa, PulPal-ın Müştərinin
                xidmətlərdən (və ya onların xüsusi funksiyalarından)
                istifadədən imtina hüququ vardır.
                <ol>
                  <li>
                    Xidmətlərdən istifadə zamanı Müştərinin təqdim etdiyi
                    şəxsi məlumatlar cari razılaşmadakı məxfilik siyasətinə
                    uyğun şəkildə qorunub saxlanılır.
                  </li>
                  <li>
                    Əgər PulPal-ın xüsusi xidmətləri qeydiyyat tələb edirsə,
                    bu halda Müştəri özünə sərbəst şəkildə istifadəçi adı (
                    Müştərinin bənzərsiz simvollu istifadəçi adı) və şifrə
                    seçə bilir. PulPal müəyyən olunmuş istifadəçi adlarından
                    istifadəyə qadağa qoya, həmçinin istifadəçinin adı və
                    şifrə ilə əlaqədar (şifrənin uzunluğu, etibarlı simvollar
                    və s.) tələblər irəli sürə bilər.
                  </li>
                  <li>
                    Müştəri bəzi xidmətlər üçün istifadəçi adına alternativ
                    identifikasiya vasitələrindən əvvəlki şifrəni dəyişərək
                    istifadə edə bilər. Bu vasitəyə giriş Müştərinin
                    istifadəçi adının idarə olunması interfeysində ikifaktorlu
                    identifikasiya vasitəsilə təqdim olunur.
                  </li>
                  <li>
                    Müştəri onun tərəfindən seçilmiş istifadəçi adı və
                    şifrələrin təhlükəsizliyi (seçim müqaviməti) üçün birbaşa
                    məsuliyyət daşıyır, habelə onların gizliliyini şəxsən
                    təmin edir. Müştəri müstəqil şəkildə PulPal xidmətlərindən
                    hər hansı fəaliyyət, habelə onun nəticələri ilə əlaqədar
                    məsuliyyət daşıyır. Buraya həmçinin Müştərinin könüllü
                    şəkildə üçüncü şəxslərə məlumatların ötürülməsi (həmçinin
                    , razılaşma və müqavilə şərtlərinə əsasən) daxildir.
                    Bununla yanaşı, PulPal xidmətlərindən istifadə
                    çərçivəsində Müştərinin istifadəçi adı daxil olmaqla bütün
                    məlumatı Müştəri tərəfindən yaradılmış hesab olunur.
                  </li>
                </ol>
              </li>
              <li>
                Əgər müəyyən olunmuş xidmət Müştərinin qeydiyyatını təmin
                etmirsə, PulPal Müştərini internet istifadəçisi adı altında ,
                o cümlədən Müştəri vasitəsilə öncədən PulPal xidmətlərindən
                istifadə zamanı elektron poçtu və telefon nömrəsi vasitəsilə
                identifikasiya edir.
              </li>
              <li>
                PulPal xidmətlərindən istənilən məlumatı daxil edən Müştəri bu
                məlumatların yadda saxlanılmasına və PulPal-ın digər
                xidmətlərindən istifadəyə əlavə təstiq tələb etmədən razılıq
                verir.
              </li>
              <li>
                Müştəri dərhal onun məlumatlarından razılığı olmadan PulPal
                xidmətlərindən istifadə olunmasını, habelə Müştərinin
                istifadəçi adı ilə bağlı istənilən pozuntuların (şübhələrin
                olduğu halda) olduğunu PulPal-a bildirməyə borcludur.
                Təhlükəsizlik baxımından Müştəri hər PulPal xidmətlərindən
                istifadə başa çatdıqdan sonra öz istifadəçi adı altında işi
                təhlükəsiz şəkildə (“çıxış” düyməsi) həyata keçirməlidir.
              </li>
              <li>
                PulPal bütün müştəriləri üçün xidmətlərin istifadəsi və ya
                ayrıca müştəri təbəqəsi üçün (Müştərinin olduğu yer, xidmətin
                təklif olunduğu dil);bununla yanaşı,köçürmələrin
                növü,ölçüsü,təyinat üsulu,köçürmələri alanların siyahısı və s.
                əlaqədar) məhdudiyyətlər tətbiq etmək hüququndadır. PulPal
                şəbəkəyə bağlanma, məlumatın alınması kimi avtomatik idarə
                olunan işlərə qadağa qoya bilər.
              </li>
              <li>
                PulPal Müştərilərinə məlumat xarakterli ismarıclar göndərmək
                hüququndadır. PulPal xidmətlərindən istifadə edən hər bir
                Müştəri Azərbaycan Respublikasının Reklam haqqında Qanununa
                əsasən , reklam xarakterli ismarıcların alınmasına razılıq
                verir. Müştəri reklam xarakterli ismarıcların alınmasına
                müvafiq funksional xidmət vasitəsilə reklamın daxil olduğu
                xidmətlər çərçivəsində və ya Müştəriləri dəstək xidməti
                vasitəsilə imtina etmək hüququ vardır.
              </li>
              <li>
                Müştəri PulPal xidmətlərinə daxil etdiyi məzmuna uyğun mövcud
                qanunvericiliyə əsasən məsuliyyət daşıyır. Buraya, həmçinin bu
                və ya digər məzmunun üçüncü şəxslərin hüquq və qanuni
                maraqlarının pozulması , həmçinin müəlliflərin şəxsi
                qeyri-maddi hüquqlarının pozulması,intelektual hüquqların və
                qeyri-maddi mallar da aiddir.
              </li>
              <li>
                Müştəri razılaşır və qəbul edir ki, PulPal onun Müştəri
                tərəfindən hər hansı xidmət sahəsində yerləşdirdiyi məlumat
                üçün məsuliyyət daşımır. Həmçinin PulPal öz təşəbüsü ilə
                Müştəriyə hər hansı məzmunun yerləşdirilməsi vəya silinməsi
                barədə imtina etmək hüququ (vəzifə yox) var. Müştəri anlayır
                və razıdır ki, o, müstəqil şəkildə məzmunun istifadəsilə
                əlaqədar (buraya etibarlılığın qiymətləndirilməsi, bütövlük və
                yararlılıq aiddir) bütün risk faktorlarını nəzərə almalıdır.
              </li>
              <li>
                Müştəri anlayır və razıdır ki, xidmət servisi texnologiyaları
                məzmunun kopyalanması (çoxaldılması) tələb oluna bilər,
                həmçinin bu və ya digər xidmətin texniki tələbatına görə
                elektron poçt ünvünı tələb oluna bilər.
              </li>
              <li>
                Müştəri PulPal xidmətlərindən istifadə zamanı bu fəaliyyət
                növü üzrə üçüncü şəxslərin qanuni maraq və hüquqlarının
                pozulmasına gətirib çıxarırsa, öz fəaliyyəti üçün üçüncü
                şəxslər qarşısında məsuliyyət daşıyır.
              </li>
              <li>
                PulPal xidmətlərindən istifadə zamanı Müştərinin aşağıdakılara
                hüququ yoxdur:
                <ol>
                  <li>
                    Üçüncü şəxslərin, həmçinin yetkinlik yaşına çatmamış
                    şəxslərin hüquqlarının pozulması,habelə onlara hər hansı
                    formada zərər yetirilməsi;
                  </li>
                  <li>
                    Özünü başqa şəxs kimi (kifayət qədər hüququ olmayan
                    nümayəndə və ya təşkilat kimi), həmçinin PulPal
                    əməkdaşı,forum moderatoru,sayt sahibi, PulPal şəriklərinin
                    əməkdaşı kimi təqdim etmək, şəbəkədə digər şəxslərlə
                    əməkdaşlığın qeyri-qanuni forma vəüsullarını təqdim etmək,
                    həmçinin PULPAL istifadəçilərini daxil etmək, hər hansı
                    subyekt və obyektin xarakterinə uyğun olaraq pul təklif
                    etmək;
                  </li>
                  <li>
                    Başqalarının icazəli məlumatlarını, elektron ödəmə
                    üsullarından istifadə;
                  </li>
                  <li>
                    Digər şəxslərin şəxsi məlumatlarını toplamaq və saxlamaq;
                  </li>
                  <li>
                    PulPal xidmətləri və veb saytların normal işini pozmaq;
                  </li>
                  <li>
                    Razılaşmada göstərilən qadağa və məhdudiyyətlərin
                    pozulmasına yönəlmiş fəaliyyət həyata keçirməmək;
                  </li>
                  <li>
                    Başqa sözlə,Qanunvericiliyin, habelə, Beynəlxalq Hüquq
                    normalarının pozulması;
                  </li>
                </ol>
              </li>
              <li>
                PulPal xidmətlərinin köməyilə əlçatan obyektlər, həmçinin
                dizayn elementləri, mətn,qrafik təsvirlər,illüstrasiyalar,
                video, kompüter proqramları, məlumat bazaları, musiqi, səs və
                digər obyektlər (daha sonra xidmətlərin məzmunu), həmçinin
                PulPal yerləşdirilən istənilən məzmun, PulPal digər müəllif
                hüququ sahiblərinin hüquqi obyektləri xüsusi icazə əsasında
                istifadə oluna bilər.
              </li>
              <li>
                Xidmətlərin hər hansı elementindən istifadə yalnız xidmətin
                təklif etdiyi funksional çərçivəsində mümkündür. PulPal
                xidmətləri daxilində olan hər hansı məzmun müəllif hüquqları
                sahibinin icazəsi olmadan istifadə oluna bilməz.
              </li>
              <li>
                PulPal xidmətləri internet şəbəkəsində digər saytlara (üçüncü
                şəxs saytları) keçid ehtiva edə bilər. Göstərilən üçüncü
                şəxslər və onların təşkil etdiyi məzmun PulPal tərəfindən
                yoxlanılır(etibarlılıq, tamlıq, qanunilik və s.) . PulPal
                müştərinin xidmətlər vasitəsilə giriş etdiyi üçüncü şəxs
                saytlarından əldə etdiyi məlumat və materiala, həmçinin üçüncü
                şəxs saytlarında qeydiyyat və rəylərə,bu saytların
                əlçatanlığına, məzmun və nəticələrə görə məsuliyyət daşımır.
              </li>
              <li>
                PulPal xidmətlərində yerləşdirilmiş hər hası formada istənilən
                sayt,məhsul.xidmət və istənilən kommersiya və qeyri-kommersiya
                xarakterli informasiya PulPal tərəfindən təstiq və ya göstəriş
                kimi qiymətləndirilə bilməz.
              </li>
              <li>
                Zəmanətin olması və məsuliyyətin məhdudlaşdırılması
                <ol>
                  <li>
                    Müştəri PulPal xidmətlərindən öz riski ilə istifadə edir.
                    Xidmətlər “olduğu kimi” təqdim olunur. PulPal öz üzərinə
                    heç bir məsuliyyət götürmür və Azərbayca Respublikası
                    qanunvericiliyində birbaşa nəzərdə tutlan hallardan başqa,
                    xidmətlərin Müştərinin məqsədlərinə uyğun gəlib-gəlməməsi
                    üçün məsuliyyət daşımır.
                  </li>
                  <li>
                    PulPal: xidmətlərin müştərinin tələblərinə və
                    gözləntilərinə uyğun olması, xidmətin davamlı, sürətli,
                    etibarlı, xətasız; xidmət sayəsində əldə olunan
                    nəticələrin dəqiq və etibarlı olması, onların hər hansı
                    məqsəd və hər hansı keyfiyyətinin olmasına, hər hansı
                    məsulun,xidmət və informasiyanın keyfiyyəti və bunların
                    Müştərinin gözləntilərinə uyğun gəlməsinə zəmanət vermir.
                  </li>
                  <li>
                    Əgər aşağıda göstərilən halı Azərbaycan Respublikası
                    Qanunvericiliyində təmin edilmirsə,PulPal Müştərinin
                    PulPal xidmətlərindən istifadə nəticəsində baş verən
                    istənilən növ itki və ya xidmətin ayrı-ayrı hissələrinin
                    göstərilməməsi ilə əlaqədar heç bir məsuliyyət daşımır.
                  </li>
                </ol>
              </li>
              <li>
                Cari razılaşma yalnız Azərbaycan Respublikası Qanunvericiliyi
                çərçivəsində tənzimlənir. Tərəflər arasında həll olunmamış
                məsələlər Azərbaycan Respublikası Qanunvericiliyinin
                tələblərinə uyğun çəkildə həllini tapır.
              </li>
              <li>
                Cari razılaşmanın bir və ya bir neçə müddəası bu və ya digər
                səbəbdən etibarsız sayılarsa və hüququi qüvvəsi olmazsa, bu
                razılaşmanın digər şərtlərinin həqiqiliyinə və etibarlılığına
                təsir etmir.
              </li>
              <li>
                PulPal Müştərinin və ya digər Müştərilərin Razılaşmanın
                şərtlərini pozduqdan dərhal sonra lazımi tədbirlər görülmürsə,
                bu hələ PulPal-ın maraqlarını daha sonra qorumayacağı
                hüququndan məhrum etmir və PulPal müstəqil şəkildə gələcəkdə
                bu kimi qanun pozuntuları zamanı öz hüquqlarını müdafiə
                edəcəkdir.
              </li>
            </ol>
          </div>
        </div>
      )
      case 'ru': return (
        <div className='footer-modal'>
          <div className='footer-modal__header'>
            <h4>Соглашение об общих условиях сервиса «PulPal»</h4>
          </div>
          <div className='footer-modal__content'>
            <ol>
              <li>
                ООО «PULPAL» (далее — «PULPAL») предлагает пользователю сети Интернет (далее — Клиент) — использовать свои
                сервисы на условиях, изложенных в настоящем соглашении (далее — «Соглашение»). Соглашение вступает в силу с
                момента выражения Клиентом согласия с его условиями в порядке, предусмотренном п. 3 Соглашения.
              </li>
              <li>
                PULPAL предлагают Клиентам доступ на передачу распоряжений банкам-партнерам на осуществление переводов, в том
                числе с использованием банковских карт и терминальных сетей.
                Все существующие на данный момент сервисы, а также любое развитие их и/или добавление новых, являются
                предметом
                настоящего Соглашения.
              </li>
              <li>
                Использование сервисов PULPAL регулируется настоящим Соглашением, Политикой конфиденциальности (ссылка на
                PULPAL), а также условиями использования отдельных сервисов.
                Начиная использовать какой-либо сервис/его отдельные функции либо пройдя процедуру регистрации, Клиент
                считается
                принявшим условия Соглашения в полном объеме, без всяких оговорок и исключений.
                PULPAL вправе предложить Клиенту изменить и/или дополнить настоящее Соглашение путем опубликования Соглашения
                в
                новой редакции на Интернет-сайте по адресу (ссылка на PULPAL). Принятием Пользователем такого предложения
                являются конклюдентные действия по использованию любого сервиса PULPAL на новых условиях.
              </li>
              <li>
                В случае несогласия Клиента с изменениями в Соглашении, внесенными в порядке, предусмотренном пунктом 3, он
                обязан прекратить использование сервисов PULPAL.
              </li>
              <li>
                Для того чтобы воспользоваться некоторыми сервисами PULPAL или некоторыми отдельными функциями сервисов,
                Клиенту
                необходимо пройти процедуру регистрации, в результате которой для Клиента будет создана уникальная учетная
                запись.
              </li>
              <li>
                Для регистрации Клиент обязуется предоставить достоверную и полную информацию о себе по вопросам, предлагаемым
                в
                форме регистрации, и поддерживать эту информацию в актуальном состоянии. Если Клиент предоставляет неверную
                информацию или у PULPAL есть основания полагать, что предоставленная Клиентом информация неполна или
                недостоверна PULPAL имеют право по своему усмотрению отказать Пользователю в использовании своих сервисов
                (либо
                их отдельных функций).
                <ol>
                  <li>
                    Персональная информация Клиента, предоставленная в процессе использования сервисов, хранится и
                    обрабатывается в соответствии с условиями Политики конфиденциальности, условиями отдельных сервисов и
                    настоящим Соглашением.
                  </li>
                  <li>
                    Если конкретный сервис PULPAL требует регистрации, то при регистрации Клиент самостоятельно выбирает себе
                    логин (уникальное символьное имя учетной записи Клиента) и пароль для доступа к учетной записи. PULPAL
                    вправе запретить использование определенных логинов, а также устанавливать требования к логину и паролю
                    (длина, допустимые символы и т.д.), либо требовать использования дополнительных паролей.
                  </li>
                  <li>
                    Для некоторых сервисов Клиент имеет возможность использовать альтернативное средство аутентификации для
                    доступа к учетной записи, заменив им установленный пароль. Доступ к такому средству предоставляется
                    посредствам настройки двухфакторной аутентификации в интерфейсе управления учетной записью Клиента.
                  </li>
                  <li>
                    Клиент самостоятельно несет ответственность за безопасность (устойчивость к подбору) выбранных им средств
                    для доступа к учетной записи, а также самостоятельно обеспечивает их конфиденциальность. Клиент
                    самостоятельно несет ответственность за все действия (а также их последствия) в рамках или с
                    использованием
                    сервисов PULPAL под учетной записью Клиента, включая случаи добровольной передачи Клиентом данных для
                    доступа к учетной записи Клиента третьим лицам на любых условиях (в том числе по договорам или
                    соглашениям).
                    При этом все действия в рамках или с использованием сервисов PULPAL под учетной записью Клиента считаются
                    произведенными самим Клиентом.
                  </li>
                </ol>
              </li>
              <li>
                В случае, если конкретный сервис не предусматривает регистрации Клиента, PULPAL аутентифицируют пользователя
                сети Интернет как Клиента на основании, в том числе, данных, предоставленных Клиентом ранее при использовании
                сервисов PULPAL, таких как, в том числе, адрес электронной почты, номер телефона.
              </li>
              <li>
                Вводя любые данные в одном из сервисов PULPAL, Клиент соглашается на сохранение этих данных для возможности
                использования их повторно или в иных сервисах PULPAL без дополнительного повторного ввода.
              </li>
              <li>
                Клиент обязан немедленно уведомить PULPAL о любом случае несанкционированного (не разрешенного Клиентом)
                доступа
                к сервисам PULPAL с использованием учетной записи Клиента и/или о любом нарушении (подозрениях о нарушении)
                конфиденциальности своих средств доступа к учетной записи. В целях безопасности Клиент обязан самостоятельно
                осуществлять безопасное завершение работы под своей учетной записью (кнопка «Выход») по окончании каждой
                сессии
                работы с сервисами PULPAL.
              </li>
              <li>
                PULPAL вправе устанавливать ограничения в использовании сервисов для всех Клиентов либо для отдельных
                категорий
                Клиентов (в зависимости от места пребывания Клиента, языка, на котором предоставляется сервис и т.д.), в том
                числе: вид и размер переводов, способ подачи распоряжения, перечень получателей переводов, и т.п. PULPAL могут
                запретить автоматическое обращение к своим сервисам, а также прекратить прием любой информации,
                сгенерированной
                автоматически.
              </li>
              <li>
                PULPAL вправе посылать своим Клиентам информационные сообщения. Используя сервисы PULPAL, Клиент также в
                соответствии с Законом Азербайджанской Республики «О рекламе» дает свое согласие на получение сообщений
                рекламного характера. Клиент вправе отказаться от получения сообщений рекламного характера путем использования
                соответствующего функционала того сервиса, в рамках которого или в связи с которым Клиентом были получены
                сообщения рекламного характера, или путем прямого обращения в службу поддержки Клиентов.
              </li>
              <li>
                Клиент самостоятельно несет ответственность за соответствие содержания размещаемого Клиентом контента в
                сервисах
                PULPAL требованиям действующего законодательства, включая ответственность перед третьими лицами в случаях,
                когда
                размещение Клиентом того или иного контента или содержание контента нарушает права и законные интересы третьих
                лиц, в том числе личные неимущественные права авторов, иные интеллектуальные права третьих лиц, и/или посягает
                на принадлежащие им нематериальные блага.
              </li>
              <li>
                Клиент признает и соглашается с тем, что PULPAL не обязаны просматривать контент любого вида, размещаемый
                и/или
                распространяемый Клиентом посредством своих сервисов, а также то, что PULPAL имеют право (но не обязанность)
                по
                своему усмотрению отказать Клиенту в размещении и/или распространении им контента или удалить любой контент,
                который доступен посредством сервисов PULPAL. Клиент осознает и согласен с тем, что он должен самостоятельно
                оценивать все риски, связанные с использованием контента, включая оценку надежности, полноты или полезности
                этого контента.
              </li>
              <li>
                Клиент осознает и соглашается с тем, что технология работы сервисов может потребовать копирование
                (воспроизведение) контента Клиента PULPAL, а также переработки его для соответствия техническим требованиям
                того
                или иного сервиса.
              </li>
              <li>
                Клиент самостоятельно несет ответственность перед третьими лицами за свои действия, связанные с использованием
                сервисов PULPAL, в том числе, если такие действия приведут к нарушению прав и законных интересов третьих лиц,
                а
                также за соблюдение законодательства при использовании сервисов.
              </li>
              <li>
                При использовании сервисов PULPAL Клиент не вправе:
                <ol>
                  <li>
                    нарушать права третьих лиц, в том числе несовершеннолетних лиц, и/или причинять им вред в любой форме;
                  </li>
                  <li>
                    выдавать себя за другого человека или представителя организации и/или сообщества без достаточных на то
                    прав,
                    в том числе за сотрудников PULPAL, за модераторов форумов, за владельца сайта, за представителей партнеров
                    PULPAL, а также применять любые другие формы и способы незаконного представительства других лиц в сети, а
                    также вводить пользователей или PULPAL в заблуждение относительно свойств и характеристик каких-либо
                    субъектов или объектов;
                  </li>
                  <li>
                    использовать чужие авторизационные данные, чужие электронные средства платежа;
                  </li>
                  <li>
                    несанкционированно собирать и хранить персональные данные других лиц;
                  </li>
                  <li>
                    нарушать нормальную работу веб-сайтов и сервисов PULPAL;
                  </li>
                  <li>
                    совершать действия, направленные на нарушение ограничений и запретов, налагаемых Соглашением;
                  </li>
                  <li>
                    другим образом нарушать нормы законодательства, в том числе нормы международного права.
                  </li>
                </ol>
              </li>
              <li>
                Все объекты, доступные при помощи сервисов PULPAL, в том числе элементы дизайна, текст, графические
                изображения,
                иллюстрации, видео, программы для ЭВМ, базы данных, музыка, звуки и другие объекты (далее — содержание
                сервисов), а также любой контент, размещенный на сервисах PULPAL, являются объектами исключительных прав
                PULPAL
                и других правообладателей, предоставивших PULPAL соответствующие разрешения.
              </li>
              <li>
                Использование каких-либо элементов сервисов возможно только в рамках функционала, предлагаемого тем или иным
                сервисом. Никакие элементы содержания сервисов PULPAL, а также любой контент, размещенный на сервисах PULPAL,
                не
                могут быть использованы иным образом без предварительного разрешения правообладателя.
              </li>
              <li>
                Сервисы PULPAL могут содержать ссылки на другие сайты в сети Интернет (сайты третьих лиц). Указанные третьи
                лица
                и их контент не проверяются PULPAL на соответствие тем или иным требованиям (достоверности, полноты,
                законности
                и т.п.). PULPAL не несут ответственность за любую информацию, материалы, размещенные на сайтах третьих лиц, к
                которым Клиент получает доступ с использованием сервисов, в том числе за любые мнения или утверждения,
                выраженные на сайтах третьих лиц, рекламу и т.п., а также за доступность таких сайтов или контента и
                последствия
                их использования Клиентом.
              </li>
              <li>
                Ссылка (в любой форме) на любой сайт, продукт, услугу, любую информацию коммерческого или некоммерческого
                характера, размещенная в сервисах PULPAL, не является одобрением или рекомендацией данных продуктов (услуг,
                деятельности) со стороны PULPAL.
              </li>
              <li>
                Отсутствие гарантий, ограничение ответственности
                <ol>
                  <li>
                    Клиент использует сервисы PULPAL на свой собственный риск. Сервисы предоставляются «как есть». PULPAL не
                    принимает на себя никакой ответственности, в том числе за соответствие сервисов целям Клиента, за
                    исключением
                    случаев, прямо предусмотренных законодательством Азербайджанской Республики;
                  </li>
                  <li>
                    PULPAL не гарантируют, что: сервисы соответствуют/будут соответствовать требованиям и ожиданиям Клиента;
                    сервисы будут предоставляться непрерывно, быстро, надежно и без ошибок; результаты, которые могут быть
                    получены
                    с использованием сервисов, будут точными и надежными и могут использоваться для каких-либо целей или в
                    каком-либо качестве; качество какого-либо продукта, услуги, информации и пр., полученных с использованием
                    сервисов, будет соответствовать ожиданиям Клиента;
                  </li>
                  <li>
                    PULPAL не несут ответственности за любые виды убытков, произошедшие вследствие использования Клиентом
                    сервисов PULPAL или отдельных частей/функций сервисов, если иное прямо не предусмотрено законодательством
                    Азербайджанской Республики;
                  </li>
                </ol>
              </li>
              <li>
                Настоящее Соглашение регулируется и толкуется в соответствии с законодательством Азербайджанской Республики.
                Вопросы,
                не урегулированные настоящим Соглашением, подлежат разрешению в соответствии с законодательством
                Азербайджанской
                Республики. Все возможные споры, вытекающие из отношений, регулируемых настоящим Соглашением, разрешаются в
                порядке, установленном действующим законодательством Азербайджанской Республики, по нормам Азербайджанского
                права.
              </li>
              <li>
                Если по тем или иным причинам одно или несколько положений настоящего Соглашения будут признаны
                недействительными или не имеющими юридической силы, это не оказывает влияния на действительность или
                применимость остальных положений Соглашения.
              </li>
              <li>
                Бездействие со стороны PULPAL в случае нарушения Клиентом либо иными Клиентами положений Соглашения не
                лишает PULPAL права предпринять соответствующие действия в защиту своих интересов позднее, а также не означает
                отказа PULPAL от своих прав в случае совершения в последующем подобных либо сходных нарушений.
              </li>
            </ol>
          </div>
        </div>
      )
      case 'en': return (
        <div className='footer-modal'>
          <div className='footer-modal__header'>
            <h4>The agreement on the general conditions of service «PulPal»</h4>
          </div>
          <div className='footer-modal__content'>
            <ol>
              <li>
                LLC “PULPAL” (hereinafter - “ PULPAL ”) invites the user of the Internet (hereinafter - the Client) to use their services
                on the conditions set forth in this agreement (hereinafter - the “Agreement”). The Agreement shall enter into force from
                the moment the Customer expresses consent to its terms in the manner provided for in clause 3 of the Agreement.
              </li>
              <li>
                PULPAL offer customers access to the transmission of orders to partner banks to make the transfer, including the use of
                bank card and terminal networks.
                All currently existing services, as well as any development of them and / or the addition of new ones, are the subject of this Agreement.
              </li>
              <li>
                The use of PULPAL services is governed by this Agreement, the Privacy Policy (  to PULPAL ),
                as well as the terms of use of individual services.
                Starting to use any service / its individual functions or having completed the registration procedure, the Client is considered
                to have accepted the terms of the Agreement in full, without any reservations or exceptions.
                PULPAL has the right to invite the Client to amend and / or supplement this Agreement by publishing the Agreement
                in a new version on the Internet site at the address (  to PULPAL ) . Acceptance by the User of such an offer is a consequential
                action to use any PULPAL service on new conditions.
              </li>
              <li>
                In case of disagreement of the Client with the amendments to the Agreement and made in the manner prescribed by paragraph 3,
                he is obliged to stop using the PULPAL services .
              </li>
              <li>
                In order to use some PULPAL services or some individual functions of the services, the Client must go through the registration
                procedure, as a result of which a unique account will be created for the Client.
              </li>
              <li>
                For registration, the Client undertakes to provide reliable and complete information about himself on the issues proposed in
                the registration form, and to keep this information up to date. If the Client provides incorrect information or PULPAL has reason
                to believe that the information provided by the Client is incomplete or false PULPAL has the right, at its discretion, to refuse the
                User to use their services (or their individual functions).
                <ol>
                  <li>
                    The Client’s personal information provided in the process of using the services is stored and processed in accordance with
                    the terms of the Privacy Policy, the terms of individual services and this Agreement.
                  </li>
                  <li>
                    If a specific PULPAL service requires registration, then during registration the Client chooses for himself a login
                    (a unique symbolic name for the Client's account) and password for access to the account. PULPAL has the right to prohibit
                    the use of certain logins, as well as establish requirements for a login and password (length, valid characters, etc.), or
                    require the use of additional passwords.
                  </li>
                  <li>
                    For some services, the Client has the opportunity to use an alternative means of authentication to access the account,
                    replacing them with the password. Access to such a facility is provided by setting up two-factor authentication in the Client's
                    account management interface.
                  </li>
                  <li>
                    The client is solely responsible for the security (resistance to selection) of his chosen means of access to the account, and also
                    independently ensures their confidentiality. The Client is solely responsible for all actions (as well as their consequences) within
                    or using PULPAL services under the Client’s account, including cases of voluntary transfer by the Client of data for access to the
                    Client ’s account to third parties on any conditions ( including under contracts or agreements ) At the same time, all actions within
                    or using the PULPAL services under the Client's account are considered to be performed by the Client himself.
                  </li>
                </ol>
              </li>
              <li>
                In the event that a particular service does not provide for the registration of the Client, PULPAL authenticates the Internet user as
                a Client on the basis of, inter alia, the data provided by the Client earlier when using the PULPAL services , such as, for example ,
                email address, phone number.
              </li>
              <li>
                Entering any data in one of the PULPAL services , the Client agrees to save this data for the possibility of using it again
                or in other PULPAL services without additional re-entry.
              </li>
              <li>
                The client is obliged to immediately notify PULPAL of any unauthorized (not authorized by the Client) access to services PULPAL
                with the use of the Client's account and / or any breach of (suspicion of abuse) privacy of its means of access to the account.
                The purpose of the security client is obliged to carry out a safe shutdown under your account ( "Exit" button) at the end of each
                work session with the services PULPAL.
              </li>
              <li>
                PULPAL has the right to establish restrictions on the use of services for all Clients or for certain categories of Clients
                ( depending on the location of the Client, the language in which the service is provided, etc.), including: type and size of
                translations, method of submitting an order , list of recipients of transfers, etc. PULPAL may prohibit the automatic access to
                its services, as well as stop receiving any information generated automatically.
              </li>
              <li>
                PULPAL has the right to send informational messages to its Clients. Using the PULPAL services , the Client also in accordance with the Law
                of the Republic of Azerbaijan “On Advertising” gives his consent to receive advertising messages. The Client has the right to refuse
                to receive messages of an advertising nature by using the appropriate functionality of the service within which or in connection with
                which the Client received messages of an advertising nature, or by directly contacting the Customer Support Service.
              </li>
              <li>
                The Client is solely responsible for the compliance of the content of the content posted by the Client with PULPAL services with
                the requirements of applicable law, including liability to third parties in cases where the placement of the content by the Client or
                the content of the content violates the rights and legitimate interests of third parties, including personal non-property rights of
                authors, other intellectual rights of third parties, and / or infringes on intangible goods belonging to them.
              </li>
              <li>
                The Client acknowledges and agrees that PULPAL is not obligated to view any kind of content posted and / or distributed by the Client
                through its services, as well as that PULPAL have the right (but not the obligation) to refuse to place the Client and / / or
                distributing content to him or removing any content that is accessible through the PULPAL services . The client is aware and agrees
                that he must independently evaluate all the risks associated with the use of the content, including an assessment of the reliability,
                completeness or usefulness of this content.
              </li>
              <li>
                The Client is aware and agrees that the technology of the services may require the copying (reproduction) of the PULPAL Client's
                content , as well as its processing to meet the technical requirements of a particular service.
              </li>
              <li>
                The Client shall be solely responsible to third parties for their actions related to the use of services PULPAL , in particular,
                if such action would lead to violation of the rights and legitimate interests of third parties, as well as compliance with the
                law when using services.
              </li>
              <li>
                When using the PULPAL services , the Client is not entitled to:
                <ol>
                  <li>
                    violate the rights of third parties, including minors, and / or harm them in any form;
                  </li>
                  <li>
                    impersonate another person or company representative and / or community without sufficient that rights, including for
                    employees PULPAL for moderators of forums for the site owner, for representatives of partners PULPAL , as well as apply
                    any other forms and ways of illegal representation of other persons in the network, as well as mislead users or PULPAL LLC
                    regarding the properties and characteristics of any entities or objects;
                  </li>
                  <li>
                    use someone else's authorization data, someone else’s electronic means of payment;
                  </li>
                  <li>
                    unauthorized to collect and store personal data of other persons;
                  </li>
                  <li>
                    disrupt the normal operation of PULPAL websites and services;
                  </li>
                  <li>
                    take actions aimed at violating the restrictions and prohibitions imposed by the Agreement;
                  </li>
                  <li>
                    otherwise violate legal norms , including international law.
                  </li>
                </ol>
              </li>
              <li>
                All objects accessible using PULPAL services , including design elements, text, graphic images, illustrations, videos,
                computer programs, databases, music, sounds and other objects (hereinafter referred to as the content of services), as well as
                any content , hosted on PULPAL services , are the exclusive rights of PULPAL and other copyright holders who have granted
                PULPAL appropriate permissions.
              </li>
              <li>
                The use of any elements of services is possible only within the framework of the functionality offered by a particular service.
                No content elements of the PULPAL services , as well as any content posted on the PULPAL services , may be used in any
                other way without the prior permission of the copyright holder.
              </li>
              <li>
                PULPAL services may contain links to other sites on the Internet (third-party sites). The specified third parties and their
                content are not checked by PULPAL for compliance with certain requirements (reliability, completeness, legality, etc.).
                PULPAL is not responsible for any information, materials posted on third-party websites that the Client accesses using the services,
                including any opinions or statements expressed on third-party websites, advertising, etc., as well as for the
                availability of such sites or content and the consequences of their use by the Client.
              </li>
              <li>
                A https://pulpal.az/  (in any form) to any website, product, service, any information of a commercial or non-commercial
                nature posted on PULPAL services does not constitute an endorsement or recommendation of these products (services, activities) by PULPAL.
              </li>
              <li>
                Lack of guarantees, limitation of liability
                <ol>
                  <li>
                    The client uses the PULPAL services at his own risk. Services are provided “as is”. PULPAL does not accept any responsibility,
                    including for the conformity of services with the goals of the Client, with the exception of cases expressly provided for by
                    the legislation of the Azerbaijan Republic;
                  </li>
                  <li>
                    PULPAL does not guarantee that: the services meet / will meet the requirements and expectations of the Client;
                    services will be provided continuously, quickly, reliably and without errors; the results that can be obtained using
                    the services will be accurate and reliable and can be used for any purpose or in any quality;
                    the quality of any product, service, information, etc. obtained using the services will meet the expectations of the Client;
                  </li>
                  <li>
                    PULPAL shall not be liable for any types of losses resulting from the use by the Client of PULPAL services or certain
                    parts / functions of services, unless otherwise expressly provided for by the legislation of the Azerbaijan Republic;
                  </li>
                </ol>
              </li>
              <li>
                This Agreement is governed by and construed in accordance with the legislation of Azerbaijan .
                Issues not regulated by this Agreement shall be resolved in accordance with the legislation of the Azerbaijan Republic .
                All possible disputes arising from relations regulated by this Agreement are resolved in the manner established by
                the current legislation of the Azerbaijan Republic , according to the norms of Azerbaijan law.</li>
              <li>
                If, for one reason or another, one or more of the provisions of this Agreement is declared invalid or null and void,
                this does not affect the validity or applicability of the remaining provisions of the Agreement.</li>
              <li>
                Inaction on the part of PULPAL in case of violation by the Client or other Clients of the provisions of the Agreement does not
                deprive PULPAL of the right to take appropriate actions to protect its interests at a later date, nor does it mean
                PULPAL's waiver of its rights in the event of subsequent similar or similar violations.
              </li>
            </ol>
          </div>
        </div>
      )
    }
  }

  return (
    <>{setAgreementPolicy()}</>
  );
}


export default AgreementPolicy;