import { useTranslation } from 'react-i18next';
import './rate.scss';

const Rate = () => {
    const { t } = useTranslation();
    
    return (
        <div className='tariffs'>
            <div className='tariffs__inner'>
                <h1>{t("Tariffs")}</h1>
                <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                {t("currentTariffs")}
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div className="accordion-body accordions">
                                <p className='accordion__title'>{t("service")}</p>
                                <table className="commission-table">
                                    <thead>
                                        <tr>
                                            {//@ts-ignore
                                            <th colSpan="2">{t("comissionPulpalAzdo8")}</th>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{t("fromCustomer")}</td>
                                            <td>{t("exprice5")}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("fromArtist")}</td>
                                            <td>3,93%</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p className='accordion__text'>
                                  {t("Tariffs1")}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                {t("ArchivedTariffs")}
                            </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div className="accordion-body accordions">
                                <p className='accordion__title'>{t("tariffsDate")}</p>
                                <p className='accordion__title'>{t("service")}</p>
                                <table className="commission-table">
                                    <thead>
                                        <tr>
                                            {//@ts-ignore
                                            <th colSpan="2">{t("comissionPulpalAzdo0")}</th>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{t("fromCustomer")}</td>
                                            <td>{t("exprice0")}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("fromArtist")}</td>
                                            <td>0%</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p className='accordion__text'>
                                    {t("Tariffs1")}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Rate;