import { useSelector } from 'react-redux';
import './AgreementPolicy.scss';
import { useEffect } from 'react';

const PrivacyPolicy: React.FC<any> = () => {
    const { language } = useSelector((state: any) => state.languageReducer);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const setPrivacyPolicy = () => {
        switch (language) {
            case 'az':
                return (
                    <div className="footer-modal">
                        <div className='footer-modal__header'>
                            <h4>PULPAL MMC Məxfilik Siyasəti</h4>
                        </div>
                        <div className='footer-modal__content'>
                            <div className='footer-modal__header-section'>
                                <p>
                                    Şəxsi məlumatların Məxfilik Siyasəti (bundan sonra - Siyasət)
                                    PulPal MMC-nin (bundan sonra - PULPAL) PULPAL xidmətindən
                                    internetdən və / və ya saytlar, proqramlar, məhsullar, PulPal
                                    xidmətləri (bundan sonra - PulPal Servisləri) istifadə edərkən
                                    İnternet istifadəçisi (bundan sonra - Müştəri) haqqında əldə
                                    edə biləcəyi məlumatlara aiddir. Siyasət yalnız PulPal
                                    xidmətlərinə aiddir. PulPal, üçüncü şəxslər tərəfindən
                                    işlənilə bilən Müştəri haqqında məlumat daxil olmaqla, PulPal
                                    Xidmətlərindən əldə olunan bağlantılara keçid edə biləcəyi
                                    üçüncü tərəflərin saytlarına / xidmətlərinə görə məsuliyyət
                                    daşımır. Siyasətin mətnində verilməyən və istifadə olunmayan
                                    bütün terminlər və anlayışlar PulPal xidmətinin ümumi şərtləri
                                    haqqında Sazişdə və ya PulPal Xidmətlərinin Müştərisi
                                    tərəfindən təmin edilməsi və istifadəsi qaydası olan digər
                                    müqavilələrdə verilmişdir. PulPal Xidmətlərindən istifadə,
                                    Müştərinin bu Siyasətə şərtsiz razılığını və orada göstərilən
                                    şəxsi məlumatlarının işlənməsi şərtlərini ifadə edir; bu
                                    şərtlərlə razılaşmadıqda, Müştəri PulPal Xidmətlərindən
                                    istifadə etməkdən çəkinməlidir.
                                </p>
                            </div>
                            <ol>
                                <li>
                                    PULPAL tərəfindən qəbul edilən və işlənilən Müştəri
                                    məlumatları
                                    <ol>
                                        <li>
                                            Bu Siyasət çərçivəsində Müştərinin şəxsi məlumatları
                                            dedikdə başa düşülür:
                                            <ol>
                                                <li>
                                                    Müştəri ilə hər hansı bir PULPAL müqavilələrinin
                                                    icrası zamanı (bank kartı məlumatları istisna olmaqla)
                                                    ötürülən Müştərinin şəxsi məlumatları da daxil olmaqla
                                                    PULPAL Xidmətlərindən hər hansı birində qeydiyyata
                                                    alınması zamanı və ya PULPAL Xidmətlərindən istifadə
                                                    zamanı müstəqil şəkildə təqdim etdiyi fərdi
                                                    məlumatlar. Nəzərə alın ki, Müştəri tərəfindən müəyyən
                                                    PULPAL xidmətlərdən istifadə yalnız lazımi məlumatlar
                                                    təqdim edildikdə mümkündür.
                                                </li>
                                                <li>
                                                    Müştərinin cihazında quraşdırılmış proqramdan istifadə
                                                    edərək PULPAL Xidmətlərindən istifadə zamanı avtomatik
                                                    olaraq PULPAL tərəfindən ötürülən məlumatlar, o
                                                    cümlədən IP ünvanı, cookie məlumatları (Müştərinin
                                                    brauzerində saxlanan mətn sənədləri), Müştərinin
                                                    brauzeri (və ya digər proqram, PULPAL Xidmətlərə giriş
                                                    əldə edilir), giriş vaxtı, tələb olunan səhifənin
                                                    ünvanı.
                                                    <ol>
                                                        <li>
                                                            PULPAL saytlarına baxarkən Müştəri haqqında
                                                            aşağıdakı anonim statistik məlumatlar avtomatik
                                                            olaraq (cookies-dən) toplanır, o cümlədən:
                                                            <ul>
                                                                <li>
                                                                    saytda görülən hərəkətlərin növü (klik,
                                                                    kursorun yönləndirilməsi və s.);
                                                                </li>
                                                                <li>fəaliyyətin tarixi və vaxtı;</li>
                                                                <li>əhifənin URL;</li>
                                                                <li>Referrer;</li>
                                                                <li>
                                                                    IP (statistikada IP adresləri ilə işləmək
                                                                    imkanı olmadan);
                                                                </li>
                                                                <li>İstifadəçi-Agent;</li>
                                                                <li>
                                                                    ClientID (cookie tərəfindən brauzer
                                                                    identifikatoru);
                                                                </li>
                                                                <li>ekran həlli;</li>
                                                                <li>HTML tıklandığı elementin sinfi;</li>
                                                                <li>
                                                                    Müştəri tərəfindən interfeysdə baxılan
                                                                    məlumatlar, o cümlədən xidmətin bütün
                                                                    bölmələrindəki məlumatlar;
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            Veb saytdan istifadə edərək, Müştəri (həmçinin veb
                                                            səhifəni ziyarət edən ) PULPAL-ın "Məhsullar";
                                                            "Xidmətlər"; "Parametrlər" bölmələrdəki
                                                            interfeysdə Müştərinin baxdığı məlumatlar istisna
                                                            olmaqla statistika və cookilərdən istifadə edə
                                                            biləcəyinə razılaşır:, sistemlər tərəfindən
                                                            sonrakı işlənməsi üçün, məsələn, Google Analytics,
                                                            Yandex.Metrica, Google Firebase, Appmetrica və
                                                            tədqiqat, iş yerinə yetirilməsi və ya PULPAL
                                                            xidmətlərinin göstərilməsi üçün üçüncü tərəfə
                                                            verilə bilər. Müştəri (həmçinin veb sayt
                                                            ziyarətçisi) cihazlarında işləyən brauzerin
                                                            parametrlərini dəyişdirərək cookiləri müstəqil
                                                            şəkildə idarə edə bilər. Cookielərin bağlanmasına
                                                            səbəb olan istifadəçi parametrlərindəki dəyişiklik
                                                            PulPal.az saytının bəzi komponentlərinin
                                                            əlçatmazlığına səbəb ola bilər.
                                                        </li>
                                                    </ol>
                                                </li>
                                                <li>
                                                    Müştəri kənar mənbələrdən (məsələn, söhbətlər /
                                                    forumlar / sosial şəbəkələr) istifadə edərkən PULPAL,
                                                    açıq şəkildə olan məlumatları ala bilər. Bu məlumatlar
                                                    Müştərinin PULPAL Xidmətləri barədə şərh və ya rəy
                                                    şəklində yayımladığı məlumatları əks etdirə bilər.
                                                    PULPAL müştərilərə xidmətin keyfiyyətini artırmaq üçün
                                                    bu cür məlumatlardan istifadə edir.
                                                </li>
                                                <li>
                                                    PULPAL, Müştəriyə xidmət göstərilən qanuna və ya
                                                    razılaşmaya zidd olan hərəkətlərin xidmətdən
                                                    istifadəni asanlaşdıran şərtləri müəyyənləşdirmək və
                                                    (və ya) qarşısını almaq üçün müəyyən məlumatları
                                                    (məsələn, IP ünvanı, istifadəçi cihaz
                                                    identifikatorunu) emal edə bilər.
                                                </li>
                                                <li>
                                                    PULPAL Müştəri haqqında məlumatı həmkarlarından əldə
                                                    edə bilər. Məsələn, qarşı tərəflə razılaşma əldə
                                                    edilərkən, sonuncu PULPAL-a qarşı tərəfə bu cür
                                                    Müştəri ilə ötürülən arasında əlaqə yaratmaq imkanı
                                                    verən bəzi məlumatları ötürə bilər, bunun başa çatması
                                                    barədə məlumat PULPAL-a qarşı tərəfə ötürülür.
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            Müştəri və PULPAL-ın tətbiq olunan müqavilələrində nəzərdə
                                            tutulmuş əsaslarla və qaydada Müştərilərin
                                            eyniləşdirilməsi / sadələşdirilmiş identifikasiyası
                                            halları istisna olmaqla, PULPAL Müştərilər tərəfindən
                                            təqdim olunan fərdi məlumatların doğruluğunu yoxlamır,
                                            onların iş bacarığlı olmasına da nəzarət həyata keçirmir.
                                            Lakin PULPAL ondan irəli gəlir ki, Müştəri düzgün və
                                            kifayət qədər fərdi məlumat verir və bu məlumatı aktual
                                            vəziyyətdə saxlayır.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    Müştərilərin şəxsi məlumatlarının toplanması və işlənməsi
                                    məqsədi
                                    <ol>
                                        <li>
                                            PULPAL yalnız qanunla müəyyən fərdi məlumatların məcburi
                                            alınması və saxlanması nəzərdə tutulmadığı təqdirdə PULPAL
                                            Xidmətlərinin göstərilməsi və ya Müştəri ilə müqavilələrin
                                            icrası üçün zəruri olan şəxsi məlumatları toplayır və
                                            saxlayır. Şəxsi məlumatların saxlanması, Müştəri
                                            tərəfindən PULPAL Xidmətlərindən istifadə haqqında
                                            razılaşma ilə Azərbaycan Respublikasının qanunvericiliyi
                                            ilə müəyyən edilmədiyi halda, şəxsi məlumatların
                                            saxlanması emal məqsədlərinin tələb olunduğu müddətdən
                                            artıq bir müddətdə həyata keçirilir.
                                        </li>
                                        <li>
                                            PULPAL Müştərinin şəxsi məlumatlarını aşağıdakı məqsədlər
                                            üçün istifadə edə bilər:
                                            <ol>
                                                <li>
                                                    PULPAL ilə müqavilələrin yerinə yetirilməsi, o
                                                    cümlədən Müştərinin şəxsiyyətini müəyyənləşdirmək /
                                                    asanlaşdırmaq, Müştəriyə xidmət göstərmək, Müştərinin
                                                    pul köçürməsi və / və ya pul qalıqlarının qaytarılması
                                                    barədə tapşırıqlarını yerinə yetirməsi;
                                                </li>
                                                <li>
                                                    Kommunikasiya. Müştəri ilə ünsiyyət, o cümlədən PULPAL
                                                    Xidmətlərin istifadəsi / PULPAL xidmətlərinin
                                                    göstərilməsi ilə bağlı bildiriş, sorğu və məlumatların
                                                    göndərilməsi, habelə Müştərinin sorğu və
                                                    müraciətlərinin işlənməsi;
                                                </li>
                                                <li>
                                                    PULPAL Xidmətlərinin keyfiyyətinin yaxşılaşdırılması,
                                                    istifadənin asanlığı, yeni PULPAL Xidmətlərin
                                                    inkişafı, Müştəriyə fərdi PULPAL xidmətləri təklif
                                                    edir;
                                                </li>
                                                <li>
                                                    Anonim məlumat əsasında statistik və digər
                                                    tədqiqatların aparılması;
                                                </li>
                                                <li>
                                                    Müştərilər üçün marketinq kampaniyalarının aparılması,
                                                    o cümlədən kampaniyada iştirak etmək üçün təkliflərin
                                                    yayılması və kampaniyanın verdiyi mükafat /
                                                    qonorarların alınması; telekommunikasiya
                                                    şəbəkələrində, o cümlədən telefon, faks, mobil
                                                    radiotelefon rabitəsi və ya birbaşa əlaqə vasitəsi ilə
                                                    reklam və informasiya materiallarının yayılması;
                                                    Müştərilərin diqqətinə çatdırılan reklam
                                                    materiallarını və digər məlumatları hədəf alır.
                                                </li>
                                                <li>
                                                    Xidmətin Müştəriyə təqdim olunduğu qanunun və ya
                                                    müqavilənin tələblərinə zidd olan hərəkətlərdən
                                                    istifadə edərək xidmətlərin verilməsinə şərait yaradan
                                                    halların qarşısının alınması.
                                                </li>
                                            </ol>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    Müştərinin şəxsi məlumatlarının işlənməsi və üçüncü şəxslərə
                                    verilməsi şərtləri
                                    <ol>
                                        <li>
                                            PULPAL Müştərilərin şəxsi məlumatlarını bu Siyasətə,
                                            müəyyən xidmətlərin göstərilməsi şərtlərinə və PULPAL
                                            daxili qaydalara uyğun olaraq işləyir.
                                        </li>
                                        <li>
                                            Müştərinin şəxsi məlumatlarına gəlincə, onun məxfiliyi
                                            təmin olunur.
                                        </li>
                                        <li>
                                            PULPAL aşağıdakı hallarda Müştərinin şəxsi məlumatlarını
                                            üçüncü şəxslərə ötürmək hüququna malikdir:
                                            <ol>
                                                <li>
                                                    Müştəri bu cür hərəkətlərə razı olduğunu bildirdikdə;
                                                </li>
                                                <li>
                                                    Köçürmə, müəyyən bir PULPAL Xidmət və ya PULPAL
                                                    tərəfdaş xidmətindən istifadə üçün, o cümlədən
                                                    Müştərinin sifarişinin icrası üçün lazımdır.
                                                    <br />
                                                    Xüsusi hallarda, Şəxsi Məlumat aşağıdakı kateqoriyalar
                                                    üzrə üçüncü tərəflərə verilə bilər:
                                                    <ol>
                                                        <li>
                                                            Transferlərin həyata keçirilməsində iştirak edən
                                                            kredit təşkilatları və digər təşkilatlar.
                                                        </li>
                                                        <li>
                                                            Marketinq tərəfdaşları və digər PULPAL
                                                            tərəfdaşları.
                                                            <ol className='alpha'>
                                                                <li>
                                                                    PULPAL, marketinq və digər tədqiqatlar üçün
                                                                    bəzi məlumatları (məsələn, statistikanı),
                                                                    habelə müştəriyə reklam ötürən, o cümlədən
                                                                    üçüncü tərəf təşkilatlara da aid olan və
                                                                    Müştəri üçün maraq kəsb edə bilən digər
                                                                    təşkilatlara ötürülməsini təmin edən digər
                                                                    məlumatlara çıxış verə bilər;
                                                                </li>
                                                                <li>
                                                                    PULPAL, PULPAL tərəfdaşının müəyyən şərtləri
                                                                    Müştərinin müəyyən etdiyi şərtləri yerinə
                                                                    yetirməsi səbəbindən PULPAL tərəfdaşının belə
                                                                    Müştəriyə güzəştlər (bonuslar), Müştəri
                                                                    tərəfindən müəyyən şərtlərin yerinə
                                                                    yetirilməsinə görə təşviq etmək imkanlarını
                                                                    müəyyənləşdirmək üçün əsas olan Müştərinin
                                                                    ödəmə əməliyyatları ilə bağlı bəzi məlumatlara
                                                                    çıxış təmin edə bilər. Bu cür giriş Müştəriyə
                                                                    güzəştlərdən istifadə üçün əlavə şərtlər
                                                                    təqdim etmək PULPAL sadiqlik imkanından
                                                                    qaynaqlanır;
                                                                </li>
                                                                <li>
                                                                    Müştəri PULPAL tərəfdaşları tərəfindən təqdim
                                                                    olunan servislərdən və xidmətlərdən istifadə
                                                                    edərkən, Müştəri haqqında məlumat Sifarişçiyə
                                                                    lazımi xidmət göstərilməsi və ya istifadə
                                                                    rahatlığının səviyyəsini artırmaq üçün lazım
                                                                    olan dərəcədə və məqsədlər üçün, məsələn,
                                                                    prosesi sürətləndirən qeydiyyat formalarının
                                                                    əvvəlcədən doldurulması üçün verilə bilər.
                                                                    PULPAL tərəfdaşları tərəfindən göstərilən
                                                                    xidmətlərdə qeydiyyat prosedurundan keçmək.
                                                                </li>
                                                            </ol>
                                                        </li>
                                                        <li>
                                                            Məlumatların saxlanılmasını təmin edən
                                                            tərəfdaşlar.
                                                        </li>
                                                        <li>
                                                            PulPal xidmətindən istifadə edərək qanunun və ya
                                                            Xidmətin Müştəriyə təqdim etdiyi müqaviləyə zidd
                                                            olan hərəkətlərin icrası üçün şərait yaradan
                                                            vəziyyətin qarşısının alınmasını təmin edən
                                                            tərəfdaşlar.
                                                        </li>
                                                        <li>
                                                            Kütləvi məlumat. PULPAL Xidmətlərində Müştərilərin
                                                            fikir mübadiləsi və bir-biri ilə əlaqə qura
                                                            biləcəyi forum və / və ya söhbət ola bilər.
                                                            Forumda və ya söhbət zonasında bir mesaj
                                                            göndərərkən Müştəri yadda saxlamalıdır ki, bu cür
                                                            məlumatlar onlayn olaraq ictimaiyyətə açıq olacaq
                                                            və belə yerləşdirmə Müştəri tərəfindən risk nəzərə
                                                            alınaraq həyata keçirilir.
                                                        </li>
                                                        <li>
                                                            Nəzarətin keçidi. Köçürmə satışın və ya
                                                            müəssisənin başqa bir köçürülməsinin (tamamilə və
                                                            ya qismən) bir hissəsi olaraq reallaşır, alıcının
                                                            isə aldığı şəxsi məlumatlarla əlaqədar bu
                                                            Siyasətin şərtlərinə riayət etmək üçün bütün
                                                            öhdəliklər yerinə yetirilir;
                                                        </li>
                                                        <li>
                                                            Köçürmə Azərbaycan və ya digər qanunvericiliklə
                                                            qanunla müəyyən edilmiş qaydada təmin edilir;
                                                        </li>
                                                        <li>
                                                            PULPAL və ya üçüncü şəxslərin hüquqlarını və
                                                            qanuni maraqlarını qorumaq üçün, PULPAL-ın
                                                            Müştərinin PULPAL ilə tətbiq olunan razılaşma
                                                            şərtlərini və / və ya tətbiq olunan qanunun
                                                            tələblərini pozduğundan şübhələnmək üçün kifayət
                                                            qədər səbəb olduğu hallarda.
                                                        </li>
                                                    </ol>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            Müştərilərin fərdi məlumatlarını emal edərkən PULPAL,
                                            fərdi məlumatların təhlükəsizliyini təmin etmək sahəsində
                                            münasibətləri tənzimləyən "Şəxsi məlumatlar haqqında"
                                            Qanunu və digər normativ aktları rəhbər tutur.
                                        </li>
                                        <li>
                                            Anonim məlumatların açıqlanması. PULPAL həm də ortaqlara
                                            anonim məlumatları (yəni birbaşa və ya dolayı olaraq
                                            Müştərinin şəxsiyyətini aşkar etməyən məlumatlar), eləcə
                                            də məcmu məlumatları (müştərilərin qrupları və
                                            kateqoriyalarına dair məlumatlar) aça bilər. PULPAL eyni
                                            zamanda müştərilərə Xidmətlərin müəyyən funksiyalarından
                                            istifadə etmək imkanı verən tərəfdaşlara anonim və məcmu
                                            məlumat toplamağa icazə verə bilər ki, sonradan bu
                                            məlumatları PULPAL-a ötürə bilər.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    Müştəri tərəfindən şəxsi məlumatların dəyişdirilməsi və
                                    silinməsi, habelə ona giriş
                                    <ol>
                                        <li>
                                            Müştərinin şəxsi məlumatlarının dəyişdirilməsi və
                                            silinməsi müvafiq PULPAL xidmətlərindən istifadə barədə
                                            razılaşmalarla müəyyən edilmiş qaydada həyata keçirilir.
                                            PULPAL Xidmətlərin bir hissəsi olaraq, Müştəri tərəfindən
                                            verilən məlumatları və ya onun bir hissəsini dəyişdirmək
                                            (yeniləmək, əlavə etmək) və ya silmək üçün funksional
                                            imkanla təmin oluna bilər. Müvafiq funksionallıqdan
                                            istifadə bu PULPAL Xidmətə tətbiq olunan razılaşmalarla
                                            tənzimlənir.
                                            <ol>
                                                <li>
                                                    Tətbiq olunan qanunla icazə verilən həddə PULPAL,
                                                    şəxsi məlumatların açıqlandığı hər bir alıcısına fərdi
                                                    məlumatların dəyişdirilməsi və ya məhv edilməsi barədə
                                                    məlumat verəcəkdir, əgər bu mümkün deyilsə və ya
                                                    qeyri-mütənasib səy tələb etmirsə.
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            Qanunun tələblərinə uyğun olaraq, PULPAL PULPAL
                                            Xidmətlərindən istifadə edərək əldə edilmiş Müştərinin
                                            şəxsi məlumatlarını emal / saxlamağı öhdəsinə götürə
                                            bilər. Bu cür emal / saxlama qanunla müəyyən edilmiş
                                            hallarda, əsaslarda və müddətlərdə PULPAL tərəfindən
                                            həyata keçirilir.
                                        </li>
                                        <li>
                                            Giriş hüququ
                                            <ol>
                                                <li>
                                                    Tətbiq olunan qanuna uyğun olaraq, Müştəri PULPAL
                                                    tərəfindən alınan məlumatları əldə etmək hüququna
                                                    malikdir, yəni aşağıdakılarla bağlı məlumat tələb
                                                    etmək hüququnuz var: (a) emalın məqsədi; (b) işlənən
                                                    məlumatların kateqoriyaları; (c) Müştərinin şəxsi
                                                    məlumatlarının göndərilən və ya göndəriləcək
                                                    qəbuledicilərin kateqoriyası; (d) saxlama müddəti və
                                                    ya onun müəyyənləşdirilməsi meyarları, habelə digər
                                                    məlumatlar.
                                                    <ol>
                                                        <li>
                                                            Xidmət interfeysində başqa cür tətbiq edilmədiyi
                                                            təqdirdə məlumat (surət) PULPAL tərəfindən yazılı
                                                            və ya digər rabitə vasitələri ilə təmin edilə
                                                            bilər.
                                                        </li>
                                                        <li>
                                                            PULPAL, Siyasətin 4.3.1-ci bəndinə uyğun olaraq
                                                            sorğu verən Müştərinin şəxsiyyətinə şübhə etməyə
                                                            əsas verərsə, PULPAL belə Müştərinin şəxsiyyətini
                                                            təsdiqləmək üçün lazım olan əlavə məlumatların
                                                            verilməsini tələb etmək hüququna malikdir.
                                                        </li>
                                                        <li>
                                                            Məlumatın (nüsxələrin) verilməsinə dair sorğunun
                                                            baxılması PULPAL tərəfindən alındığı andan bir ay
                                                            (30 gün) müddətində aparılır. PULPAL, tələblərin
                                                            mürəkkəbliyini və sayını nəzərə alaraq göstərilən
                                                            müddəti iki ay (60 gün) uzatmaq hüququna malikdir.
                                                            PULPAL Müştərini belə uzatma üçün əsas olan
                                                            səbəbləri göstərərək məlumatlandırır.
                                                        </li>
                                                        <li>
                                                            Sorğunun açıq bir əsaslandırılması olmadıqda,
                                                            habelə həddindən artıq çox sayda müraciət edildiyi
                                                            təqdirdə, PULPAL tələbin yerinə yetirilməsi üçün
                                                            (inzibati xərcləri nəzərə alaraq) uyğun bir haqq
                                                            almaq və ya yerinə yetirməkdən imtina etmək
                                                            hüququna malikdir. PULPAL, Müştəriyə bu barədə
                                                            Siyasətin 3.3.2.5-ci bənddə göstərilən qaydada
                                                            dərc edilmiş məlumatı verə bilməyəcəkdir.
                                                        </li>
                                                        <li>
                                                            Müştəri PULPAL-dan alınan məlumatları PULPAL-ın
                                                            texniki ötürülmə imkanları varsa PULPAL vasitəsilə
                                                            bu hissədə nəzərdə tutulmuş qaydada müstəqil
                                                            olaraq başqa bir nəzarətçiyə ötürmək hüququna
                                                            malikdir.
                                                        </li>
                                                    </ol>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            Etiraz etmək hüququ
                                            <ol>
                                                <li>
                                                    Tətbiq olunan qanunla müəyyən edilmiş hallarda Müştəri
                                                    əvvəllər verilmiş razılığını geri götürə bilər və ya
                                                    şəxsi məlumatların işlənməsi ilə əlaqədar qanuni
                                                    olaraq etirazlarını bildirə bilər. Bəzi hallarda,
                                                    razılığın geri alınması Müştərinin PULPAL
                                                    Xidmətlərindən istifadə edə bilməyəcəyi deməkdir.
                                                </li>
                                                <li>
                                                    Tətbiq olunan qanuna uyğun olaraq, Müştəri nəzarətçiyə
                                                    şikayət vermək hüququna malikdir.
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            Uşaqların PULPAL xidmətlərindən istifadəsi
                                            <ol>
                                                <li>
                                                    Qanuni nümayəndənin razılığı olmadan PULPAL Services
                                                    uşaqlar üçün nəzərdə tutulmur. PULPAL, belə şəxslərin
                                                    məlumatlarını yaşını müəyyənləşdirmək və müəyyən yaş
                                                    məhdudiyyətlərini tətbiq etmək üçün istifadə edə
                                                    bilər.
                                                </li>
                                            </ol>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    Müştərilərin şəxsi məlumatlarını qorumaq üçün görülən
                                    tədbirlər
                                    <ol>
                                        <li>
                                            PULPAL, Müştərinin şəxsi məlumatlarını icazəsiz və ya
                                            təsadüfən daxil olmaq, məhv etmək, dəyişdirmək, bloklamaq,
                                            çıxarmaq, yaymaqdan, habelə onunla üçüncü şəxslərin digər
                                            qanunsuz əməllərindən qorumaq üçün zəruri və kifayət qədər
                                            təşkilatı və texniki tədbirlər görür.
                                        </li>
                                        <li>
                                            Şəxsi məlumatları emal edərkən PULPAL, onun
                                            təhlükəsizliyini təmin edir və şəxsi məlumatların qanunsuz
                                            və ya təsadüfi əldə edilməsindən, məhv edilməsindən,
                                            dəyişdirilməsindən, bloklanmasından, kopyalanmasından,
                                            yayılmasından, habelə digər məlumatlara münasibətdə digər
                                            qanunazidd hərəkətlərdən qorunması üçün zəruri təşkilati
                                            və texniki tədbirlər görür, məxfilik rejimi və ona əməl
                                            olunmasına nəzarət, habelə Azərbaycan Respublikası
                                            qanunvericiliyinin, PULPAL təlimatlar, standartlar və
                                            daxili təşkilati və inzibati sənədlərinin tələbləri
                                            əsasında həyata keçirilən əlavə qoruyucu tədbirlərin
                                            tətbiq edir.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    Məxfilik Siyasətinin dəyişdirilməsi. Tətbiq olunan
                                    Qanunveriçilik
                                    <ol>
                                        <li>
                                            PULPAL, Siyasəti İnternet saytında (link) yeni bir
                                            versiyada yayımlamaqla Müştərini bu Siyasəti dəyişməyə və
                                            / və ya əlavə etməyə dəvət etmək hüququna malikdir.
                                            İstifadəçinin belə bir təklifi qəbul etməsi PULPAL
                                            xidmətlərindən hər birini yeni şərtlərlə istifadə etmək
                                            üçün ardıcıl bir hərəkətdir.
                                        </li>
                                        <li>
                                            Azərbaycan Respublikasının qanunları və fərdi məlumatların
                                            təhlükəsizliyini təmin etmək sahəsində münasibətləri
                                            tənzimləyən qaydalar bu Siyasətə və Məxfilik Siyasətinin
                                            tətbiqi ilə əlaqədar yaranan Müştəri və PULPAL
                                            münasibətlərinə aiddir.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    Şəxsi məlumatların emalı ilə əlaqədar Müştərilərlə qarşılıqlı
                                    əlaqə
                                    <ol>
                                        <li>
                                            Siyasətlə bağlı suallar və ya şərhlər office@pulpal.az
                                            elektron poçt ünvanına göndərilə bilər
                                        </li>
                                        <li>
                                            PULPAL, Siyasətin müddəaları ilə əlaqəli olmayan suallara
                                            cavab verməmək hüququna malikdir, bu da Müştərinin bu cür
                                            PULPAL suallarını İnternetdəki PULPAL veb saytında,
                                            "PULPAL MMC Əlaqə" bölməsində göstərilən ünvana
                                            göndərməsinə maneə törətmir.
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </div>
                    </div>
                )
            case 'ru':
                return (
                    <div className="footer-modal">
                        <div className='footer-modal__header'>
                            <h4>Политика конфиденциальности ООО «PULPAL»</h4>
                        </div>

                        <div className='footer-modal__content'>
                            <div>
                                <p>Настоящая Политика конфиденциальности персональной информации (далее — Политика) действует в отношении
                                    информации, которую ООО «PulPal» (далее — PULPAL) может получить о пользователе сети Интернет (далее —
                                    Клиенте) во
                                    время использования им сервиса «PULPAL» и/или любого из веб-сайтов, программ, продуктов, услуг PulPal (далее —
                                    Сервисы PulPal).
                                    Политика применима только к Сервисам PulPal. PulPal не контролирует и не несет ответственность за сайты /
                                    сервисы
                                    третьих лиц, на которые Клиент может перейти по ссылкам, доступным из Сервисов PulPal, в том числе за
                                    обрабатываемую третьими лицами информацию о Клиенте.
                                    Все не приведённые и используемые по тексту Политики термины и определения приведены в Соглашение об общих
                                    условиях сервиса «PulPal» или в иных соглашениях, предметом которых является порядок предоставления и
                                    использования Клиентом Сервисов PulPal.
                                    Использование Сервисов PulPal означает безоговорочное согласие Клиента с настоящей Политикой и указанными в
                                    ней
                                    условиями обработки его персональной информации; в случае несогласия с этими условиями Клиент должен
                                    воздержаться
                                    от использования Сервисов PulPal.
                                </p>
                            </div>
                            <ol>
                                <li>
                                    Информация Клиентов, которую получает и обрабатывает PULPAL.
                                    <ol>
                                        <li>
                                            В рамках настоящей Политики под персональной информацией Клиента понимаются:
                                            <ol>
                                                <li>
                                                    Персональная информация, которую Клиент предоставляет о себе самостоятельно при регистрации в любом из
                                                    Сервисов PULPAL или в процессе использования Сервисов PULPAL, включая персональные данные Клиента
                                                    (исключение данные банковский карт), передаваемые в ходе исполнения любых соглашений PULPAL с
                                                    Клиентом.
                                                    Следует учесть, что использование Клиентом определенных сервисов PULPAL возможно только в случае
                                                    предоставления необходимых данных.
                                                </li>
                                                <li>
                                                    Данные, которые автоматически передаются PULPAL в процессе использования Сервисов PULPAL с помощью
                                                    установленного на устройстве Клиента программного обеспечения, в том числе IP-адрес, информация из
                                                    cookie (текстовые файлы, хранящиеся в браузере Клиента), информация о браузере Клиента (или иной
                                                    программе, с помощью которой осуществляется доступ к Сервисам PULPAL), время доступа, адрес
                                                    запрашиваемой страницы.
                                                    <ol>
                                                        <li> При просмотре веб-сайтов PULPAL, происходит автоматический сбор (из cookies) следующих
                                                            обезличенных
                                                            статистических данных о Клиенте, в том числе:
                                                            <ol className="alpha">
                                                                <li>
                                                                    тип выполненного на сайте действия (клик, наведение курсора и т.п.);
                                                                </li>
                                                                <li>
                                                                    дата и время выполнения действия;
                                                                </li>
                                                                <li>
                                                                    URL страницы;
                                                                </li>
                                                                <li>
                                                                    Referrer;
                                                                </li>
                                                                <li>
                                                                    IP (без возможности работы с IP-адресами в статистике);
                                                                </li>
                                                                <li>
                                                                    User-Agent;
                                                                </li>
                                                                <li>
                                                                    ClientID (идентификатор браузера по файлу cookie);
                                                                </li>
                                                                <li>
                                                                    экранное разрешение;
                                                                </li>
                                                                <li>
                                                                    класс HTML-элемента, на который происходит клик;
                                                                </li>
                                                                <li>
                                                                    класс HTML-элемента, на который происходит клик;
                                                                </li>
                                                                <li>
                                                                    данные об информации просматриваемой Клиентом в интерфейсе, включая информацию в разделах все
                                                                    разделы
                                                                    сервиса;
                                                                </li>
                                                                <li>
                                                                    данные о фактах заполнения форм/обращений на сайте PULPAL, включая ошибки при их заполнении.
                                                                </li>
                                                            </ol>
                                                        </li>
                                                        <li>Пользуясь веб-сайтом, Клиент (в том числе посетитель веб-сайта) соглашается на то, что PULPAL
                                                            может
                                                            использовать статистические данные и файлы сookies, за исключением данных об информации
                                                            просматриваемой
                                                            Клиентом в интерфейсе в разделах: «Товары»; «Услуги»; «Настройки», для их последующей обработки
                                                            системами, например, Google Analytics, Яндекс.Метрика, Google Firebase, Appmetrica и может
                                                            передавать
                                                            третьему лицу для проведения исследований, выполнения работ или оказания услуг PULPAL. Клиент (в
                                                            том
                                                            числе посетитель веб-сайта) может самостоятельно управлять файлами Cookies путем изменения
                                                            настроек
                                                            браузера, функционирующего на его оборудовании. Изменения пользовательских настроек, в результате
                                                            которых файлы cookies будут заблокированы, могут привести к недоступности отдельных компонентов
                                                            веб-сайта PulPal.az.
                                                        </li>
                                                    </ol>
                                                </li>
                                                <li>
                                                    PULPAL может получить общедоступную информацию, когда Клиент использует сторонние ресурсы (например,
                                                    чаты/форму/социальные сети). Эти данные могут содержать информацию, которую Клиент публикует, в том
                                                    числе в виде комментариев или отзывов о Сервисах PULPAL. PULPAL использует такие сведения в целях
                                                    повышения качества обслуживания Клиентов.
                                                </li>
                                                <li>
                                                    PULPAL также может обрабатывать определенные данные (например, IP-адрес, идентификатор
                                                    пользовательского
                                                    устройства) для выявления и (или) предотвращения условий, способствующих совершению с использованием
                                                    сервиса действий, противоречащих требованиям законодательства или соглашения, во исполнение которого
                                                    Клиенту предоставлен сервис.
                                                </li>
                                                <li>
                                                    PULPAL может получать информацию о Клиенте от своих контрагентов. Например, при исполнении соглашения
                                                    с
                                                    контрагентом, последний может передавать PULPAL некоторые сведения, которые обеспечивают контрагенту
                                                    возможность установления связи между таким Клиентом и его переводом, информация о совершении которого
                                                    передается PULPAL контрагенту.
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            За исключением случаев идентификации / упрощенной идентификации Клиентов по основаниям и в порядке,
                                            предусмотренным применимыми соглашениями Клиента и PULPAL, PULPAL не проверяет достоверность персональной
                                            информации, предоставляемой Клиентами, и не осуществляет контроль за их дееспособностью. Однако PULPAL
                                            исходит из того, что Клиент предоставляет достоверную и достаточную персональную информацию и поддерживает
                                            эту информацию в актуальном состоянии.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    Цели сбора и обработки персональной информации Клиентов
                                    <ol>
                                        <li>
                                            PULPAL собирает и хранит только ту персональную информацию, которая необходима для предоставления Сервисов
                                            PULPAL или исполнения соглашений с Клиентом, за исключением случаев, когда законодательством предусмотрено
                                            обязательное получение и хранение определенной персональной информации. Хранение персональной информации
                                            осуществляется не дольше, чем этого требуют цели обработки, если только срок хранения такой персональной
                                            информации не установлен законодательством Азербайджанской Республики, соглашением об использовании
                                            Клиентом
                                            Сервисов PULPAL.
                                        </li>
                                        <li>
                                            Персональную информацию Клиента PULPAL может использовать в следующих целях:
                                            <ol>
                                                <li>
                                                    Исполнения соглашений с PULPAL, в том числе в целях проведения идентификации / упрощенной
                                                    идентификации
                                                    Клиента, предоставления Клиенту сервиса, выполнения распоряжений Клиента о переводе денежных средств
                                                    и/или возврате остатка денежных средств;
                                                </li>
                                                <li>
                                                    Коммуникаций. Осуществление связи с Клиентом, в том числе направления уведомлений, запросов и
                                                    информации, касающихся использования Сервисов PULPAL / оказания PULPAL услуг, а также обработки
                                                    запросов
                                                    и заявок от Клиента;
                                                </li>
                                                <li>
                                                    Улучшения качества Сервисов PULPAL, удобства их использования, разработки новых Сервисов PULPAL,
                                                    предложения Клиенту персонализированных Сервисов PULPAL;
                                                </li>
                                                <li>
                                                    Проведения статистических и иных исследований на основе обезличенных данных;
                                                </li>
                                                <li>
                                                    Проведения маркетинговых акций для Клиентов, в том числе в целях распространения предложений об
                                                    участии
                                                    в акции и получения предусмотренных акцией призов/вознаграждений; распространения
                                                    рекламно-информационных материалов по сетям электросвязи, в том числе посредством использования
                                                    телефонной, факсимильной, подвижной радиотелефонной связи, или путем прямых контактов; таргетирования
                                                    рекламных материалов и иной информации, доводимой до сведения Клиентов.
                                                </li>
                                                <li>
                                                    Предотвращение условий, способствующих совершению с использованием сервисов действий, противоречащих
                                                    требованиям законодательства или соглашения, во исполнение которого Клиенту предоставлен сервис.
                                                </li>
                                            </ol>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    Условия обработки персональной информации Клиента и её передачи третьим лицам
                                    <ol>
                                        <li>
                                            PULPAL обрабатывает персональную информацию Клиентов в соответствии с настоящей Политикой, условиями
                                            оказания конкретных сервисов и внутренними регламентами PULPAL.
                                        </li>
                                        <li>
                                            В отношении персональной информации Клиента обеспечивается ее конфиденциальность.
                                        </li>
                                        <li>
                                            PULPAL вправе передать персональную информацию Клиента третьим лицам в следующих случаях:
                                            <ol>
                                                <li>
                                                    Клиент выразил свое согласие на такие действия;
                                                </li>
                                                <li>
                                                    Передача необходима для использования определенного Сервиса PULPAL или сервиса партнера PULPAL, в том
                                                    числе для исполнения распоряжения Клиента.
                                                    В частности, Персональная информация может быть переда третьим лицам следующих категорий:
                                                    <ol>
                                                        <li>
                                                            Кредитные организации и иные организации, участвующие при осуществлении переводов.
                                                        </li>
                                                        <li>
                                                            Партнеры по маркетингу и иные контрагенты PULPAL.
                                                            <ol className="alpha">
                                                                <li>PULPAL можем предоставить доступ к некоторым данным (например, статистике) для проведения
                                                                    маркетинговых и других исследований, а также к иным данным, позволяющим обеспечить передачу
                                                                    Клиенту рекламы, в том числе сторонних организаций, которая актуальна и может быть интересна
                                                                    Клиенту;
                                                                </li>
                                                                <li>PULPAL может предоставить доступ к некоторым данным о платежных операциях Клиента,
                                                                    являющихся
                                                                    основанием для определения партерном PULPAL возможности предоставления такому Клиенту скидок
                                                                    (премий), поощрений вследствие выполнения Клиентом определенных условий, установленных
                                                                    партнером
                                                                    PULPAL, а также о Бонусном счете Клиента – участника Программы лояльности PULPAL, когда такой
                                                                    доступ обусловлен возможностью предоставления Клиенту дополнительных условий использования
                                                                    Привилегий;
                                                                </li>
                                                                <li>При использовании Клиентом сервисов и услуг, предоставляемых партнерами PULPAL, информация о
                                                                    Клиенте может предоставляться таким лицам в объеме и в целях, необходимых для надлежащего
                                                                    предоставления сервисов Клиенту или повышающих уровень удобства их использования, например,
                                                                    для
                                                                    предзаполнения регистрационных форм, обеспечивающего ускорение процесса прохождения процедуры
                                                                    регистрации в сервисах, предоставляемых партерами PULPAL.
                                                                </li>
                                                            </ol>
                                                        </li>
                                                        <li>
                                                            Партнеры, обеспечивающие хранение данных.
                                                        </li>
                                                        <li>Партнеры, участвующие при обеспечении предотвращения условий, способствующих совершению с
                                                            использованием сервиса PulPal действий, противоречащих требованиям законодательства или
                                                            соглашения,
                                                            во исполнение которого Клиенту предоставлен сервис.
                                                        </li>
                                                        <li>
                                                            Общедоступная информация. Сервисы PULPAL могут иметь форумы и/или чаты, где Клиенты могут
                                                            обмениваться идеями и общаться друг с другом. Публикуя сообщение на форуме или в чат-зоне, Клиенту
                                                            следует иметь в виду, что такая информация будет общедоступна в режиме онлайн и такое размещение
                                                            осуществляется Клиентом на его страх и риск.
                                                        </li>
                                                        <li>
                                                            Переход контроля. Передача происходит в рамках продажи или иной передачи бизнеса (полностью или в
                                                            части), при этом к приобретателю переходят все обязательства по соблюдению условий настоящей
                                                            Политики применительно к полученной им персональной информации;
                                                        </li>
                                                        <li>
                                                            Передача предусмотрена Азербайджанским или иным применимым законодательством в рамках
                                                            установленной
                                                            законодательством процедуры;
                                                        </li>
                                                        <li>
                                                            В целях обеспечения возможности защиты прав и законных интересов PULPAL или третьих лиц в случаях,
                                                            когда у PULPAL имеются достаточные основания полагать, что Клиент нарушает условия применимого
                                                            соглашения с PULPAL и/или требования применимого законодательства.
                                                        </li>
                                                    </ol>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            При обработке персональных данных Клиентов PULPAL руководствуется законом «О персональных данных» и
                                            другими
                                            нормативными актами, регулирующими отношения в сфере обеспечения безопасности персональных данных.
                                        </li>
                                        <li>
                                            Раскрытие анонимных данных. PULPAL также может раскрывать партнерам анонимные данные (то есть данные,
                                            которые
                                            не раскрывают личность Клиента ни прямо, ни косвенно), а также агрегированные данные (данные о группах и
                                            категориях Клиентов). PULPAL также можем разрешить партнерам собирать анонимные и агрегированные данные в
                                            рамках
                                            предоставления Клиентам возможности пользоваться отдельными функциями Сервисов, которые впоследствии могут
                                            передавать эти данные PULPAL.
                                        </li>
                                    </ol>
                                </li>

                                <li>
                                    Изменение и удаление Клиентом персональной информации, а также получение доступа к ней
                                    <ol>
                                        <li>
                                            Изменение и удаление персональной информации Клиента осуществляется в порядке, установленном соглашениями
                                            об
                                            использовании соответствующих Сервисов PULPAL. В рамках Сервисов PULPAL Клиенту может предоставляться
                                            функциональная возможность изменить (обновить, дополнить) или удалить предоставленную Клиентом информацию
                                            или её часть. Использование соответствующего функционала регулируется применимым к данному Сервису PULPAL
                                            соглашениями.
                                            <ol>
                                                <li>
                                                    В пределах, установленных применимым законодательством, PULPAL сообщает об изменении или уничтожении
                                                    персональной информации каждому получателю, которому была раскрыта персональная информация, кроме
                                                    случаев, когда это оказывается невозможным или требует несоразмерного усилия.
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            В соответствии с требованиями законодательства на PULPAL может быть возложена обязанность осуществлять
                                            обработку / хранение полученной при использовании Сервисов PULPAL персональной информации Клиента. Такая
                                            обработка / хранение осуществляется PULPAL в случаях, по основаниям и в течение сроков, установленных
                                            законодательством.
                                        </li>
                                        <li>
                                            Право на доступ
                                            <ol>
                                                <li>
                                                    В соответствии с применимым законодательством, Клиент имеет право на доступ к информации, полученной
                                                    PULPAL, то есть имеете право запросить информацию относительно: (а) цели обработки; (б) категории
                                                    обрабатываемых данных; (в) категорией получателей, которым были или будут переданы персональные данные
                                                    Клиента; (г) срока хранения или критериев его определения, а также иных сведений.
                                                    <ol>
                                                        <li>
                                                            Если иное не реализовано в интерфейсе сервиса, информация (копия) может предоставляться PULPAL в
                                                            письменной форме или при помощи иных средств связи.
                                                        </li>
                                                        <li>
                                                            Если PULPAL имеет основания для сомнения относительно идентификации личности Клиента, подающего
                                                            запрос согласно п. 4.3.1 Политики, PULPAL вправе затребовать предоставления дополнительной
                                                            информации, необходимой для подтверждения личности такого Клиента.
                                                        </li>
                                                        <li>
                                                            Рассмотрение запроса о предоставлении сведений (копии) осуществляется в течение одного месяца (30
                                                            дней) с момента его получения PULPAL. PULPAL вправе продлить указанный срок на два месяца (60
                                                            дней)
                                                            с учетом сложности и количества запросов. PULPAL информирует Клиента о таком продлении, с
                                                            указанием
                                                            причин, послуживших основанием для такого продления.
                                                        </li>
                                                        <li>
                                                            В случае если запрос не имеет явного обоснования, а также в случае чрезмерного количества
                                                            запросов,
                                                            PULPAL вправе взимать разумную плату за выполнение запроса (с учетом административных расходов)
                                                            или
                                                            отказаться от его выполнения. PULPAL также не сможет предоставить Клиенту информацию о нем,
                                                            опубликованную в порядке, предусмотренном в п. 3.3.2.5. Политики.
                                                        </li>
                                                        <li>
                                                            Клиент вправе самостоятельно передать информацию, полученную от PULPAL в порядке, предусмотренном
                                                            в
                                                            настоящем разделе другому контролеру, а при наличии у PULPAL технической возможности – передать
                                                            через PULPAL.
                                                        </li>
                                                    </ol>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            Право на возражение
                                            <ol>
                                                <li>
                                                    В пределах, установленных применимым законодательством, Клиент может отозвать любое свое согласие,
                                                    которое им было ранее предоставлено, или представить свои возражения на законных основаниях в
                                                    отношении
                                                    обработки его персональной информации. В некоторых ситуациях отзыв согласия будет означать, что Клиент
                                                    не сможет воспользоваться Сервисами PULPAL.
                                                </li>
                                                <li>
                                                    В соответствии с применимым законодательством, Клиент имеет право на подачу жалобы в надзорный орган.
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            Использование Сервисов PULPAL детьми
                                            <ol>
                                                <li>
                                                    Без согласия законного представителя, Сервисы PULPAL не предназначены для детей. PULPAL может
                                                    использовать данные таких лиц для целей проверки возраста и применения тех или иных возрастных
                                                    ограничений.
                                                </li>
                                            </ol>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    Меры, применяемые для защиты персональной информации Клиентов
                                    <ol>
                                        <li>
                                            PULPAL принимает необходимые и достаточные организационные и технические меры для защиты персональной
                                            информации Клиента от неправомерного или случайного доступа, уничтожения, изменения, блокирования,
                                            копирования, распространения, а также от иных неправомерных действий с ней третьих лиц.
                                        </li>
                                        <li>
                                            При обработке персональных данных PULPAL обеспечивает их безопасность и принимает необходимые
                                            организационные и технические меры для защиты персональных данных от неправомерного или случайного доступа
                                            к
                                            ним, уничтожения, изменения, блокирования, копирования, распространения персональных данных, а также от
                                            иных
                                            неправомерных действий, в отношении таких данных режима конфиденциальности и контроля за его соблюдением,
                                            а
                                            также путем внедрения дополнительных мер защиты, реализующих на базе требований законодательства
                                            Азербайджанской Республики, стандартов и внутренних организационно-распорядительных документов PULPAL.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    Изменение Политики конфиденциальности. Применимое законодательство
                                    <ol>
                                        <li>PULPAL вправе предложить Клиенту изменить и/или дополнить настоящую Политику путем опубликования
                                            Политики
                                            в новой редакции на Интернет-сайте по адресу (ссылка). Принятием Пользователем такого предложения являются
                                            конклюдентные действия по использованию какого-либо из Сервисов PULPAL на новых условиях.
                                        </li>
                                        <li>
                                            К настоящей Политике и отношениям между Клиентом и PULPAL, возникающим в связи с применением Политики
                                            конфиденциальности, подлежит применению законодательство Азербайджанской Республики и нормативные акты,
                                            регулирующие отношения в сфере обеспечения безопасности персональных данных.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    Взаимодействие с Клиентами по вопросам обработки персональной Информации
                                    <ol>
                                        <li> Вопросы или замечания в отношении Политики могут быть направлены на электронный адрес: office@pulpal.az
                                        </li>
                                        <li>PULPAL вправе не отвечать на вопросы, не имеющие отношения к положениям Политики, что не лишает Клиента
                                            возможности направить такие вопросы PULPAL по адресам, указанным на сайте PULPAL в сети «Интернет», раздел
                                            «Контактная ООО PULPAL».
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </div>
                    </div>

                )
            case 'en':
                return (
                    <div className="footer-modal">
                        <div className='footer-modal__header'>
                            <h4>Privacy Policy LLC «PULPAL»</h4>
                        </div>
                        <div className='footer-modal__content'>
                            <div className='footer-modal__header-section'>
                                <div className="privacy-policy__header-section"><p>This Privacy Policy for personal information (hereinafter - the Policy) applies to
                                    information that PulPal LLC (hereinafter - PULPAL ) may receive about an Internet user (hereinafter - the Client)
                                    while using the PULPAL service and / or any of the web -sites, programs, products, services PulPal (hereinafter - PulPal Services ).
                                    The policy applies only to PulPal Services .
                                    PulPal does not control and is not responsible for sites / services of third parties to which the Client can click on the links available
                                    from PulPal Services , including information about the Client processed by third parties.
                                    All terms and definitions that are not given and used in the text of the Policy are given in the Agreement on the general conditions of
                                    the PulPal service or in other agreements, the subject of which is the procedure for the provision and use by the Client of PulPal Services .
                                    The use of PulPal Services means the Client’s unconditional consent to this Policy and the conditions for processing his
                                    personal information specified in it; in case of disagreement with these conditions, the Client should refrain from using PulPal Services.
                                </p></div>
                                <ol>
                                    <li>
                                        Customer information, which is received and drawing batyvaet PULPAL.
                                        <ol>
                                            <li>
                                                Within the framework of this Policy, the personal information of the Client means:
                                                <ol>
                                                    <li>
                                                        Personal information that the Client provides about himself independently during registration in any of the PULPAL Services or in
                                                        the process of using the PULPAL Services , including personal data of the Client (exclusion of bank card data) transmitted during
                                                        the execution of any PULPAL agreements with the Client. Please note that the use of certain PULPAL services by the Client is
                                                        possible only if the necessary data is provided.
                                                    </li>
                                                    <li>
                                                        Data that is automatically transmitted by PULPAL in the process of using the PULPAL Services using software installed
                                                        on the Client’s device , including IP address, information from cookies (text files stored in the Client’s browser),
                                                        information about the Client’s browser (or other program by which access to the PULPAL Services is accessed ),
                                                        access time, address of the requested page.
                                                        <ol>
                                                            <li> When browsing PULPAL websites , the following anonymized statistics about
                                                                the Client are automatically collected (from cookies ), including:
                                                                <ul>
                                                                    <li>
                                                                        type of action performed on the site (click, hover, etc.);
                                                                    </li>
                                                                    <li>
                                                                        date and time of the action;
                                                                    </li>
                                                                    <li>
                                                                        URL of the page;
                                                                    </li>
                                                                    <li>
                                                                        Referrer;
                                                                    </li>
                                                                    <li>
                                                                        IP (without the ability to work with IP addresses in statistics);
                                                                    </li>
                                                                    <li>
                                                                        User-Agent;
                                                                    </li>
                                                                    <li>
                                                                        ClientID (browser identifier by cookie);
                                                                    </li>
                                                                    <li>
                                                                        screen resolution;
                                                                    </li>
                                                                    <li>
                                                                        the class of the HTML element that is clicked on;
                                                                    </li>
                                                                    <li>
                                                                        data on information viewed by the Client in the interface, including information in sections of all sections of the service.
                                                                    </li>
                                                                    <li>
                                                                        data on the facts of filling out forms / appeals on the PULPAL website , including errors in filling them out.
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                            <li>By using the website, the Customer (including the website visitor) agrees that PULPAL may use statistics and files from ookies ,
                                                                with the exception of data on information viewed by the Customer in the interface in the sections: “ Products ”; " Services ";
                                                                "Settings", for their subsequent processing by systems, for example, Google Analytics, Yandex.Metrica, Google Firebase,
                                                                Appmetrica and may transfer to a third party for research, performance of work or the provision of PULPAL services.
                                                                The client (including the website visitor) can independently manage cookies by changing the settings of the browser operating on
                                                                its equipment. Changes to user settings that result in cookies being blocked may result in the inaccessibility of certain
                                                                components of the website PulPal.az.
                                                            </li>
                                                        </ol>
                                                    </li>
                                                    <li>
                                                        PULPAL can receive publicly available information when the Client uses third-party resources
                                                        (for example, chats / form / social networks). This data may contain information that the Client publishes,
                                                        including in the form of comments or feedback on the PULPAL Services . PULPAL uses such information to improve
                                                        the quality of customer service.
                                                    </li>
                                                    <li>
                                                        PULPAL may also process certain data (for example, IP- address, user device identifier) ​​to identify and (or)
                                                        prevent conditions that facilitate the use of the service of actions that contradict the requirements of
                                                        the law or agreement pursuant to which the Service is provided to the Client.
                                                    </li>
                                                    <li>
                                                        PULPAL may receive information about the Client from its counterparties. For example, in the performance of
                                                        an agreement with a counterparty, the latter may transmit to PULPAL some information that provides
                                                        the counterparty with the possibility of establishing a connection between such a Client and its transfer,
                                                        the information on the completion of which is transmitted to PULPAL to the counterparty.
                                                    </li>
                                                </ol>
                                            </li>
                                            <li>
                                                Except in cases of identification / simplified identification of Clients on the grounds and in the manner provided for
                                                by the applicable agreements of the Client and PULPAL , PULPAL does not verify the accuracy of the personal information
                                                provided by the Clients and does not exercise control over their legal capacity. However, PULPAL assumes that the Client
                                                provides reliable and sufficient personal information and maintains this information up to date.
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        Purpose of collecting and processing personal information of customers
                                        <ol>
                                            <li>
                                                PULPAL collects and stores only that personal information that is necessary for the provision of
                                                PULPAL Services or the execution of agreements with the Client, unless the law provides for the mandatory receipt and
                                                storage of certain personal information. The storage of personal information is carried out no longer than
                                                the processing goals require, unless the storage period for such personal information is established by the legislation
                                                of the Azerbaijan Republic , by the agreement on the use of PULPAL Services by the Client.
                                            </li>
                                            <li>
                                                PULPAL may use the Customer’s personal information for the following purposes:
                                                <ol>
                                                    <li>
                                                        Fulfillment of agreements with PULPAL , including for the purpose of identifying / simplifying the identification of
                                                        the Client, providing the Client with a service , fulfilling the orders of the Client on the transfer of funds
                                                        and / or return of the balance of funds;
                                                    </li>
                                                    <li>
                                                        Communications . Communication with the Client, including sending notifications, requests and information
                                                        regarding the use of the PULPAL Services / provision of PULPAL services, as well as processing requests and
                                                        requests from the Client;
                                                    </li>
                                                    <li>
                                                        Improvements in the quality of PULPAL Services , ease of use, development of new PULPAL Services,
                                                        offers to the Client personalized PULPAL Services ;
                                                    </li>
                                                    <li>
                                                        Conducting statistical and other studies based on anonymized data;
                                                    </li>
                                                    <li>
                                                        Conducting marketing campaigns for Clients, including with the aim of disseminating offers to participate in
                                                        the campaign and receiving the prizes / rewards provided by the campaign; distribution of advertising and
                                                        information materials on telecommunication networks, including through the use of telephone, facsimile,
                                                        mobile radiotelephone communications, or through direct contacts; targeting advertising materials and
                                                        other information brought to the attention of Customers.
                                                    </li>
                                                    <li>
                                                        Prevention of conditions conducive to the commission of services using actions that are contrary to the requirements
                                                        of the law or the agreement pursuant to which the service is provided to the Client.
                                                    </li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        Conditions for processing personal information of the Client and its transfer to third parties
                                        <ol>
                                            <li>
                                                PULPAL processes the personal information of Clients in accordance with this Policy,
                                                the conditions for the provision of specific services and PULPAL internal regulations.
                                            </li>
                                            <li>
                                                With regard to the personal information of the Client, its confidentiality is ensured.
                                            </li>
                                            <li>
                                                PULPAL is entitled to transfer the Client’s personal information to third parties in the following cases:
                                                <ol>
                                                    <li>
                                                        The client has expressed his consent to such actions;
                                                    </li>
                                                    <li>
                                                        The transfer is necessary for the use of a specific PULPAL Service or PULPAL partner service,
                                                        including for the execution of a Client’s order. In particular, Personal Information may be transferred
                                                        to third parties in the following categories:
                                                        <ol>
                                                            <li>
                                                                Credit organizations and other organizations involved in the implementation of transfers.
                                                            </li>
                                                            <li>
                                                                Marketing partners and other PULPAL counterparties.
                                                                <ul>
                                                                    <li>PULPAL can provide access to some data (for example, statistics) for marketing and other research,
                                                                        as well as to other data that can ensure the transfer to the Client of advertising, including third-party
                                                                        organizations, which is relevant and may be of interest to the Client;
                                                                    </li>
                                                                    <li>PULPAL may provide access to some data about the Client's payment transactions, which are the basis
                                                                        for determining the PULPAL partner's ability to provide such Client with discounts (bonuses),
                                                                        incentives due to the fulfillment by the Client of certain conditions established by the PULPAL partner ,
                                                                        as well as about the Bonus Account of the Client participating in the Program PULPAL loyalty when such access is due to the
                                                                        possibility of providing the Client with additional conditions for using the Privileges;
                                                                    </li>
                                                                    <li>When the Customer uses the services and services provided by PULPAL partners , information about
                                                                        the Customer may be provided to such persons to the extent and for the purposes necessary for
                                                                        the proper provision of services to the Customer or increasing the level of convenience of their use,
                                                                        for example, for pre-filling registration forms, which speeds up the process passing the registration
                                                                        procedure in the services provided by PULPAL partners.
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                            <li>
                                                                Data storage partners.
                                                            </li>
                                                            <li>Partners involved in ensuring the prevention of conditions conducive to the execution using the PulPal
                                                                service of actions that contradict the requirements of the law or the agreement pursuant to which the Service
                                                                is provided to the Client.
                                                            </li>
                                                            <li>
                                                                Publicly Available Information . PULPAL Services may have forums and / or chats where Clients can exchange ideas
                                                                and communicate with each other. When posting a message on the forum or in the chat zone, the Client should bear
                                                                in mind that such information will be publicly available online and such placement is carried out by the Client
                                                                at his own risk.
                                                            </li>
                                                            <li>
                                                                Transition control . The transfer takes place as part of a sale or other transfer of business (in whole or in part),
                                                                while the acquirer is transferred to all obligations to comply with the terms of this Policy in relation to
                                                                the personal information received by him;
                                                            </li>
                                                            <li>
                                                                The transfer is provided for by Azerbaijan or other applicable legislation within the framework of the procedure established by law;
                                                            </li>
                                                            <li>
                                                                In order to protect the rights and legitimate interests of PULPAL or third parties in cases where PULPAL has
                                                                sufficient reason to believe that the Client is in violation of the terms of the applicable agreement
                                                                with PULPAL and / or the requirements of applicable law.
                                                            </li>
                                                        </ol>
                                                    </li>
                                                    <li>

                                                    </li>
                                                </ol>
                                            </li>
                                            <li>
                                                When processing personal data of Clients, PULPAL is guided by the Law on Personal Data and other regulatory acts
                                                regulating relations in the field of ensuring the security of personal data.
                                            </li>
                                            <li>
                                                Disclosure of anonymous data . PULPAL may also disclose anonymous data to partners
                                                (that is, data that does not reveal the identity of the Client either directly or indirectly),
                                                as well as aggregated data (data about groups and categories of customers).
                                                PULPAL may also allow partners to collect anonymous and aggregated data as part of giving customers the opportunity to use
                                                certain functions of the Services, which can subsequently transmit this data to PULPAL.
                                            </li>
                                        </ol>
                                    </li>

                                    <li>
                                        Change and deletion by the Client of personal information, as well as access to it
                                        <ol>
                                            <li>
                                                Change and deletion of the Client’s personal information is carried out in the manner established by agreements
                                                on the use of the corresponding PULPAL Services . As part of the PULPAL Services , the Client may be provided
                                                with a functional opportunity to change (update, supplement) or delete the information provided by the Client or its part.
                                                The use of the appropriate functionality is governed by the agreements applicable to this PULPAL Service.
                                                <ol>
                                                    <li>
                                                        To the extent permitted by applicable law, PULPAL will notify you of a change or destruction of personal information
                                                        to each recipient to whom personal information has been disclosed, unless this is not possible or
                                                        requires disproportionate effort.
                                                    </li>
                                                </ol>
                                            </li>
                                            <li>
                                                In accordance with the requirements of the law, PULPAL may be obligated to process / store the personal information of
                                                the Client obtained using the PULPAL Services .
                                                Such processing / storage is carried out by PULPAL in cases, on the grounds and within the time limits established by law.
                                            </li>
                                            <li>
                                                Right of access
                                                <ol>
                                                    <li>
                                                        In accordance with applicable law, the Client has the right to access information received by PULPAL ,
                                                        that is, you have the right to request information regarding:
                                                        <ol>
                                                            <li>
                                                                Unless otherwise implemented in the service interface , information (copy) may be provided by PULPAL in writing or
                                                                by other means of communication.
                                                            </li>
                                                            <li>
                                                                If PULPAL has reason to doubt the identity of the Client submitting the request in accordance with
                                                                clause 4.3.1 of the Policy, PULPAL has the right to require the provision of additional information necessary
                                                                to confirm the identity of such Client.
                                                            </li>
                                                            <li>
                                                                Consideration of the request for the provision of information (copies) is carried out within one month (30 days)
                                                                from the moment of its receipt by PULPAL . PULPAL is entitled to extend the indicated period by two months (60 days),
                                                                taking into account the complexity and number of requests. PULPAL informs the Client about such an extension,
                                                                indicating the reasons that served as the basis for such an extension.
                                                            </li>
                                                            <li>
                                                                If the request does not have an explicit justification, as well as in the case of an excessive number of requests,
                                                                PULPAL has the right to charge a reasonable fee for fulfilling the request (taking into account administrative
                                                                expenses) or refuse to fulfill it. PULPAL will also not be able to provide the Client with information
                                                                about it published in the manner provided for in clause 3.3.2.5. Politicians.
                                                            </li>
                                                            <li>
                                                                The client has the right to independently transfer the information received from PULPAL in the manner prescribed in
                                                                this section to another controller, and if PULPAL has the technical ability to transmit it through PULPAL.
                                                            </li>
                                                        </ol>
                                                    </li>
                                                </ol>
                                            </li>
                                            <li>
                                                Right to object
                                                <ol>
                                                    <li>
                                                        To the extent established by applicable law, the Client may withdraw any of his consent that he had previously been given,
                                                        or submit his objections legally with regard to the processing of his personal information.
                                                        In some situations, withdrawal of consent will mean that the Client will not be able to use the PULPAL Services.
                                                    </li>
                                                    <li>
                                                        In accordance with applicable law, the Client is entitled to file a complaint with the supervisor.
                                                    </li>
                                                </ol>
                                            </li>
                                            <li>
                                                Use of the PULPAL Services by children
                                                <ol>
                                                    <li>
                                                        Without the consent of the legal representative, PULPAL Services is not intended for children.
                                                        PULPAL may use the data of such persons for the purpose of verifying age and applying certain age restrictions.
                                                    </li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        Measures used to protect the personal information of customers
                                        <ol>
                                            <li>
                                                PULPAL takes the necessary and sufficient organizational and technical measures to protect the Client’s personal information
                                                from unauthorized or accidental access, destruction, alteration, blocking, copying, distribution,
                                                as well as from other illegal actions of third parties with it.
                                            </li>
                                            <li>
                                                When processing personal data, PULPAL ensures its safety and takes the necessary organizational and technical measures
                                                to protect personal data from unlawful or accidental access to it, destruction, alteration, blocking, copying,
                                                distribution of personal data, as well as from other illegal actions, in relation to such data confidentiality and
                                                monitoring its implementation, as well as through the introduction of additional security measures that implement on
                                                the basis of requirements th legislation of Azerbaijan Rep faces , standards and internal organizational and
                                                administrative documents PULPAL.
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        Modification of the Privacy Policy. Applicable Law
                                        <ol>
                                            <li>PULPAL has the right to invite the Client to amend and / or supplement this Policy by publishing the Policy in a
                                                new version on the Internet site at (https://pulpal.az/ ).
                                                Acceptance by the User of such an offer is a consequential action to use any of the PULPAL Services on new terms.
                                            </li>
                                            <li>
                                                The laws of the Republic of Azerbaijan and the regulations governing relations in the field of ensuring the security
                                                of personal data are subject to this Policy and the relations between the Client and PULPAL arising in connection
                                                with the application of the Privacy Policy.
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        Interaction with Clients on the processing of personal Information
                                        <ol>
                                            <li> Questions or comments regarding the Policy may be sent to the email address: office@pulpal.az
                                            </li>
                                            <li>PULPAL has the right not to answer questions that are not related to the provisions of the Policy,
                                                which does not preclude the Client from sending such PULPAL questions to the addresses indicated on the PULPAL website on
                                                the Internet, section “ PULPAL Contact LLC ”.
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                )
        }
    }

    return (
        <>{setPrivacyPolicy()}</>
    )
}

export default PrivacyPolicy;