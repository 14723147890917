import React from 'react';
import { ServicesProps } from './Services.props';
import { ServiceSources } from './Services.sources';
import ServiceItem from './ServiceItem';
import styles from '../Home.module.scss';
import { useTranslation } from 'react-i18next';

const Services: React.FC<ServicesProps> = ({ serviceItems }) => {
    const { t } = useTranslation();

    return (
        <section className={styles.services}>
            <div className={'container'}>
                <div className={'section__header'}>
                    <img src={ServiceSources.icon} alt='Services' />
                    <h2 className={'section__title ' + styles.secTitle}>{t("Our Service and Features")}</h2>
                </div>

                <div className={'row ' + styles.services__inner}>
                    <ServiceItem
                        title={`${t("Accept payments from the terminal")}`}
                        imageSrc={ServiceSources.s1}
                        description={`${t("TheTerminal")}`}
                        path={''}
                    />
                    <ServiceItem
                        title={`${t("PaymentsOnline")}`}
                        imageSrc={ServiceSources.s2}
                        description={`${t("AcceptPayments")}`}
                        path={''}
                    />
                    <ServiceItem
                        title={`${t("InstallmentCards")}`}
                        imageSrc={ServiceSources.s3}
                        description={`${t("ConvenientAndFlexible")}`}
                        path={''}
                    />
                    <ServiceItem
                        title={`${t("ANİ PAY Payment system")}`}
                        imageSrc={ServiceSources.s4}
                        description={`${t("AniPay")}`}
                        path={''}
                    />
                    <ServiceItem
                        title={`${t("Online Money The bladder")}`}
                        imageSrc={ServiceSources.s5}
                        description={`${t("TheBladder")}`}
                        path={''}
                        openRegisterInModal={true}
                    />
                    <ServiceItem
                        title={`${t("PulPalMobileApp")}`}
                        imageSrc={ServiceSources.s6}
                        description={`${t("OurMobileApp")}`}
                        path={''}
                        backSide={true}
                    />
                    <ServiceItem
                        title={`${t("Card2Card")}`}
                        imageSrc={ServiceSources.s7}
                        description={`${t("OurService")}`}
                        path={'/#c2c'}
                    />
                    <ServiceItem
                        title={`${t("PayAnyServices")}`}
                        imageSrc={ServiceSources.s8}
                        description={`${t("WithUs")}`}
                        path={'/payments'}
                    />
                    <ServiceItem
                        title={`${t("RegisterElectronicWallet")}`}
                        imageSrc={ServiceSources.s9}
                        description={`${t("OpeningAnElectronic")}`}
                        path={'/sign-up-physical'}
                    />
                </div>
            </div>
        </section>
    );
}

export default Services;