import axios from "axios";
import { axiosAuth, user } from "../http";

export default class AuthService {
    static authData = null;
    static setAuthData(data: any) {
        if (data && data.auth) {
            localStorage.setItem('pulpalAuth', JSON.stringify(data))
            AuthService.authData = data;
        }
        return data
    }

    static getAuthData() {
        if (AuthService.authData) {
            return AuthService.authData
        }
        
        const authData = localStorage.getItem('pulpalAuth')
        AuthService.authData = authData ? JSON.parse(authData) : undefined
        return AuthService.authData
    }

    static removeAuthData() {
        localStorage.removeItem('pulpalAuth')
        AuthService.authData = null
        return { status: 'Removed success' }
    }

    static async refresh(refreshToken: string) {
        console.log(' service refreshToken', refreshToken);
        try {
            const ref = await axiosAuth.post(`${user}/refresh`, {}, {
                headers: {
                    Authorization: `${refreshToken}`,
                }
            });
            return ref
        } catch (error) {
            
        }
        // console.log('ref', ref)
    }
}