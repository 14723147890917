import React from 'react';
import DeveloperBox from './DeveloperBox';
import { DevelopersProps } from './Developers.props';
import { DeveloperSources } from './Developers.sources';
import styles from '../Home.module.scss';
import { useTranslation } from 'react-i18next';

const Developers: React.FC<DevelopersProps> = () => {
	const language = localStorage.getItem("language");
	const { t } = useTranslation();
	return (
		<section className={styles.dev} id='dev'>
			<div className='container'>
				<div className='section__header'>
					<img src={DeveloperSources.devIcon} alt='Developers' />
					<h2 className='section__title'>{t("Apply")}</h2>
					<p className='section__header-text'>{t("Take advantage of PulPal's")}</p>
				</div>

				<div className={`row ${styles['dev__inner']}`}>
					<div className='col-12 col-lg-6'>
						<div className={`${styles['dev__box']} ${styles['dev__box-large']}`}>
							<img src={DeveloperSources.dbl} alt='Developers Guide' />
						</div>
					</div>
					<DeveloperBox
						icon={{ src: DeveloperSources.apiIcon, alt: 'API' }}
						color={'blue'}
						title={t('All API capabilities')}
						desc={t('PulPal has an intuitive')}
						button={{ url: `https://docs.pulpal.az/index_${language}.html#pulpal-prefiksi-il-inteqrasiya`, title: t("ViewingDocs"), image: { src: DeveloperSources.rab, alt: '' } }}
					/>

					<DeveloperBox
						icon={{ src: DeveloperSources.module, alt: 'API' }}
						color={'orange'}
						title={t('Ready modules')}
						desc={t('Just install')}
						button={{ url: '/modules', title: t("ViewingModules"), image: { src: DeveloperSources.ray, alt: '' } }}
					/>
				</div>
			</div>
		</section>
	);
}

export default Developers;